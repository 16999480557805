import "react-multi-carousel/lib/styles.css";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import LynxLogo from "../../../assets/images/Color=White, Size=Large.svg";
import styles from "./register-login.module.css";
import Google from "../../../assets/images/Vector.svg";
import { useAuth0 } from "@auth0/auth0-react";
import graidentImage from "../../../assets/images/start-screen-gradient.png";
import img1 from "../../../assets/images/start-page-image1.png";
import img2 from "../../../assets/images/start-page-image2.png";
import img3 from "../../../assets/images/start-page-image3.png";
import img4 from "../../../assets/images/start-page-image4.png";
import img5 from "../../../assets/images/start-page-image5.png";
import { useContext, useEffect } from "react";
import { LoadingContext } from "../../../context/loading-context";
import Carousel from "react-multi-carousel";
import { useTheme } from "../../../context/theme-context";
import { useLocation } from "react-router-dom";

const items = [
  {
    bgImg: img1,
  },
  {
    bgImg: img2,
  },
  {
    bgImg: img3,
  },
  {
    bgImg: img4,
  },
  {
    bgImg: img5,
  },
];

const Login = (props) => {
  const { theme } = useTheme();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const { setIsLoading } = useContext(LoadingContext);
  const location = useLocation();
  useEffect(() => {
    const shouldStoreQueries = location.search.split("=")[0] !== "?code";
    if (localStorage.getItem("starter-queries") === "") {
      if (shouldStoreQueries) {
        localStorage.setItem("starter-queries", location.search);
      }
    }
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Box
      className={styles.gredient}
      sx={{
        backgroundImage: `url(${graidentImage})`,
      }}
    >
      <Stack
        className={styles.register}
        spacing={{
          // md: 6,
          xs: 1,
        }}
      >
        <Box className={styles.logoContainer}>
          <img
            src={LynxLogo}
            className={styles.logo}
            alt="Logo"
            width="100"
            height="100"
          />
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "18px",
              fontWeight: "400",
              fontStyle: "italic",
            }}
          >
            Indie Creator Collabs
          </Typography>
        </Box>

        <Container className={styles.container}>
          <Box
            className={styles.cards}
            sx={{
              "& .react-multi-carousel-list": {
                overflow: "initial",
                // paddingLeft: "24px",
              },
              "& ul.react-multi-carousel-track": {
                // gap: "16px",
              },
              "& .react-multi-carousel-item": {
                transform: "scale(0.9)",
              },
              "& .react-multi-carousel-item--active": {
                transform: "scale(1)",
              },
              "& .custom-dot-list-style": {
                bottom: "-40px",
                gap: "12px",
              },
              ".react-multi-carousel-dot button": {
                borderRadius: "0",
                background: "rgba(247, 247, 247, 0.40)",
                border: "none",
                width: "8px",
                height: "8px",
              },
              "& .react-multi-carousel-dot--active button": {
                transform: "scale(1.3)",
                background: "#F7F7F7",
              },
            }}
          >
            <Carousel
              responsive={responsive}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              showDots={true}
              ssr={true}
              // infinite={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              customTransition="all .5"
              transitionDuration={500}
              // deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {items.map((item, index) => (
                <Box className={styles.card}>
                  <img
                    width={300}
                    height={400}
                    src={item.bgImg}
                    style={{
                      margin: 0,
                      padding: 0,
                      boxSizing: "border-box",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              ))}
            </Carousel>
          </Box>
        </Container>
        <Box className={styles.buttonContainer}>
          <Button
            onClick={async () => {
              setIsLoading(true);
              await loginWithRedirect({ connection: "google-oauth2" });
              setIsLoading(false);
            }}
            // fullWidth
            sx={{
              backgroundColor: "#FFF",
              color: theme.palette.primary.main,
              textTransform: "uppercase",
              fontWeight: "700",
              fontSize: "18px",
              width: {
                // xs: "100%",
                // sm: "50%",
              },
            }}
            className={styles.button}
            startIcon={
              <img
                style={{
                  padding: "10px",
                  backgroundColor: "#F5F2F6",
                  borderRadius: "4px",
                }}
                src={Google}
                alt="google"
              />
            }
          >
            Sign in with Google
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default Login;
