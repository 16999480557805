import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Link,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import styles from "./style.module.css";
import {
  AddPhotoAlternateOutlined,
  Edit,
  EditOutlined,
} from "@mui/icons-material";
import { ProfileImageContext } from "../../../../context/profile-image-context";
import { UserContext } from "../../../../context/user-context";
import { useTheme } from "../../../../context/theme-context";
import { ProfileCompleteContext } from "../../../../context/profile-complete-context";

const ProfileImage = ({ data }) => {
  const { theme } = useTheme();
  let { profile_image, setIsModalOpen } = data;
  const { selectedImage, setSelectedImage } = useContext(ProfileImageContext);
  const { user } = useContext(UserContext);
  const { imageAdded, setImageAdded } = useContext(ProfileCompleteContext);

  const openModal = () => {
    setIsModalOpen(true);
    setSelectedImage(user?.original_image);
  };

  const handleProfileImageChange = () => {
    if (profile_image && !imageAdded) {
      setImageAdded(true);
    }
    if (!profile_image && imageAdded) {
      setImageAdded(false);
    }
  };

  useEffect(() => {
    handleProfileImageChange();
  }, [profile_image]);

  return (
    <React.Fragment>
      {profile_image === null && (
        <Box className={styles.image} onClick={openModal}>
          <AddPhotoAlternateOutlined
            sx={{
              width: "40px",
              height: "40px",
              flexShrink: "0",
              color: theme.palette.darkgrey.darkgrey400,
            }}
          />
        </Box>
      )}
      {profile_image && (
        <Box
          className={styles.image}
          sx={{
            backgroundImage: `url(${profile_image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        >
          <Box className={styles.Cameraupload} onClick={openModal}>
            <EditOutlined
              fontSize="16px"
              sx={{ color: theme.palette.darkgrey.darkgrey400 }}
            />
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default ProfileImage;
