import { Box, Typography } from "@mui/material";
import img from '../../../../../assets/images/sad.png';

import styles from './style.module.css';

export default function Sad ({txt, key}) {
    return (
        <Box className={styles.border} key={key}>
            <Box className={styles.bg}>
                {/* <Typography variant="body1" className={styles.txt1}>
                    😞
                </Typography> */}
                <img src={img} />
                <Typography variant="body1" className={styles.txt2}>
                {txt}
                </Typography>
            </Box>
            <Box className={styles.enableIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M12 4L4 12" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4 4L12 12" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </Box>
        </Box>
    )
}