import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import logo from "../../../assets/images/Color=Gredient, Size=Large.svg";
import image from "../../../assets/images/check-circle-broken.svg";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../../components/loading/default";
import { UserContext } from "../../../context/user-context";
import { useTheme } from "../../../context/theme-context";
import styles from "./style.module.css";
import Google from "../../../assets/images/Vector.svg";
import { useAuth0 } from "@auth0/auth0-react";

const Thanks = ({ tempTheme, handleDataComplete, isLoading }) => {
  const { theme, setDarkMode } = useTheme();
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        //justifyContent: "center",
        padding: "0px 24px",
        backgroundColor: theme.palette.lightgrey.lightgrey600,
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //justifyContent: "center",
          padding: "40px 0px",
        }}
      >
        <img src={logo} width="234px" alt="LYNX Logo" />
      </Box>
      <Box
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey00,
          p: "40px 24px",
          borderRadius: "12px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "48px",
              alignItems: "flex-start",
              gap: "8px",
              borderRadius: "100px",
              background:
                "linear-gradient(160deg, #0F5FF7 9.58%, #9373FF 51.07%, #E81CFF 93.44%)",
              color: theme.palette.lightgrey.lightgrey00,
            }}
          >
            <img src={image} alt="image" />
            {/* <TaskAlt style={{ fontSize: "80px" }} /> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              alignSelf: "stretch",
            }}
          >
            <Typography
              variant="h5"
              color={theme.palette.darkgrey.darkgrey700}
              sx={{
                textAlign: "center",
                fontWeight: "700",
                fontSize: "24px",
              }}
            >
              One more step!
            </Typography>
            <Typography
              variant="body1"
              color={theme.palette.darkgrey.darkgrey400}
              sx={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Use the sign-in below to access your account
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={async () => {
              await loginWithRedirect({ connection: "google-oauth2" });
              handleDataComplete();
              setDarkMode(tempTheme);
            }}
            sx={{
              textTransform: "uppercase",
              fontWeight: "700",
              fontSize: "18px",
            }}
            className={styles.button}
            startIcon={
              <img
                style={{
                  padding: "10px",
                  backgroundColor: "#F5F2F6",
                  borderRadius: "4px",
                }}
                src={Google}
                alt="google"
              />
            }
          >
            Sign in with Google
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Thanks;
