import { createContext, useState } from "react";

export const LoadingContext = createContext(null);

const LoadingContextProvider = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const contextValue = {
        isLoading,
        setIsLoading
    }

    return (
        <LoadingContext.Provider value={contextValue}>
            {props.children}
        </LoadingContext.Provider>
    );
};
export default LoadingContextProvider;