import React, { useRef, useState, useEffect, useContext } from "react";

import "cropperjs/dist/cropper.min.css";
import { Box, Typography } from "@mui/material";
import styles from "./style.module.css";
import ImageCropper from "./cropper";
import supabase from "../../../../../../config/supabaseClient";
import { uploadImage } from "../../../../../../helpers/uploadImageToS3";
import { SkillsBarContext } from "../../../../../../context/skills-bar-context";
import { SkillImageContext } from "../../../../../../context/skill-image-context";
import { LoadingContext } from "../../../../../../context/loading-context";
import imageCompression from "browser-image-compression";

const EditSkillImage = ({ modal, activeSkill, setActiveSkill }) => {
  const {
    selectedSkillImage,
    setSelectedSkillImage,
    setCroppedImage,
    setIsModalSkillOpen,
  } = useContext(SkillImageContext);
  const [isSkillUploading, setIsSkillUploading] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const handleCrop = async (croppedImage) => {
    try {
      // Convert the Uint8Array to a Blob
      const blob = new Blob([croppedImage], { type: "image/png" });
      const originalBlob = blob;
      
      // Compress the image using browser-image-compression library
      const options = {
        maxSizeMB: 1, // Set the maximum size of the compressed image to 0.5 MB
        maxWidthOrHeight: 600, // Set the maximum width or height of the compressed image
        useWebWorker: true, // Use a web worker for faster compression
        quality: 1, // Adjust the quality parameter (0.7 is a good starting point)
      };

      const compressedBlob = await imageCompression(originalBlob, options);
      
      const blobURL = await uploadImage(
        compressedBlob,
        "skill-cover-image-" + activeSkill.id + "-v-" + new Date().getTime()
      );

      // setActiveSkill({ ...activeSkill, skill_cover_image: blobURL });
      setCroppedImage(blobURL);
      setSelectedSkillImage(null);
      setIsModalSkillOpen(false);
      setIsLoading(true);

      const { error, data } = await supabase
        .from("creator_skills")
        .update({
          skill_cover_image: blobURL,
        })
        .eq("id", activeSkill?.id);

      if (error) {
        console.error("Error uploading skill image:", error);
      }
      
      const { data: updatedSkill, error: updatedSkillError } = await supabase
        .from("creator_skills")
        .select("*")
        .eq("id", activeSkill.id);
      
      setActiveSkill(updatedSkill[0]);
      setIsLoading(false);

      // handle original image upload
      if (selectedSkillImage !== activeSkill?.original_Cover_image) {
        const response = await fetch(selectedSkillImage);
        const blobData = await response.blob();

        // Compress the image using browser-image-compression library
        const options = {
          maxSizeMB: 1, // Set the maximum size of the compressed image
          maxWidthOrHeight: 600, // Set the maximum width or height of the compressed image
          useWebWorker: true, // Use a web worker for faster compression
          quality: 1,
        };

        const compressedBlob = await imageCompression(blobData, options);
        
        const originalImageUrl = await uploadImage(
          compressedBlob,
          "original-cover-image-" +
            activeSkill.id +
            "-v-" +
            new Date().getTime()
        );
      
        const { error, data } = await supabase
          .from("creator_skills")
          .update({
            original_cover_image: originalImageUrl,
          })
          .eq("id", activeSkill?.id);
          
        const { data: updatedSkill, error: updatedSkillError } = await supabase
          .from("creator_skills")
          .select("*")
          .eq("id", activeSkill.id);
        
        setActiveSkill(updatedSkill[0]);
      }

    } catch (error) {
      console.error("Error handling upload:", error);
    }

  };
  const handleCancel = () => {
    setCroppedImage(null);
    setSelectedSkillImage(null);
    setIsModalSkillOpen(false);
  };

  const skill_cover_image = activeSkill?.skill_cover_image;
  const imageSrc = selectedSkillImage || skill_cover_image;

  return (
    <Box className={styles.parent}>
      <Box className={styles.container}>
        <ImageCropper
          imageSrc={selectedSkillImage}
          onCrop={handleCrop}
          onCancel={handleCancel}
          isProfileUploading={isSkillUploading}
          setIsProfileUploading={setIsSkillUploading}
        />
      </Box>
    </Box>
  );
};

export default EditSkillImage;
