import { Box, Button, TextField, Typography, Link } from "@mui/material";
import React, { useContext, useState } from "react";
import styles from "./style.module.css";
import supabase from "../../../../../config/supabaseClient";
import { UserContext } from "../../../../../context/user-context";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../../../context/theme-context";
import {
  add_help_form,
  sendClassificationHelpEmail,
} from "../../../../../helpers/emails";

const CreatorType2 = ({ setIsOpenThanks }) => {
  const { theme } = useTheme();
  const [details, setDetails] = useState("");
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const { data, error } = await supabase
      .from("feedback_tickets")
      .insert({
        submitted_by: user.id,
        category: "Help With Classification",
        details,
      })
      .select();

    setIsOpenThanks(true);
    await sendClassificationHelpEmail(user.id, data[0].ticket_number);
  };

  return (
    <Box className={styles.creatorType} sx={{}}>
      <Box className={styles.content}>
        <Box className={styles.creatorType2}>
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.darkgrey.darkgrey600,
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            Details
          </Typography>
        </Box>
        <Box className={styles.options}>
          <Typography
            variant="body1"
            color={theme.palette.darkgrey.darkgrey600}
          >
            Give us a short description of what skill you are looking for in our
            list...
          </Typography>

          <TextField
            sx={{
              background: theme.palette.lightgrey.lightgrey00,
              width: {
                //md: "50%",
              },
            }}
            fullWidth
            id="outlined-textarea"
            multiline
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            rows={4}
          />
        </Box>
        <Button variant="contained" onClick={handleSubmit} fullWidth>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default CreatorType2;
