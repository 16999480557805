import { Box, Button, Chip, ClickAwayListener, Fade, IconButton, Tooltip, Typography, capitalize, tooltipClasses } from "@mui/material";
import React, {useState} from "react";
import styles from './styles.module.css';
import HitMeUp from "../hit-me-up";
import { useTheme } from "../../../../../context/theme-context";
import { Link } from "@mui/icons-material";

const PersonalDetails = ({data}) => {
    const { theme, darkMode } = useTheme();
    const [isVisible, setIsVisible] = useState(true);
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyLink = async (linkToCopy) => {
        try {
          await navigator.clipboard.writeText(linkToCopy);
          setIsCopied(true);
    
          // Set a timeout to reset the isCopied state after 2 seconds
          setTimeout(() => {
            setIsCopied(false);
          }, 2000);
        } catch (err) {
          console.error('Unable to copy to clipboard', err);
        }
    };
    
    return (
        <Box className={styles.communityStatus}
            sx={{
                bgcolor: theme.palette.lightgrey.lightgrey00,
            }}
        >
            <Typography variant="body"
                className={styles.heading}
                sx={{ color: theme.palette.darkgrey.darkgrey600, }}
            >
                Personal Details
            </Typography>
            <Box className={styles.content}>
                {data.map((item, index) => (
                    item.label === "Age" ? (
                        <Box key={item.label} className={styles.same}>
                        <Box
                            sx={{
                            display: 'flex',
                            gap: '8px'
                            }}
                        >
                            <Typography variant="body2"
                            sx={{ color: theme.palette.darkgrey.darkgrey300 }}
                            >
                            {item.label}
                            </Typography>
                        </Box>
                        <Chip
                            label={"Gen " + item.value}
                        />
                        </Box>
                        ) : item.label === "Pronouns" ? (
                        <Box key={item.label} className={styles.same}>
                            <Typography variant="body2"
                                sx={{ color: theme.palette.darkgrey.darkgrey300 }}
                            >
                                {item.label}
                            </Typography>
                            <Chip
                                label={item.value}
                                sx={{
                                    color: darkMode
                                        ? theme.palette.primary.purpel100
                                        : theme.palette.primary.main,
                                    bgcolor: !darkMode
                                        && theme.palette.primary.purpel50,
                                }}
                            />
                        </Box>
                    ) : item.label === "Email Address" ? (
                        <Box key={item.label} className={styles.same}>
                            <Typography variant="body2"
                                sx={{ color: theme.palette.darkgrey.darkgrey300 }}
                            >
                                {item.label}
                            </Typography>
                            <Box className={styles.container}>  
                                {isVisible && <HitMeUp setIsVisible={setIsVisible} />}
                                {isVisible === false && 
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: darkMode
                                                ? theme.palette.darkgrey.darkgreybody50
                                                :theme.palette.darkgrey.darkgrey600,
                                        }}
                                    >
                                        {item.value}
                                    </Typography>
                                }        
                            </Box>
                        </Box>
                        ) :(
                                <Box key={item.label} className={styles.same}>
                                    <Typography variant="body2"
                                        sx={{ color: theme.palette.darkgrey.darkgrey300 }}
                                    >
                                        {item.label}
                                    </Typography>
                                    <Box className={styles.container}>  
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: darkMode
                                                    ? theme.palette.darkgrey.darkgreybody50
                                                    :theme.palette.darkgrey.darkgrey600,
                                            }}
                                        >
                                        {item.value}
                                        </Typography>    
                                        {item.label === "Profile Link" && (
                                            <Box>
                                                <IconButton
                                                    onClick={()=> handleCopyLink(item.value)}
                                                    sx={{
                                                        bgcolor: darkMode
                                                            ? '#525252'
                                                            : theme.palette.secondary.light,
                                                        display: isCopied? 'none': 'flex',
                                                        padding: '6px',
                                                    }}
                                                >
                                                    <Link sx={{width: '1.3rem', height: '1.3rem'}}/>
                                                </IconButton>
                                                {isCopied && <span style={{ marginLeft: '5px', color: theme.palette.secondary.blue100 }}>Copied!</span>}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            )
                    ))}
                
            </Box>

        </Box>
    );
}

export default PersonalDetails;
