import React, { useRef, useState, useEffect, useContext } from "react";

import "cropperjs/dist/cropper.min.css";
import { Box, Typography } from "@mui/material";
import styles from "./style.module.css";
import ImageCropper from "./cropper";
import supabase from "../../../../../../../config/supabaseClient";
import { uploadImage } from "../../../../../../../helpers/uploadImageToS3";
import { LoadingContext } from "../../../../../../../context/loading-context";
import imageCompression from "browser-image-compression";
import { ProjectImageContext } from "../../../../../../../context/project-cover-image-context";

const EditProjectImage = ({project}) => {
  
  const {
    setIsModalProjectOpen,
    setCroppedImage,
    selectedProjectImage,
    setSelectedProjectImage,
    setOriginalProjectImage,
    originalProjectImage
  } = useContext(ProjectImageContext);
  
  const [isProjectUploading, setIsProjectUploading] = useState(false);
  // const { isLoading, setIsLoading } = useContext(LoadingContext);

  const handleCrop = async (croppedImage) => {
    try {
      // Convert the Uint8Array to a Blob
      const blob = new Blob([croppedImage], { type: "image/png" });
      const originalBlob = blob;
      
      // Compress the image using browser-image-compression library
      const options = {
        maxSizeMB: 1, // Set the maximum size of the compressed image to 0.5 MB
        maxWidthOrHeight: 800, // Set the maximum width or height of the compressed image
        useWebWorker: true, // Use a web worker for faster compression
        quality: 1, // Adjust the quality parameter (0.7 is a good starting point)
      };

      const compressedBlob = await imageCompression(originalBlob, options);
      
      const blobURL = await uploadImage(
        compressedBlob,
        "project-cover-image-" + Math.random() + "-v-" + new Date().getTime()
      );

      setCroppedImage(blobURL);


      // handle original image uploade
      if (selectedProjectImage !== project?.original_cover_image) {
        const response = await fetch(selectedProjectImage);
        const blobData = await response.blob();
        
        // Compress the image using browser-image-compression library
        const options = {
          maxSizeMB: 1, // Set the maximum size of the compressed image
          maxWidthOrHeight: 800, // Set the maximum width or height of the compressed image
          useWebWorker: true, // Use a web worker for faster compression
          quality: 1,
        };

        const compressedBlob = await imageCompression(blobData, options);

        const originalImageUrl = await uploadImage(
          compressedBlob,
          "original-image-project-" + project.id + "-v-" + new Date().getTime()
        );

        setOriginalProjectImage(originalImageUrl);
        setSelectedProjectImage(originalImageUrl);
      } else {
        setOriginalProjectImage(project.original_cover_image);
      }
      

      setSelectedProjectImage(null);
      setIsModalProjectOpen(false);      
    } catch (error) {
      console.error("Error handling upload:", error);
    }

  };
  const handleCancel = () => {
    // setOriginalProjectImage(null);
    setSelectedProjectImage(null);
    setIsModalProjectOpen(false);
  };
  
  // const imgSrc = selectedProjectImage || originalProjectImage;
  console.log('imgSrc', selectedProjectImage);
  return (
    <Box className={styles.parent}>
      <Box className={styles.container}>
        <ImageCropper
          imageSrc={selectedProjectImage}
          onCrop={handleCrop}
          onCancel={handleCancel}
          isProfileUploading={isProjectUploading}
          setIsProfileUploading={setIsProjectUploading}
        />
      </Box>
    </Box>
  );
};

export default EditProjectImage;
