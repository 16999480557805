import { Box, Typography } from "@mui/material";
import styles from './style.module.css';
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../../../context/theme-context";
import Info from "../../../../../components/info";
import Angry from "../angry";
import Happy from "../happy";
import Sad from "../sad";
import { useInfoBoxs } from "../../../../../context/info-boxs-context";
import { set } from "react-ga";
import { orange } from "@mui/material/colors";
import { UserContext } from "../../../../../context/user-context";

const displayedText = {
    profile_image: 'Add a profile image',
    creator_types: 'Add more creator types',
    tagline: 'Add a tagline',
    mobile_number: 'Add a mobile number',
    skills: 'Add more skills',
    specialities: 'Add more specialties',
    portfolio_link: 'Add more portfolio links',
    cover_image: 'Add new thumbnails for each skill',
    primary_skill: 'Indicate your primary skill',
    shout_outs: 'Make shoutouts to other creators',
    projects: 'Post, join & like more projects',
    last_login: 'Login frequently',
    skills_description: "Add descriptions to your skills",
    form_submission: "Give feedback by clicking MAKE LYNX BETTER",
};
const maxPoints = {
    profile_image: 5,
    creator_types: 3,
    tagline: 5,
    mobile_number: 5,
    skills: 6,
    specialities: 3,
    portfolio_link: 15,
    cover_image: 60,
    primary_skill: 10,
    shout_outs: 20,
    projects: 70,
    last_login: 30,
    skills_description: 15,
    form_submission: 10,
}

const majorImpact = {};

const RatingsContent = ({userScores}) => {
    const { theme, darkMode } = useContext(ThemeContext);
    const { infoBoxes } = useInfoBoxs();
    const [green, setGreen] = useState([]);
    const [orange, setOrange] = useState([]);
    const [red, setRed] = useState([]);
    const { user } = useContext(UserContext);

    const renderCards = () => {
        const keys = Object.keys(displayedText);
        for (let item of keys) {
            // console.log(userScores[item]);
            if (userScores[item] >= maxPoints[item]) {
                setGreen((prev) => [...prev, item]);
                console.log(item, ": ", userScores[item], ":" , maxPoints[item], 'Green');
            } else if(userScores[item] <= 0 || userScores[item] == null ) {
                setRed((prev) => [...prev, item]);
                console.log(item, ": ", userScores[item], ":", maxPoints[item], 'Red');
            } else if(userScores[item] >0 && userScores[item] < maxPoints[item] ){
                setOrange((prev) => [...prev, item]);
                console.log(item, ": ", userScores[item], ":", maxPoints[item], 'Orange');
            }

        }
    }


    useEffect(() => {
        if (user && userScores && userScores !== null) {
            
            if ( Object.keys(userScores).length !== 0) {
                setGreen([]);
                setOrange([]);
                setRed([]);
                renderCards();
            }
        }
    },[user, userScores])

    const renderMajorImpact = red.map((key) => (
        <Angry txt={displayedText[key]} key={key} />
    ));
    
    const renderRecommendations = orange.map((key) => (
        <Sad txt={displayedText[key]} key={key} />    
    ));

    const renderCompleted = green.map((key) => (
        <Happy txt={displayedText[key]} key={key} />
    ));

    return (
        <Box>
            <Box className={styles.text}
                sx={{background: theme.palette.lightgrey.lightgrey500}}
            >
                <Box className={styles.header}>
                    <Typography variant="body1" sx={{ color: theme.palette.darkgrey.darkgrey700 }}>
                    Improve My Score
                    </Typography>
                    {infoBoxes.map((item) => {
                        if (item.name === 'improveMyScore') {    
                            return <Info
                                heading={item.title}
                                content={item.content}
                            />
                        }
                    })}
                </Box>
                <Box className={styles.card}>
                    {renderMajorImpact}
                    {renderRecommendations}
                </Box>
            </Box>
            
            <Box className={styles.text}
                sx={{background: theme.palette.lightgrey.lightgrey500}}
            >
                <Box className={styles.header}>
                    <Typography variant="body1" sx={{ color: theme.palette.darkgrey.darkgrey700 }}>
                    Completed Tasks
                    </Typography>
                </Box>
                <Box className={styles.card}>
                    {renderCompleted}
                </Box>
            </Box>
            
            
            {/* <Box className={styles.completed}
                sx={{
                    color: darkMode? theme.palette.success.main: theme.palette.success.dark,
                    bgcolor: darkMode? 'rgba(227, 253, 243, 0.08)': '#CDFEEA',
                }}
            >
                <Typography variant="body1"
                    sx={{
                        fontSize: '18px',
                        fontWeight: "700",
                        letterSpacing: '0.36px',
                        textTransform: 'uppercase',
                    }}
                >
                Completed
                </Typography>
                <Box className={styles.item}>
                    {renderCompleted()}
                </Box>
            </Box>
            <Box className={styles.completed}
                sx={{
                    color: darkMode? theme.palette.danger.red400: theme.palette.danger.red500,
                    bgcolor: darkMode? 'rgba(253, 204, 207, 0.08)': '#FFE0E0',
                }}
            >
                <Typography variant="body1"
                    sx={{
                        fontSize: '18px',
                        fontWeight: "700",
                        letterSpacing: '0.36px',
                        textTransform: 'uppercase',
                    }}
                >
                Major Impact
                </Typography>
                <Box className={styles.item}>
                    {renderMajorImpact()}
                </Box>
            </Box>
            <Box className={styles.completed}
                sx={{
                    color: darkMode? theme.palette.warning.orange400: theme.palette.warning.orange500,
                    bgcolor: darkMode? 'rgba(252, 188, 114, 0.08)': '#FEF3CD',
                    
                }}
            
            >
                <Typography variant="body1"
                    sx={{
                        fontSize: '18px',
                        fontWeight: "700",
                        letterSpacing: '0.36px',
                        textTransform: 'uppercase',
                    }}
                >
                OTHER RECOMMENDATIONS
                </Typography>
                <Box className={styles.item}>
                    {renderRecommendations()}
                </Box>
            </Box> */}
        </Box>
    )
}

export default RatingsContent;