import { Box, Button, Chip, Divider, Typography } from "@mui/material";
import styles from './style.module.css';
import { useTheme } from "../../../../../../../../context/theme-context";
import profileImage from "../../../../../../../../assets/images/profileImage.png";
import { useEffect, useState } from "react";
import DefaultState from "./components/default";
import AcceptedState from "./components/accepted";
import Options from "./components/options";
import { defaultProfilePrefix } from "../../../../../../../../constants";

const ApplicantCard = ({item, key, setTotalApplications}) => {
    const { theme, darkMode } = useTheme();
    const [status, setStatus] = useState(item.status);

    useEffect(() => {
        setStatus(item.status)
    }, [item])

    return (
        <Box className={styles.card}
            key={key}
            sx={{
                bgcolor: darkMode? "#333": "#FFF",
            }}
        >
            <Box className={styles.content}>
                <Box className={styles.pic}
                    sx={{
                        backgroundImage: `url(${item.profile_image})`
                    }}
                ></Box>
                <Box className={styles.text}>
                    <Box className={styles.name}>
                        <Typography variant="body1"
                            color={theme.palette.darkgrey.darkgrey700}
                            sx={{
                                fontSize: '16px',
                                fontWeight: '700',
                            }}
                        >{item.real_name}</Typography>
                        <Chip
                            label="Pro"
                            sx={{
                                display: !item.has_accept_only_paid_project && 'none',
                                height: 'auto',
                                fontSize: '12px',
                                p: '4px 12px',
                                color: '#FFF',
                                bgcolor: theme.palette.warning.main,
                            }}
                        />
                    </Box>
                    <Typography variant="body1"
                        color={theme.palette.darkgrey.darkgrey400}
                        component={'a'}
                        href={`${'/creators'}/${item.user_id}`}
                        sx={{
                            textDecoration:'none',
                            display: `-webkit-box`,
                            webkitBoxOrient: 'vertical',
                            webkitLineClamp: 1,
                            alignSelf: 'stretch',
                            overflow: 'hidden',
                            fontSize: '13px',
                            fontWeight: '500',
                        }}
                    >{`${defaultProfilePrefix}${item.user_id}`}</Typography>
                </Box>
            </Box>
            <Divider flexItem />
            {status === "Accepted" || status === "Completed" ? 
                <AcceptedState
                    setStatus={setStatus}
                    status={status}
                    item={item}
                /> :
                <DefaultState setTotalApplications={setTotalApplications} item={item} setStatus={setStatus} />
            }
            {status === "Completed" && <Options item={item} setStatus={setStatus} />}
        </Box>
    );
};

export default ApplicantCard;