import { Box, Typography } from '@mui/material';
import styles from './style.module.css';
import { useTheme } from '../../../../../../context/theme-context';
import Card from './card';
import image1 from '../../../../../../assets/images/project-manage-card1.png';
import image2 from '../../../../../../assets/images/project-manage-card2.png';
import { ManageProjectsContext } from '../../../../../../context/manage-projects-context';
import { useContext } from 'react';

const projects = [
    {
        image: image1,
        name: 'Social Impact',
        category: 'musics',
        location: 'LA',
    },
    {
        image: image1,
        name: 'Entertainment and Media',
        category: 'musics',
        location: 'LA',
    },
    {
        image: image1,
        name: 'Education and Learning',
        category: 'musics',
        location: 'LA',
    },
    {
        image: image1,
        name: 'Art and Creativity',
        category: 'musics',
        location: 'LA',
    },
    {
        image: image1,
        name: 'Environmental Sustainability',
        category: 'musics',
        location: 'LA',
    },
    {
        image: image1,
        name: 'Health and Wellness App',
        category: 'musics',
        location: 'LA',
    },
]


const Applications = () => {
    const { theme } = useTheme();
    const { applications } = useContext(ManageProjectsContext);

    const renderProjects = applications.map((project, index) => {
        return (
            <Card
                key={index}
                project={project}
            />
        )
    })

    return (
        <Box className={styles.hosted}>
            <Typography variant='body1'
                sx={{
                    color: theme.palette.darkgrey.darkgrey600,
                    fontSize: '24px',
                    fontWeight: '500',
                }}
            >{applications.length} Project Applications</Typography>
            <Box className={styles.content}>
                {renderProjects}
            </Box>
        </Box> 
    );
}

export default Applications;