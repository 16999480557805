import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import styles from "./style.module.css";
import { ChevronLeft } from "@mui/icons-material";
import CreatorType2 from "../components/creator-type2";
import Logo2 from "../components/logo2";
import { useNavigate } from "react-router-dom";
import HWCThanks from "./HWCThanks";
import { useTheme } from "../../../../context/theme-context";

const HelpWithClassification = () => {
  const { theme } = useTheme();
  const [isOpenThanks, setIsOpenThanks] = useState(false);
  const navigate = useNavigate();
  return (
    <Box
    
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey500,
      }}
    >
      <HWCThanks
        isOpenThanks={isOpenThanks}
        setIsOpenThanks={setIsOpenThanks}
      />
      <Box className={styles.container}>
        <Box className={styles.arrow}>
          <Button
            startIcon={<ChevronLeft />}
            onClick={()=> navigate("/profile")}
            sx={{
              textTransform: 'capitalize',
              fontSize: '14px',
              fontWeight: '500',
              color: theme.palette.darkgrey.darkgrey500,
            }}
          >
            Back
          </Button>
        </Box>
        <Logo2 />
        <CreatorType2 setIsOpenThanks={setIsOpenThanks} />
      </Box>
    </Box>
  );
};

export default HelpWithClassification;
