import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Chip, Link } from "@mui/material";
import styles from "./styles.module.css";
import {
  Edit,
  EditNoteOutlined,
  EditOutlined,
  EmojiEvents,
  EmojiEventsOutlined,
  FileUploadOutlined,
} from "@mui/icons-material";

import { SkillsBarContext } from "../../../../../context/skills-bar-context";
import CoverImageUploader from "./cover-image-uploader";
import { SkillImageContext } from "../../../../../context/skill-image-context";
import { useTheme } from "../../../../../context/theme-context";

const SkillCoverImage = () => {
  const { theme } = useTheme();
  const { activeSkill } = useContext(SkillsBarContext);

  const { setIsModalSkillOpen } = useContext(SkillImageContext);
  const [skill_cover_image, setSkillCoverImage] = useState(
    activeSkill?.skill_cover_image
  );

  useEffect(() => {
    setSkillCoverImage(activeSkill?.skill_cover_image);
  }, [activeSkill]);

  // useEffect(() => {}, [skill_cover_image, activeSkill]);
  return (
    <Box
      className={styles.skillsImg}
      sx={{
        // bgcolor: theme.palette.lightgrey.lightgrey00,
      }}
    >
      {activeSkill?.is_editor_choice && (
        <Chip
          label={"Creators' Choice"}
          variant="contained"
          icon={<EmojiEventsOutlined />}
          sx={{
            textTransform: "uppercase",
            padding: "6px 16px 6px 3px",
            gap: "14px",
            bgcolor: theme.palette.primary.main,
            color: "#FFF",
            "& svg": {
              color: "inherit !important",
            },
          }}
        />
      )}

      <Box
        className={styles.primaryImg}
        sx={{
          backgroundImage: skill_cover_image
            ? `url(${skill_cover_image})`
            : "none",
        }}
      >
        {!skill_cover_image && <CoverImageUploader />}
        <Box className={styles.options}>
          {activeSkill.is_primary && (
            <>
              <Chip
                label="Primary Skill"
                sx={{
                  bgcolor: theme.palette.warning.main,
                  color: "#FFF",
                }}
              />
            </>
          )}
          {activeSkill.accept_only_paid_project && (
            <Chip
              label="Pro"
              sx={{
                bgcolor: theme.palette.warning.main,
                color: "#FFF",
              }}
            />
          )}
          {skill_cover_image && (
            <Box
              className={styles.Cameraupload}
              onClick={() => setIsModalSkillOpen(true)}
            >
              <EditOutlined
                fontSize="16px"
                sx={{ color: theme.palette.darkgrey.darkgrey400 }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SkillCoverImage;
