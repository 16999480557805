import React, { useContext, useState } from "react";
import styles from "./style.module.css";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import Top from "./top";
import Menu from "./menu";
import { useTheme } from "../../../context/theme-context";
import Mode from "./mode";
import { LoadingContext } from "../../../context/loading-context";
import Loading from "../../../components/loading/default";

const Landing = () => {
  const { theme } = useTheme();
  const { isLoading } = useContext(LoadingContext);

  if (isLoading) {
    return <Loading />
  }
  return (
    <Box
      className={styles.landing}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey600,
      }}
    >
      <Box className={styles.container}>
        <Box className={styles.sideDrawer}>
          <Top />
          <Divider sx={{ width: "100%" }} />
          <Menu />
          <Mode />
        </Box>
      </Box>
    </Box>
  );
};
export default Landing;
