import { Box, Button } from "@mui/material";
import React from "react";
import EditSkill from "./comonents/editSkill";
import EditDescription from "./comonents/editDescription";
import { useTheme } from "../../../../../context/theme-context";

const AddSkill = () => {
  const { theme } = useTheme();
  return (
    <Box sx={{bgcolor: theme.palette.lightgrey.lightgrey00}}>
      <EditSkill />
      <EditDescription />
    </Box>
  );
};

export default AddSkill;
