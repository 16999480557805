import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Paper, Typography } from "@mui/material";
import styles from "./creator-registration.module.css";
import HorizontalLinearStepper from "./Stepper/stepper";

import { ReversedRegistrationContext } from "../../context/reversed-registration-context";

import { LoadingContext } from "../../context/loading-context";
import { useNavigate, useLocation } from "react-router";

import Thanks from "./RegisterationThanks/thanks";
import UseLocalStorageState from "../../hooks/userLocalStorage";

import { useTheme } from "../../context/theme-context";

const CreatorReversedRegistration = () => {
  const [isRegisterationFinished, setIsRegisterationFinished] =
    UseLocalStorageState("registeration-finished", false);
  // const [isLoading, setIsLoading] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { handleDataComplete, completedInfo } = useContext(
    ReversedRegistrationContext
  );
  const { darkMode, setDarkMode, theme } = useTheme();

  const [tempTheme, setTempTheme] = useState(darkMode);

  useEffect(() => {
    if (completedInfo && isRegisterationFinished) return;
    if (!completedInfo && isRegisterationFinished)
      setIsRegisterationFinished(false);
  }, []);
  useEffect(() => {
    const shouldStoreQueries =
      location.search.split("=")[0] !== "?code" && location.search !== "";
    if (shouldStoreQueries) {
      console.log(location.search, "from setting location search");
      localStorage.setItem("starter-queries", location.search);
    }
    setDarkMode(true);
  }, []);
  return (
    <React.Fragment>
      {isRegisterationFinished && (
        <Thanks tempTheme={tempTheme} handleDataComplete={handleDataComplete} isLoading={isLoading} />
      )}
      {isRegisterationFinished === false && (
        <Box
          sx={{
            backgroundColor: theme.palette.lightgrey.lightgrey600,
            minHeight: "100vh",
            padding: "1.5rem",
          }}
        >
          <Box
            className={styles.parent}
            sx={{
              backgroundColor: theme.palette.lightgrey.lightgrey00,
            }}
          >
            <Box className={styles.basicLogo}>
              <Container className={styles.content}>
                {/* <img src={LynxLogo} className={styles.logo} alt="Logo" /> */}
                <Box className={styles.text}>
                  <Typography
                    variant="body1"
                    align="center"
                    color={"#FFF"}
                    sx={{
                      fontSize: "36px",
                      fontWeight: "700",
                      lineHeight: "44px",
                      textTransform: "uppercase",
                      textAlign: "center",
                    }}
                  >
                    Creator Registration
                  </Typography>
                  <Typography variant="body1" align="center" color={"#FFF"}>
                    Apply in less than 60 seconds
                  </Typography>
                </Box>
              </Container>
            </Box>

            <Box className={styles.stepper}>
              <HorizontalLinearStepper
                setIsRegisterationFinished={setIsRegisterationFinished}
              />
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default CreatorReversedRegistration;
