import { Box, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import styles from "./style.module.css";
import rockAndRoll from "../../../../../assets/images/rock-and-roll.png";
import { useLocaleText } from "@mui/x-date-pickers/internals";
import { useLocation } from "react-router-dom";
import supabase from "../../../../../config/supabaseClient";
import { UserContext } from "../../../../../context/user-context";
import { useTheme } from "../../../../../context/theme-context";

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.slice(0, maxLength) + "...";
  }
}
const Top = ({ data }) => {
  const { theme } = useTheme();
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [gaveShoutOut, setGaveShoutOut] = useState(true);
  const cancelShoutOut = async () => {
    if (gaveShoutOut) {
      const { error } = await supabase
        .from("user_shoutouts")
        .delete()
        .match({ receiver_id: data.receiver_id, sender_id: user?.id });
      if (!error) {
        setGaveShoutOut(false);
      }
    }
  };

  const handleShoutOutClick = async () => {
    if (location.pathname !== "/profile") return;
    if (gaveShoutOut) {
      cancelShoutOut();
      return;
    }
    const { error } = await supabase
      .from("user_shoutouts")
      .insert({ receiver_id: data.receiver_id, sender_id: user?.id });
    if (!error) setGaveShoutOut(true);
  };

  return (
    <Box className={styles.top}>
      <Box className={styles.profile}>
        <Box
          className={styles.image}
          sx={{
            backgroundImage: `url(${data.profile_image})`,
            width: "32px",
            height: "32px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        ></Box>
        <Box className={styles.name}>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.darkgrey.darkgrey600,
            }}
          >
            {truncateText(data.real_name, 5)}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              color: theme.palette.darkgrey.darkgrey300,
            }}
          >
            {data.nick_name}
          </Typography>
        </Box>
      </Box>
      <Box
        onClick={handleShoutOutClick}
        className={styles.Shouts}
        sx={{
          bgcolor: gaveShoutOut
            ? theme.palette.warning.main
            : theme.palette.darkgrey.darkgrey400,
        }}
      >
        <img src={rockAndRoll} width="100%" height="100%" alt="rock-and-roll" />
      </Box>
    </Box>
  );
};

export default Top;
