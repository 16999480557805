import React, { useState, useRef, useEffect } from "react";
// import Cropper from "react-easy-crop";
import { getCroppedImg } from "./cropUtils"; // You'll need to implement this utility function
import styles from "./style.module.css";
import { Box, Typography } from "@mui/material";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";
import LoadingButton from "../../../../../../components/loading/button";

const ASPECT_RATIO = 700/735;
const MIN_DIMENSION = 150;

const ImageCropper = ({
  imageSrc,
  onCrop,
  onCancel,
  isProfileUploading,
  setIsProfileUploading,
}) => {

  const [crop, setCrop] = useState();
  const imgRef = useRef(null);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
    console.log('test crop: ', crop, width, height);
  }

  const handleCrop = async () => {
    const croppedImage = await getCroppedImg(
      imgRef.current,
      convertToPixelCrop(
        crop,
        imgRef.current.width,
        imgRef.current.height
      )
    );

    onCrop(croppedImage);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#040d12',
        height: '100%'
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: {
            xs: '100%',
            md: "400px",
          },
          height: '100%'
        }}
      >
        {imageSrc && (
          <Box className={styles.crop}>
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentCrop) => { setCrop(percentCrop); console.log("test persent crop:",percentCrop)}}
              circularCrop
              keepSelection
              aspect={ASPECT_RATIO}
              minWidth={MIN_DIMENSION}
            >
              <Box
                sx={{
                  maxWidth: '350px',
                  maxHeight: '367.5px',
                  bgcolor: 'inherit'
                  // aspectRatio: '700 / 735',
                }}
              >
                <img
                  ref={imgRef}
                  src={imageSrc}
                  alt="upload"
                  crossOrigin = 'anonymous'
                  style={{width: '100%' , height: '100%', objectFit: 'cover'}}
                  onLoad={onImageLoad}
                />
              </Box>
            </ReactCrop>  
          </Box>
        )}
        <Box className={styles.frame}>
          <Box className={styles.content}>
            <Typography
              variant="body1"
              fontWeight={500}
              onClick={() => onCancel()}
              sx={{
                cursor: "pointer",
              }}
            >
              Cancel
            </Typography>
            <Typography
              variant="body1"
              fontWeight={500}
              onClick={handleCrop}
              sx={{
                cursor: "pointer",
              }}
            >
              <div onClick={() => setIsProfileUploading(true)}>
                {isProfileUploading ? <LoadingButton /> : "choose"}
              </div>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageCropper;
