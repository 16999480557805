import React, { useContext, useState } from "react";
import styles from "./step4.module.css";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

import { ReversedRegistrationContext } from "../../../context/reversed-registration-context";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import { useTheme } from "../../../context/theme-context";

const Step4 = ({ setIsRegisterationFinished, collaboratorsTypes }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const {
    collaboratorsTypes: selectedCollaboratorsTypes,
    setCollaboratorsTypes,
    setCompletedInfo,
  } = useContext(ReversedRegistrationContext);
  const handleFinishClick = () => {
    setIsRegisterationFinished(true);
    setCompletedInfo(true);
  };
  const handleToggle = (value) => () => {
    const itemIndex = selectedCollaboratorsTypes.findIndex(
      (item) => item.category === value.category
    );
    if (itemIndex === -1) {
      return setCollaboratorsTypes((items) => [...items, value]);
    }
    // item exists remove it
    const filteredItems = selectedCollaboratorsTypes.filter(
      (item) => item.category !== value.category
    );
    setCollaboratorsTypes([...filteredItems]);
  };

  return (
    <Box className={styles.form}>
      <Box
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey400,
          p: '1.5rem',
        }}
      >
        <Typography variant="body1"
          color={theme.palette.darkgrey.darkgrey600}
          sx={{
            fontSize: '18px',
            fontWeight: '700',
          }}
        >
          LYNX is a collaboration platform for creators to discover other
          creators. Who would you like to collab with? (Choose as many as you
          like!)
        </Typography>
        <List sx={{
          width: "100%",
          mt: "24px",
          m: 0,
          color: theme.palette.darkgrey.darkgrey500,
        }}>
          {collaboratorsTypes.map((type) => {
            const labelId = `checkbox-list-label-${type.category}`;
            return (
              <ListItem
                key={labelId}
                disablePadding
                sx={{
                  padding: 0,
                  "& .css-cveggr-MuiListItemIcon-root": {
                    minWidth: "auto",
                  },
                }}
              >
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(type)}
                  dense
                  sx={{
                    paddingLeft: "0px",
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={
                        selectedCollaboratorsTypes.findIndex(
                          (item) => item.category === type.category
                        ) !== -1
                      }
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={`${type.collaborator_description}`}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Box sx={{p: '1.5rem'}}>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          onClick={handleFinishClick}
          className={ styles.next}
          endIcon={
            <ChevronRight/>
          }
          sx={{
            p: "8px 13px 8px 24px",
            justifyContent: 'space-between',
            "& span:nth-of-type(1)": {
              background: theme.palette.primary.purpel400,
              height: '2em',
              width: '2em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
            }
          }}
        >
          Next Step
        </Button>
      </Box>
    </Box>
  );
};

export default Step4;
