import { Box, Button, Typography } from "@mui/material";
import styles from "./style.module.css";
import Top from "./top";
import Image from "./image";
import { useTheme } from "../../../../../../context/theme-context";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Card = ({ project }) => {
  const { theme, darkMode } = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(project);
  }, []);

  // const handleViewProjectClick = () => {
  //     navigate('/project-details');
  // }

  return (
    <Box
      className={styles.card}
      sx={{
        bgcolor: darkMode? theme.palette.lightgrey.lightgrey400: "FFF",
      }}
    >
      <Top
        name={project.real_name}
        nickName={project.nick_name}
        image={project.profile_image}
        label={project.category}
      />
      <Image image={project.project_cover_image} />
      <Box className={styles.text}>
        <Typography
          component="h4"
          variant="body1"
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: theme.palette.darkgrey.darkgrey600,
            // display: "-webkit-box",
            // WebkitBoxOrient: "vertical",
            // overflow: "hidden",
            WebkitLineClamp: 1,
            // textOverflow: "ellipsis",
            minHeight: "1.5em",
          }}
        >
          {`${project.project_title}`}
        </Typography>
        <Typography
          className={styles.description}
          variant="body1"
          sx={{
            fontSize: "16px",
            color: theme.palette.darkgrey.darkgrey500,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 3,
            textOverflow: "ellipsis",
            minHeight: "4.5em",
          }}
        >
          {project.project_description}
        </Typography>
      </Box>
      <Box
        className={styles.button}
        sx={{
          p: "8px 16px 16px",
        }}
      >
        <Button
          variant="contained"
          onClick={() => navigate(`/projects/${project.project_id}`)}
          sx={{
            textTransform: "capitalize",
            flex: "1",
            p: "10px 1.5rem",
            borderRadius: "4px",
          }}
        >
          View Project
        </Button>
      </Box>
    </Box>
  );
};

export default Card;
