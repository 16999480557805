import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Chip, Link } from "@mui/material";
import styles from "./styles.module.css";
import {
  EditOutlined,
} from "@mui/icons-material";

import CoverImageUploader from "./cover-image-uploader";
import { useTheme } from "../../../../../../../context/theme-context";
import UploadProjectImage from "./upload-profile-image";
import { ProjectImageContext } from "../../../../../../../context/project-cover-image-context";
import EditProjectImage from "./edit";
import DeleteProjectImage from "./delete";

const ProjectCoverImage = () => {
  const { theme } = useTheme();
  const {
    setIsModalProjectOpen,
    croppedImage,
    selectedProjectImage,
    setSelectedProjectImage,
    isDeleteProjectImageOpen,
    originalProjectImage
  } = useContext(ProjectImageContext);

  const [project_cover_image, setProjectCoverImage] = useState(croppedImage);

  useEffect(() => {
    setProjectCoverImage(croppedImage);
  }, [croppedImage]);
  useEffect(() => {
    setSelectedProjectImage(originalProjectImage);
  }, []);


  return (
    <Box className={styles.skillsImg}>
      <Box
        className={styles.primaryImg}
        sx={{
          backgroundImage: project_cover_image
            ? `url(${project_cover_image})`
            : "none",
        }}
      >
        {!project_cover_image && <CoverImageUploader />}
        <Box className={styles.options}>
          {project_cover_image && (
            <Box
              className={styles.Cameraupload}
              onClick={() => setIsModalProjectOpen(true)}
            >
              <EditOutlined
                fontSize="16px"
                sx={{ color: theme.palette.darkgrey.darkgrey400 }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <UploadProjectImage projectCoverImage={project_cover_image} />
      {selectedProjectImage && (
          <EditProjectImage/>
      )}
      {isDeleteProjectImageOpen && <DeleteProjectImage />}
    </Box>
  );
};

export default ProjectCoverImage;
