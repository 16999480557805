import { Box } from "@mui/material";
import styles from "./style.module.css";
import Card from "./card";
import { useContext, useState } from "react";
import { DiscoverContext } from "../../../../../context/discover-context";
import LoadingButton from "../../../../../components/loading/button";
import ProjectCard from "./projectCard";
import Fireworks from "../../../../../components/common/FireWorks";
import ConfettiComponent from "../../../../../components/common/FireWorks";

const GridResult = ({ projects }) => {
  const { loading } = useContext(DiscoverContext);
  const [isConfettiVisible, setIsConfettiVisible] = useState(false);
  
  const handleClick = () => {
    setIsConfettiVisible(true);
    setTimeout(() => setIsConfettiVisible(false), 5000); // Hide confetti after 5 seconds
  };

  return (
    <Box className={styles.result}>
      <ConfettiComponent isConfettiVisible={isConfettiVisible} />
      {projects?.map((item, index) => (
          item.key === 'user' && <Card key={index} setConfetti={handleClick} project={item} /> ||
          item.key === 'project' && <ProjectCard key={index} project={item} setConfetti={handleClick} />
      ))}
      {loading && <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><LoadingButton /></Box> }
      {/* {loading && <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><LoadingButton /></Box> } */}
    </Box>
  );
};

export default GridResult;
