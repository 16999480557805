import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import BackButton from "../back-button";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../context/theme-context";
import { UserContext } from "../../../context/user-context";
import supabase from "../../../config/supabaseClient";

const EmailPreferences = () => {
  const { theme } = useTheme();
  const [emailPreference, setEmailPreference] = useState(null);
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const handleSave = async () => {
    // insert into the database
    const { error, data } = await supabase
      .from("users")
      .update({
        email_preference: emailPreference,
      })
      .eq("id", user.id);

    // select user
    const { errorSelect, data: dataSelect } = await supabase
      .from("users")
      .select("*")
      .eq("id", user.id);

    // update user context
    setUser(dataSelect[0]);

    // back to settings
    navigate("/settings");
  };
  if (!user) {
    return <div>Loading</div>;
  }
  return (
    <Box
      className={styles.subStatus}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey600,
      }}
    >
      <BackButton />
      <Box className={styles.container}>
        <Box className={styles.details}>
          <Box className={styles.content}>
            <Typography
              variant="body1"
              component="h3"
              sx={{
                color: theme.palette.darkgrey.darkgrey700,
                fontSize: "24px",
                fontWeight: "500",
              }}
            >
              Email Preferences
            </Typography>
            <FormControl
              sx={{
                color: theme.palette.darkgrey.darkgrey500,
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                },
              }}
            >
              <RadioGroup
                name="emailPreference"
                defaultValue={user.email_preference}
                onChange={(e) => setEmailPreference(e.target.value)}
              >
                <FormControlLabel
                  value="Daily matches & updates"
                  control={<Radio />}
                  label="Daily matches & updates"
                />
                <FormControlLabel
                  value="Weekly matches & updates"
                  control={<Radio />}
                  label="Weekly matches & updates"
                />
                <FormControlLabel
                  value="Monthly matches & updates"
                  control={<Radio />}
                  label="Monthly matches & updates"
                />
                <FormControlLabel
                  value="No matches & updates (complete opt-out)"
                  control={<Radio />}
                  label="No matches & updates (complete opt-out)"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Button variant="contained" fullWidth onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default EmailPreferences;
