import { Box, Button, Typography } from "@mui/material";
import React from "react";
import logo from "../../../assets/images/Color=Gredient, Size=Large.svg";
import icon1 from "../../../assets/images/project-landing-icon1.png";
import icon2 from "../../../assets/images/project-landing-icon2.png";
import icon3 from "../../../assets/images/project-landing-icon3.png";

import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../context/theme-context";

const ProjectLanding = () => {
  const { theme, darkMode } = useTheme();
  const navigate = useNavigate();

  const handlePostClick = () => {
    // Use navigate to go to the "/projct-board-submission" route
    navigate("/project-board-submission");
  };
  const handleJoinClick = () => {
    navigate("/project-join");
  };
  const handleManageClick = () => {
    navigate("/project-manage");
  };

  return (
    <Box className={styles.landing}>
      <Box
        className={styles.container}
        sx={{ backgroundColor: theme.palette.lightgrey.lightgrey500 }}
      >
        <Box className={styles.logo}>
          <img src={logo} width="234px" height="80px" alt="LYNX Logo" />
        </Box>
        <Box className={styles.buttons}>
          <Box className={styles.clickButton}
            onClick = {handlePostClick}
            sx={{
              bgcolor: darkMode ? theme.palette.lightgrey.lightgrey400 :
                theme.palette.lightgrey.lightgrey00
            }}
          >
            <Box className={styles.icon}>
              <img src={icon1} alt="icon" />
            </Box>
            <Typography variant="body1"
              sx={{
                fontSize: '18px',
                fontWeight: 700,
                color: darkMode? theme.palette.primary.light: theme.palette.primary.main,
                textAlign: 'center',
              }}  
            >Post a project</Typography> 
          </Box>
          <Box className={styles.clickButton}
            onClick = {handleJoinClick}
            sx={{
              bgcolor: darkMode ? theme.palette.lightgrey.lightgrey400 :
                theme.palette.lightgrey.lightgrey00
            }}
          >
            <Box className={styles.icon}>
              <img src={icon2} alt="icon" />
            </Box>  
            <Typography variant="body1"
              sx={{
                fontSize: '18px',
                fontWeight: 700,
                color: darkMode? theme.palette.primary.light: theme.palette.primary.main,
                textAlign: 'center',
              }}  
            >Join a project</Typography>
          </Box>
          <Box className={styles.clickButton}
            onClick={handleManageClick}
            sx={{
              bgcolor: darkMode ? theme.palette.lightgrey.lightgrey400 :
                theme.palette.lightgrey.lightgrey00
            }}
          >
            <Box className={styles.icon}>
              <img src={icon3} alt="icon" />
            </Box>  
            <Typography variant="body1"
              sx={{
                fontSize: '18px',
                fontWeight: 700,
                color: darkMode? theme.palette.primary.light: theme.palette.primary.main,
                textAlign: 'center',
              }}  
            >Manage my projects</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectLanding;
