import { Box } from "@mui/material";
import styles from "./style.module.css";

import SkillsList from "./skills-list";
import { useState } from "react";

const SkillsBar = ({ activeSkill, setActiveSkill, skills }) => {
  return (
    <Box className={styles.skills}
    sx={{
      justifyContent: skills.length < 3 ? 'flex-start' : 'space-between',  
    }}
    >
      <SkillsList
        activeSkill={activeSkill}
        setActiveSkill={setActiveSkill}
        skills={skills}
      />
    </Box>
  );
};

export default SkillsBar;
