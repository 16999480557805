import { Box, Button, Typography } from "@mui/material";
import removeImage from "../../../../../../../../../../assets/images/trash-02.svg";
import removeImageDark from "../../../../../../../../../../assets/images/trash-02-dark.svg";
import styles from './style.module.css';
import { useTheme } from "../../../../../../../../../../context/theme-context";
import supabase from "../../../../../../../../../../config/supabaseClient";
import { useContext, useState } from "react";
import { ManageProjectsContext } from "../../../../../../../../../../context/manage-projects-context";
import DeleteConfirmation from "../delete-confirmation";
import { UserContext } from "../../../../../../../../../../context/user-context";
import { sendProjectApplicationAcceptedEmail, sendRemovedByHostEmail } from "../../../../../../../../../../helpers/emails";

const DefaultState = ({setStatus, item, setTotalApplications}) => {
    const { theme, darkMode } = useTheme();
    const { projectApplicants, setProjectApplicants, handleDeleteApplicants } = useContext(ManageProjectsContext);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const { user } = useContext(UserContext);

    const handleAccept = async() => {
        setStatus('Accepted');
        const { data, error } = await supabase
            .from('projects_applications')
            .update({ status: 'Accepted' })
            .eq('project_id', item.project_id)
            .eq('user_id', item.user_id);
        if (error) {
            throw error;
        } 

        // active campaign
        await sendProjectApplicationAcceptedEmail(item.user_id, item.project_id);
    }

    const handleRemove = async(item) => {
        // handle Remove here
        const updatedApplicants = projectApplicants.filter(
            app => { console.log(app, 'from remove app'); return app.application_id !== item.application_id; } 
            
        );
        setProjectApplicants(updatedApplicants);
        setTotalApplications(projectApplicants.length - 1)
        handleDeleteApplicants(item);
    
    }
    
    return (
        <Box className={styles.actions}>
            <DeleteConfirmation
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
                onDelete={handleRemove}
                project={item}
                text={'remove'}
            />
            <Box className={styles.remove}
                onClick={()=>setIsDeleteModalOpen(true)}
            >
                <img src={darkMode? removeImageDark: removeImage} alt="remove" />
                <Typography variant="body1"
                    color={theme.palette.darkgrey.darkgrey400}
                    sx={{
                        fontSize: '12px',
                        fontWeight: '700',
                        letterSpacing: 2,
                        textTransform: 'uppercase'
                    }}
                >Remove Application</Typography>
            </Box>
            <Button variant="contained"
                onClick={ handleAccept }
                sx={{
                    fontSize: '12px',
                    fontWeight: '700',
                    padding: '8px 20px',
                    letterSpacing: 1,
                }}
            >Accept</Button>
        </Box>
    )
}

export default DefaultState;