import React, { useContext, useState } from "react";
import { Box, Button, Chip } from "@mui/material";
import styles from "./style.module.css";
import { FileUploadOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTheme } from "../../../../../../../context/theme-context";
import { ProjectImageContext } from "../../../../../../../context/project-cover-image-context";

const CoverImageUploader = () => {
  const { setIsModalProjectOpen} = useContext(ProjectImageContext);
  const { theme, darkMode } = useTheme();
  
  return (
    <Box
      className={styles.primarySkills}
      sx={{
        borderColor: darkMode? '#7B7A7A' : theme.palette.lightgrey.lightgrey800,
        background: darkMode? '#333333': theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Box className={styles.content}>
        <Button
          variant="text"
          onClick={()=> setIsModalProjectOpen(true)}
          startIcon={<FileUploadOutlined />}
          sx={{
            color: darkMode
              ? theme.palette.darkgrey.darkgrey300
              : theme.palette.darkgrey.darkgrey400,
            textTransform: "initial",
            fontSize: '16px',
            fontWeight: '400',
            "& .css-gcc2o7-MuiButton-startIcon>*:nth-of-type(1)": {
              fontSize: '24px'
            }
          }}
        >
          Upload a header image
        </Button>
      </Box>
    </Box>
  );
};
export default CoverImageUploader;
