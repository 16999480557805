import {
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Fade,
  IconButton,
  Link,
  Tooltip,
  Typography,
  capitalize,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import supabase from "../../../../../config/supabaseClient";
import { useParams } from "react-router-dom";
import { useTheme } from "../../../../../context/theme-context";

const Collaborate = () => {
  const { theme } = useTheme();
  const [collaborators, setCollaborators] = useState([]);
  const [ageRagnes, setAgeRanges] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const fetchCollaborators = async () => {
      const { data } = await supabase
        .from("creator_desired_collaborators")
        .select("*")
        .eq("user_id", id);

      setCollaborators(data);
    };
    const fetchAgeRanges = async () => {
      const { data, error } = await supabase
        .from("collaborat_age_ranges")
        .select("*")
        .eq("user_id", id);
      setAgeRanges(data || []);
    };
    fetchAgeRanges();
    fetchCollaborators();
  }, [id]);

  return (
    <Box
      className={styles.communityStatus}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Box className={styles.content}>
        <Typography
          variant="body"
          className={styles.heading}
          sx={{ color: theme.palette.darkgrey.darkgrey600 }}
        >
          I am looking to collaborate with:
        </Typography>
        <Box
          className={styles.tags}
          sx={{
            flexWrap: "wrap",
          }}
        >
          {collaborators.map((collaborate, index) => (
            <Chip
              key={collaborate.type}
              label={collaborate.display_name}
            />
          ))}
        </Box>
      </Box>
      <Box className={styles.content}>
        <Typography
          variant="body"
          className={styles.heading}
          sx={{ color: theme.palette.darkgrey.darkgrey600 }}
        >
          Age Range
        </Typography>
        <Box
          className={styles.tags}
          sx={{
            flexWrap: "wrap",
          }}
        >
          {ageRagnes.map((item) => {
            return (
              <Chip
                label={`Gen ${item.age_category}`}
                key={item.age_category}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Collaborate;
