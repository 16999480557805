import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Input,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  AccountCircle,
  Close,
  FileUploadOutlined,
  Person,
  Person2,
  UploadFile,
} from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import uploadIcon from "../../../../../../../../assets/images/upload-01.svg";
import cameraIcon from "../../../../../../../../assets/images/camera-01.svg";
import editIcon from "../../../../../../../../assets/images/edit-03.svg";
import deleteIcon from "../../../../../../../../assets/images/trash-01.svg";
import { SkillImageContext } from "../../../../../../../../context/skill-image-context";
import { SkillsBarContext } from "../../../../../../../../context/skills-bar-context";
import { useTheme } from "../../../../../../../../context/theme-context";
import { ProjectImageContext } from "../../../../../../../../context/project-cover-image-context";
import { creatorDefaultImages } from "../../../../../../../../constants";

const DialogBox = ({projectCoverImage, project}) => {
  const { theme } = useTheme();
  const {
    isModalProjectOpen,
    setIsModalProjectOpen,
    croppedImage,
    setCroppedImage,
    setIsDeleteProjectImageOpen,
    selectedProjectImage,
    setSelectedProjectImage,
    setOriginalProjectImage,
    originalProjectImage,
  } = useContext(ProjectImageContext);
  
  const [isEditDisabled, setIsEditDisabled] = useState(false);

  const closeModal = () => {
    setIsModalProjectOpen(false);
    setSelectedProjectImage(null);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // Process the selected file as needed
    if (selectedFile) {
      // Do something with the file
      const imageUrl = URL.createObjectURL(selectedFile);
      setSelectedProjectImage(imageUrl);
      setOriginalProjectImage(imageUrl)

    }

    setIsModalProjectOpen(false);
  };
  
  useEffect(() => {
    console.log('im the project please test me',project)
  },[project])

  const deleteImage = () => {};

  useEffect(() => {
    let flag = true;
    const defaultImages = Object.values(creatorDefaultImages);
    for (let i = 0; i < defaultImages.length; i++){
      console.log(defaultImages[i]);
      if (defaultImages[i] == croppedImage) {
        setIsEditDisabled(true);        
        flag = false;
        console.log(croppedImage,"test Cropped true")
      } 
    }
    if (flag === true) {
        setIsEditDisabled(false);
        console.log(croppedImage,"test Cropped false")
    }

  },[project, croppedImage])

  const handleEdit = () => {
    setSelectedProjectImage(originalProjectImage);
    setIsModalProjectOpen(false);
  };
  const handleDelete = () => {
    deleteImage();
    setIsDeleteProjectImageOpen(true);
    setIsModalProjectOpen(false);
    setSelectedProjectImage(null);
  };

  const style = {
    bgcolor: theme.palette.lightgrey.lightgrey500,
  };

  return (
    <Box
      className={styles.coverImage}
      sx={{
        display: isModalProjectOpen ? "flex" : "none",
        backgroundImage: `url(${selectedProjectImage})`, // Specify the path to your background image
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Modal
        open={isModalProjectOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              bgcolor: theme.palette.lightgrey.lightgrey500,
              width: {
                xs: "100%",
                md: "400px",
              },
            }}
            className={styles.dialogBox}
          >
            <Box sx={style} className={styles.container}>
              <Box sx={style} className={styles.text}>
                <Box sx={style} className={styles.subText}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: theme.palette.darkgrey.darkgrey800,
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    Upload skill cover photo
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: theme.palette.darkgrey.darkgrey500 }}
                  >
                    JPG, GIF, or PNG. Max size of 2MB
                  </Typography>
                </Box>
                <Close
                  onClick={closeModal}
                  sx={{
                    cursor: "pointer",
                    color: theme.palette.darkgrey.darkgrey300,
                  }}
                />
              </Box>
            </Box>
            <Divider />
            <Box sx={style} className={styles.upload}>
              <Box sx={style} className={styles.uploadPhoto}>
                <input
                  type="file"
                  accept="image/*"
                  id="imageInput"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <TextField
                  fullWidth
                  value={"Choose photo from gallery"}
                  onClick={() => document.getElementById("imageInput").click()}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <label htmlFor="imageInput" style={{ cursor: "pointer" }}>
                        <img src={uploadIcon} alt="upload" />
                      </label>
                    ),
                    style: {
                      backgroundColor: theme.palette.lightgrey.lightgrey00,
                      color: theme.palette.darkgrey.darkgrey600,
                      outline: "none",
                    },
                  }}
                />
              </Box>

              {/* <Box sx={style} className={styles.uploadPhoto}>
                <TextField
                  fullWidth
                  value="Open Camera"
                  onClick={openCamera}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <label style={{ cursor: "pointer" }}>
                        <img src={cameraIcon} alt="upload" />
                      </label>
                    ),
                    style: {
                      backgroundColor: theme.palette.lightgrey.lightgrey00,
                      color: theme.palette.darkgrey.darkgrey600,
                      outline: "none",
                    },
                  }}
                />
                <video ref={videoRef} style={{ display: "none" }} />
              </Box> */}

              <Box
                sx={style}
                style={{ display: projectCoverImage ? "flex" : "none" }}
                className={styles.uploadPhoto}
              >
                <TextField
                  disabled = {isEditDisabled? true: false}
                  fullWidth
                  value="Edit"
                  onClick={handleEdit}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <label style={{ cursor: "pointer" }}>
                        <img src={editIcon} alt="upload" />
                      </label>
                    ),
                    style: {
                      backgroundColor: theme.palette.lightgrey.lightgrey00,
                      color: theme.palette.darkgrey.darkgrey600,
                      outline: "none",
                    },
                  }}
                />
              </Box>
              <Box
                sx={style}
                style={{ display: projectCoverImage ? "flex" : "none" }}
                className={styles.uploadPhoto}
              >
                <TextField
                  fullWidth
                  value="Delete"
                  onClick={handleDelete}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <label style={{ cursor: "pointer" }}>
                        <img src={deleteIcon} alt="upload" />
                      </label>
                    ),
                    style: {
                      backgroundColor: theme.palette.lightgrey.lightgrey00,
                      color: theme.palette.danger.red500,
                      outline: "none",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DialogBox;
