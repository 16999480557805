import React, { useEffect, useContext } from 'react';
import confetti from 'canvas-confetti';

const ConfettiButton = ({showConfetti, setShowConfetti, containerRef}) => {

    useEffect(() => {
        if (showConfetti) {
            const canvas = document.createElement('canvas');
            canvas.width = 600;
            canvas.height = 600;
            canvas.style.position = 'absolute';
            canvas.style.top = '50%';
            canvas.style.left = '50%';
            canvas.style.transform = 'translate(-50%, -50%)';
            canvas.style.pointerEvents = 'none';
          
            const container = containerRef.current;
            container.appendChild(canvas);

            const myConfetti = confetti.create(canvas);
            myConfetti({
                particleCount: 200,
                spread: 200,
                startVelocity: 15,
                scalar: 0.9,
                ticks: 90
            }).then(() => {
                container.removeChild(canvas);
                setShowConfetti(false); // Reset showConfetti after animation
            });
        }
    }, [showConfetti, setShowConfetti, containerRef]);

    return null; // Confetti is managed outside of the return statement
};

export default ConfettiButton;
