import { createContext, useCallback, useEffect, useRef, useState } from "react";
import supabase from "../config/supabaseClient";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import { UserContext } from "./user-context";
import { server_link } from "../constants";

const ProjectFiltersContext = createContext(null);

const ProjectFiltersProvider = ({children}) => {
    const filtersOptions = [
        { name: "Performers", value: 'Performers', column: 'collaborator_category' },
        { name: "Street Artists", value: 'Street Artists', column: 'collaborator_category' },
        { name: "TV & Film Pros", value: "TV & Film Pros", column: 'collaborator_category' },
        { name: "Musicians", value: "Musicians", column: 'collaborator_category' },
        { name: "Fine Artists", value: "Fine Artists", column: 'collaborator_category' },
        { name: "Fashion Designers", value: "Fashion Designers", column: 'collaborator_category' },
        { name: "Space Designers", value: "Space Designers", column: 'collaborator_category' },
        { name: "Culinary Artists", value: "Culinary Artists", column: 'collaborator_category' },
        { name: "Digital Artists", value: "Digital Artists", column: 'collaborator_category' },
        { name: "Stylists", value: "Stylists", column: 'collaborator_category' },
        { name: "Marketers", value: "Marketers", column: 'collaborator_category' },
        { name: "Technologists", value: "Technologists", column: 'collaborator_category' },
        { name: "Writers", value: "Writers", column: 'collaborator_category' },
        { name: "Product Designers", value: "Product Designers", column: 'collaborator_category' },
        { name: "Business People", value: "Business People", column: 'collaborator_category' },
        { name: "Event Producers", value: "Event Producers", column: 'collaborator_category' },
        // { name: 'Remote', value: 'Remote', column: 'location_type' },
        // { name: 'Hybrid', value: 'Hybrid', column: 'location_type' },
        // { name: 'Local', value: 'Local', column: 'location_type' },
        // { name: 'Gen X', value: 'X', column: 'age_range' },
        // { name: 'Gen X+', value: 'X+', column: 'age_range' },
        // { name: 'Gen Y', value: 'Y', column: 'age_range' },
        // { name: 'Gen Y+', value: 'Y+', column: 'age_range' },
        // { name: 'Gen Z', value: 'Z', column: 'age_range' },
        // { name: 'Gen Z+', value: 'Z+', column: 'age_range' },
        // { name: 'All', value: 'All', column: 'experience_level' },
        // { name: 'Advanced', value: 'Advanced or higher', column: 'experience_level' },
        // { name: 'Pro', value: 'Pros only', column: 'experience_level' },
    ];

    const [selectedFilters, setSelectedFilters] = useState(filtersOptions);
    const [filters, setFilters] = useState([]);
    const [availableFilters, setAvailableFilters] = useState([]);
    const [projectIds, setProjectIds] = useState([]);
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const resultsRef = useRef(null);
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    
    const ITEMS_PER_PAGE = 10; // Number of items to load per page

    const { user } = useContext(UserContext);

    useEffect(() => {
        const handleDefaultfilters = async () => {
            const { data: userCreators, error } = await supabase.from('creator_types').select("*").eq("user_id", user?.id);
            if (!error) {
                console.log("test user creators: ", userCreators);
                const selected = filtersOptions.filter(option =>
                    userCreators.some(item => option.name.includes(item.type))
                );
                const notSelected = filtersOptions.filter(option =>
                    userCreators.every(item => !option.name.includes(item.type))
                );
                setFilters(selected);
                setAvailableFilters(notSelected);
            } else {
                console.log("test user creators: ", error);
            }
        }

        handleDefaultfilters();
    },[user])

    const handleSelectFilter = (filter) => {
        setFilters([...filters, filter]);
        setAvailableFilters(availableFilters.filter(f => f.value !== filter.value));
    };

    const handleDeselectFilter = (filter) => {
        setFilters(filters.filter(f => f.value !== filter.value));
        setAvailableFilters([...availableFilters, filter]);
    };

    const handleRemoveFilter = (filter) => {
        setSelectedFilters(selectedFilters.filter(f => f.value !== filter.value));
        setFilters(filters.filter(f => f.value !== filter.value));
        setAvailableFilters([...availableFilters, filter]);
    };

    const handleApplyFilters = () => {
        // setFilters(selectedFilters);
        // setPage(1);  // Reset page when filters are applied
        // setResults([]);  // Clear previous results
        // setHasMore(true);  // Reset hasMore for new filter results
    };

    // const handleResetFilters = () => {
    //     setAvailableFilters([...availableFilters, ...selectedFilters]);
    //     setSelectedFilters([]);
    //     setProjectIds([]);
    //     setResults([]);
    //     setPage(1);
    //     setHasMore(true);
    // };

    const handleResetFilters = async() => {
        const { data: userCreators, error } = await supabase.from('creator_types').select("*").eq("user_id", user?.id);
            if (!error) {
                console.log("test user creators: ", userCreators);
                const selected = filtersOptions.filter(option =>
                    userCreators.some(item => option.name.includes(item.type))
                );
                const notSelected = filtersOptions.filter(option =>
                    userCreators.every(item => !option.name.includes(item.type))
                );
                setFilters(selected);
                setAvailableFilters(notSelected);
                setPage(1);
                setHasMore(true);
            } else {
                console.log("test user creators: ", error);
            }
    };

    const fetchProjectIds = async () => {
        setLoading(true);
        // let query = supabase.from('projects').select('id')

        // if (filters.length > 0) {
        //     // Create OR filter string
        //     const orFilterString = filters.map(filter => `${filter.column}.eq.${filter.value}`).join(',');
        //     query = query.or(orFilterString);
        // }

        // const { data, error } = await query;

        // // if (!error) {
        // //     console.log('Fetched project IDs: ', data);
        // //     setLoading(false);
        // // } else {
        // //     console.error('Error fetching project IDs:', error);
        // //     return [];
        // // }

        // const distinctProjectIds = [...new Set(data.map(item => item.id))];
        // console.log('Fetched project IDs: ', distinctProjectIds);
        
        // setProjectIds(distinctProjectIds);

        let ids;
        await axios.post(`${server_link}api/projects-filtering`, filters)
            .then(response => {
                console.log('Response data from the end point :', response.data.projects);
                // setProjectIds(response.data.projects);
                // console.log('Response data from the end point : test : ', projectIds);
                ids= response.data.projects;
            })
            .catch(error => {
                console.error('Error:', error);
            });
        
        return ids;

    };

    const fetchProjectData = async ( ids,  page ) => {
        const offset = (page - 1) * ITEMS_PER_PAGE;
        const limit = ITEMS_PER_PAGE;
        const idsToFetch = await ids.slice(offset, offset + limit);
    
        console.log("test project ids: ", projectIds);
        console.log("test filters: ", filters);
        console.log("test ids: ", ids)
        // console.log("test ids with results: ", results)

        if (idsToFetch.length === 0 && filters.length > 0) {
            return [];
        }
    
        setLoading(true);
        
        let query = supabase
            .from('project_user_info')
            .select('*')
            .neq('status', "Under Review")
            .order('project_order', { ascending: true });
    
        if (idsToFetch.length > 0) {
            query = query.in('project_id', idsToFetch);
        }
    
        const { data, error } = await query;
        setLoading(false);
    
        if (error) {
            console.error('Error fetching project data:', error);
            // You might want to set an error state here to display an error message to the user
            return [];
        }
    
        return data;
    };
    

    const loadMoreResults = async (ids = []) => {
        console.log("hasMore: ", hasMore);
        
        if (ids.length !== 0) {
            const newResults = await fetchProjectData(ids, page);
            console.log("new results: ", newResults);
            if (newResults.length === 0) {
                console.log("test hasMore: from ids ", newResults);
                setHasMore(false);
            } else {
                setResults(prevResults => [...prevResults, ...newResults]);
                setPage(prevPage => prevPage + 1);
            }
        } else{

            console.log("test hasMore: projectIds ", projectIds);
            const newResults = await fetchProjectData( projectIds, page );
            console.log("new results: ", newResults);
            if (newResults.length === 0) {
                console.log("test hasMore: from projIds ", newResults);
                setHasMore(false);
            } else {
                setResults(prevResults => [...prevResults, ...newResults]);
                setPage(prevPage => prevPage + 1);
            }
        }
    
    };

    
    useEffect(() => {
        const fetchAndSetProjectIds = async () => {
            const ids = await fetchProjectIds();
            setProjectIds(ids);
            console.log('Response data from the end point : test return : ', ids);
            console.log('filters options: ', filters);
            setPage(1);
            setHasMore(true);
            setResults([])
            await loadMoreResults(ids); // Load the initial results based on the current filters and page
        };
    
        fetchAndSetProjectIds();
    }, [filters.length]);
    
    // useEffect(() => {
    //     const fetchAndSetProjectIds = async () => {
    //         setResults([]);
    //         setPage(1);
    //         setHasMore(true);
    //         loadMoreResults(); // Load the initial results based on the current filters and page
    //     };
    
    //     fetchAndSetProjectIds();
    // }, [filters]);
    
    const handleScroll = useCallback(() => {
        console.log("test hasMore: ", hasMore)
        if (pathname === "/project-join" && hasMore) {
            if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight) {
                loadMoreResults();
            }
        }
    }, [pathname, hasMore]);
    
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const contextValue = {
        availableFilters,
        selectedFilters,
        filters,
        handleSelectFilter,
        handleDeselectFilter,
        handleApplyFilters,
        handleRemoveFilter,
        handleResetFilters,
        resultsRef,
        results,
        loadMoreResults,
        loading,
    }

    return (
        <ProjectFiltersContext.Provider value={contextValue} >
            {children}
        </ProjectFiltersContext.Provider>
    )
}

export default ProjectFiltersContext;

export {ProjectFiltersProvider};