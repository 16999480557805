import { Box } from "@mui/material";
import styles from "./style.module.css";
import Card from "./card";
import { useContext } from "react";
import ProjectFiltersContext from "../../../../../context/project-filters-context";
import LoadingButton from "../../../../../components/loading/button";

const ListResult = ({ projects }) => {
  const { loading } = useContext(ProjectFiltersContext);
  
  return (
    <Box className={styles.result}>
      {projects.map((project) => (
        <Card project={project} />
      ))}
      {loading &&
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
          <LoadingButton />
        </Box>
      }
    </Box>
  );
};

export default ListResult;
