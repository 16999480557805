import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import supabase from "../config/supabaseClient";
import TagManager from "react-gtm-module";
import moment from "moment";
import { getAddressInfo } from "../helpers/getAddressInfo";
import { calculateUserGeneration } from "../helpers/calculateUserGeneration";

const insertUserSkills = async (creatorTypes, userId) => {
  for (let i = 0; i < creatorTypes.length; i++) {
    await supabase
      .from("creator_skills")
      .insert({ user_id: userId, category: creatorTypes[i].category });
  }
};
function extractUTMParameters(urlString) {
  const params = {};
  const urlParams = new URLSearchParams(urlString);

  // Extract utm_source, utm_campaign, and utm_content parameters
  params.utm_source = urlParams.get("utm_source");
  params.utm_campaign = urlParams.get("utm_campaign");
  params.utm_content = urlParams.get("utm_content");

  return params;
}
export const UserContext = createContext(null);
const UserContextProvider = (props) => {
  const { pathname, search } = useLocation();
  const [globalUser, setGlobalUser] = useState(null);
  const [shouldContinueSignUp, setShouldContinueSignUp] = useState(false);
  const [error, setError] = useState(null);
  const { user, isAuthenticated, isLoading: isAuthLoading } = useAuth0();

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const checkRedirectedUrl = () => {
    console.log(pathname, search, "inside check RedirectedUrl");
    if (
      search === "?tab=shoutouts&shoutouts-tab=received" &&
      pathname === "/profile"
    ) {
      localStorage.setItem("redirectedUrl", pathname + search);
      console.log("before navigating");
      navigate("/");
      return;
    }
    if (
      pathname.startsWith("/creators/") ||
      pathname.startsWith("/projects/") ||
      location.pathname.includes("/project-manage") ||
      pathname.includes("/email-preferences") ||
      (search === "?tab=shoutouts&shoutouts-tab=received" &&
        pathname === "/profile") ||
      pathname === "/discover/results"
    ) {
      console.log(
        pathname,
        "from pathname inside redirectedUrl Setting the value -----------------"
      );
      localStorage.setItem("redirectedUrl", pathname + search);
    }
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      if (globalUser?.signup_completed) setShouldContinueSignUp(false);
    }
  }, [user, globalUser, isAuthenticated]);

  const initiateUser = async () => {
    if (isAuthLoading) return;
    setIsLoading(true);
    // check wether the user completed signup process or not
    if (!user) return;
    if (!isAuthenticated) return;
    const { data, error } = await supabase
      .from("users")
      .select("*")
      .eq("email", user.email);

    if (error) {
      setIsLoading(false);
      setError(error);
      alert("failed to create the Account, Please Try Again");
      // initiateUser();
    }
    // this is the case where the user wants to sign up for the first time
    if (data.length === 0) {
      // create a record for the db
      const shouldRegisterFromReversed = localStorage.getItem(
        "shouldRegisterFromReversed"
      );
      console.log(user, user.email, "from user and user email");
      await supabase.from("users").insert({
        email: user.email,
        real_name: user.name,
        public_email: user.email,
        profile_image: user.picture,
        mobile: user.mobile,
        last_login: moment(),
      });

      const { data: userData, error } = await supabase
        .from("users")
        .select()
        .eq("email", user.email);
      console.log(user.email, "after inserting the user");
      console.log(
        userData,
        shouldRegisterFromReversed,
        "from userData and shouldRegisterFrom Reversed"
      );
      if (shouldRegisterFromReversed === "true" && userData) {
        console.log("from should register from reversed");
        const user = userData[0];
        const registeredData = localStorage.getItem("reversedRegistrationData");
        const parsedRegisteredData = JSON.parse(registeredData);

        const {
          collaboratorsTypes,
          birthdate,
          country,
          creatorTypes,
          postalCode,
          pronounce,
          phoneNumber,
          countryCode
        } = parsedRegisteredData;
        
        console.log(
          collaboratorsTypes,
          birthdate,
          country,
          creatorTypes,
          postalCode,
          pronounce,
          parsedRegisteredData.birthdate,
          parsedRegisteredData,
          phoneNumber,
          countryCode,
          "from parsed data"
        );

        const addressData = await getAddressInfo(postalCode, country);
        const city = addressData?.city || "";
        const county = addressData?.county || "";
        const state = addressData?.state || "";
        const { utm_source, utm_campaign, utm_content } = extractUTMParameters(
          localStorage.getItem("starter-queries")
        );
        console.log(
          birthdate,
          "from birth date",
          moment(birthdate, "MM-DD-YYYY").format("YYYY-MM-DD"),
          "from date"
        );
        const { error, data } = await supabase
          .from("users")
          .update({
            country,
            generation: calculateUserGeneration(birthdate),
            postal_code: postalCode,
            birth_date: moment(birthdate, "MM-DD-YYYY").format("YYYY-MM-DD"),
            signup_completed: true,
            pronouns: pronounce,
            last_record_change: new Date(),
            city,
            county,
            state,
            utm_info: localStorage.getItem("starter-queries"),
            utm_source,
            utm_campaign,
            utm_content,
            mobile: countryCode + phoneNumber,
          })
          .eq("id", user.id);
        localStorage.removeItem("starter-queries");
        creatorTypes.map(async (item) => {
          const record = {
            category: item.category,
            type: item.creator_type,
            user_id: user.id,
          };
          const { error: creatorsError } = await supabase
            .from("creator_types")
            .insert(record);
        });
        await insertUserSkills(creatorTypes, user.id);
        collaboratorsTypes.map(async (item) => {
          const record = {
            type: item.creator_type,
            category: item.category,
            user_id: user.id,
            display_name: item.collaborator_category,
          };
          const { error: collaboratorsError } = await supabase
            .from("creator_desired_collaborators")
            .insert(record);
        });
        const { error: creatorStatus } = await supabase
          .from("user_community_status")
          .insert({
            user_id: user.id,
            status: "Creator",
            date_acquired: moment().format("YYYY-MM-DD"),
          });
        const { data: updatedUser, error: updatedUserError } = await supabase
          .from("users")
          .select("*")
          .eq("email", user.email);
        const result = await ["X", "X+", "Y", "Y+", "Z", "Z+"].map(
          async (item) => {
            await supabase
              .from("collaborat_age_ranges")
              .insert({ user_id: user.id, age_category: item });
          }
        );
        TagManager.dataLayer({
          dataLayer: {
            event: "successful registration",
          },
        });
        setGlobalUser(updatedUser[0]);
        setIsLoading(false);
        setShouldContinueSignUp(false);
        navigate("/profile");
        return;
      }
      if (userData) setGlobalUser(userData[0]);
      setIsLoading(false);
      setShouldContinueSignUp(true);
      navigate("/profile");
      setIsLoading(false);

      return;
    }

    // user already tried to login in before but didin't complete the signup
    if (data[0].signup_completed === null) {
      setGlobalUser(data[0]);
      setIsLoading(false);
      setShouldContinueSignUp(true);
      await supabase
        .from("users")
        .update({ last_login: moment() })
        .eq("id", data[0].id);
      return;
    }
    // you are okay to set the global user
    setGlobalUser(data[0]);
    await supabase
      .from("users")
      .update({ last_login: moment() })
      .eq("id", data[0].id);
    setIsLoading(false);
  };

  useEffect(() => {
    if (globalUser && !shouldContinueSignUp) {
      if (globalUser.profile_image) {
      }
    }
  }, [globalUser]);

  useEffect(() => {
    if (
      shouldContinueSignUp &&
      isAuthenticated &&
      pathname === "/terms-of-service"
    )
      return;
    const projectRegExp = /\/projects\/\d+/;
    if (!isAuthenticated && projectRegExp.test(location.pathname))
      return checkRedirectedUrl();

    if (
      shouldContinueSignUp &&
      isAuthenticated &&
      location.pathname !== "/onboarding"
    ) {
      checkRedirectedUrl();
      navigate("/onboarding");
    }

    if (!shouldContinueSignUp && isAuthenticated && location.pathname === "/")
      navigate("/profile");
  }, [shouldContinueSignUp, isAuthenticated, location]);

  useEffect(() => {
    if (!isAuthLoading && !isAuthenticated) {
      const projectRegExp = /\/projects\/\d+/;
      if (projectRegExp.test(location.pathname)) return checkRedirectedUrl();
      if (
        location.pathname.includes("project-manage") ||
        location.pathname.includes("email-preferences") ||
        (location.search === "?tab=shoutouts&shoutouts-tab=received" &&
          location.pathname === "/profile") ||
        location.pathname === "discover/results"
      )
        checkRedirectedUrl();
      checkRedirectedUrl();
      if (location.pathname === "/signup") {
        return;
      }

      navigate("/" + location.search);
    }
  }, [isAuthLoading, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !isAuthLoading) initiateUser();
  }, [user, isAuthenticated, isAuthLoading]);

  const contextValue = {
    user: globalUser,
    isLoading,
    setShouldContinueSignUp,
    setUser: setGlobalUser,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
