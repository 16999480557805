import React, { useContext, useEffect, useState } from "react";

import Skill from "./skill";
import { SkillsBarContext } from "../../../../../../context/skills-bar-context";

const SkillsList = ({ userSkills }) => {
  // Initial state representing three skills
  const { activeSkill, setActiveSkill } = useContext(SkillsBarContext);
  const [skills, setSkills] = useState(userSkills);
  useEffect(() => {
    setSkills(userSkills);
  }, [userSkills]);

  return (
    <React.Fragment>
      {skills.map((skill, index) => (
        <Skill skill={skill} index={index} key={index} />
      ))}
    </React.Fragment>
  );
};

export default SkillsList;
