import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { RoleContext } from "../../../../../../../../../context/role-context";
import styles from "./style.module.css";
import supabase from "../../../../../../../../../config/supabaseClient";
import { useTheme } from "../../../../../../../../../context/theme-context";

const AddRoleModal = ({ isModalOpen, handleModal }) => {
  const { theme } = useTheme();
  const { roles, setRoles } = useContext(RoleContext);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSkill, setSelectedSkill] = useState("");
  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const [categories, setCategories] = useState([]);
  const [skills, setSkills] = useState([]);
  const [specialities, setSpecialities] = useState([]);

  const handleChangeCategory = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  const handleChangeSkill = (event, newValue) => {
    setSelectedSkill(newValue);
  };

  const handleChangeSpecialty = (event, newValue) => {
    setSelectedSpecialty(newValue);
  };

  // fetching categories and skills and speciality

  useEffect(() => {
    const fetchCategories = async () => {
      const { data, error } = await supabase.from("creator_types").select("*");

      const set = new Set(data.map((item) => item.category));
      setCategories(Array.from(set));
      //setSelectedCategory(data[0].category);
    };
    fetchCategories();
  }, []);
  useEffect(() => {
    const fetchSkills = async () => {
      const { data, error } = await supabase
        .from("skills")
        .select("*")
        .eq("category", selectedCategory);
      const set = new Set(data.map((item) => item.skill));
      setSkills(Array.from(set));
    };
    fetchSkills();
  }, [selectedCategory]);
  useEffect(() => {
    const fetchSpecialities = async () => {
      const { data, error } = await supabase
        .from("skills")
        .select("*")
        .eq("skill", selectedSkill);
      const set = new Set(data.map((item) => item.speciality));
      setSpecialities(Array.from(set));
    };
    fetchSpecialities();
  }, [selectedSkill]);

  // close add Modal
  const handleModalClose = () => {
    handleModal(false);
  };

  // function that add new role to roles state
  const updateRoles = () => {
    if (!selectedCategory) return;
    //new role that will be add
    const updatedRole = {
      num: roles[roles.length - 1].num + 1,
      selectedCategory: selectedCategory,
      selectedSkill: selectedSkill,
      selectedSpecialty: selectedSpecialty,
    };

    // update roles
    setRoles((prevItems) => [...prevItems, updatedRole]);

    // reinatialize states with empty string
    setSelectedCategory("");
    setSelectedSkill("");
    setSelectedSpecialty("");
  };

  // Add button thats invok updateRoles function and close modal
  const handleAddButtonClick = () => {
    handleModal(false);
    updateRoles();
  };

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Box
        className={styles.roleParent}
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey00,
          width: {
            xs: "320px",
          },
        }}
      >
        <Box className={styles.subParent}>
          <Box className={styles.content}>
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.darkgrey.darkgrey700,
                fontWeight: "500",
                fontSize: "18px",
              }}
            >
              Add New Role #{roles.length + 1}
            </Typography>
          </Box>
          <Box className={styles.inputField}>
            <Autocomplete
              // sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
              options={categories ? categories : []}
              getOptionLabel={(option) => option}
              value={selectedCategory}
              onChange={handleChangeCategory}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Category"
                  variant="outlined"
                />
              )}
            />
            <Autocomplete
              // sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
              options={skills ? skills : []}
              getOptionLabel={(option) => option}
              onChange={handleChangeSkill}
              value={selectedSkill}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Skill"
                  variant="outlined"
                />
              )}
            />
            <Autocomplete
              // sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
              options={specialities ? specialities : []}
              getOptionLabel={(option) => option}
              value={selectedSpecialty}
              onChange={handleChangeSpecialty}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Specialty"
                  variant="outlined"
                />
              )}
            />
            <Button
              onClick={handleAddButtonClick}
              variant="contained"
              fullWidth
            >
              Add
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddRoleModal;
