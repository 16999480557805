import { createContext, useContext, useEffect, useState } from "react";


export const ProjectDetailsContext = createContext();

const ProjectDetailsContextProvider = (props) => {
    const [isLogedIn, setIsLogedIn] = useState(false);
    const [status, setStatus] = useState('');
    
    const contextValue = {
        isLogedIn,
        setIsLogedIn,
        status,
        setStatus
    }
    
    return (
        <ProjectDetailsContext.Provider value={contextValue}>
            {props.children}
        </ProjectDetailsContext.Provider>
    );

}

export default ProjectDetailsContextProvider;