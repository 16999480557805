import React, { useRef, useState } from "react";
import styles from './style.module.css';  
import DialogBox from "./dialog-box";

const UploadProjectImage = ({ projectCoverImage }) => {
    return (
        <React.Fragment>
            <DialogBox projectCoverImage={projectCoverImage} />    
        </React.Fragment>
    );
}

export default UploadProjectImage;