import { Box, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { useTheme } from "../../../../../context/theme-context";

const HitMeUp = ({ setIsVisible }) => {
  const { theme } = useTheme();
  
  const handleClick = ()=>{
    setIsVisible(false);
  }
  return (
    <Button variant="contained"
      onClick={handleClick}
      className={styles.up}
      fullWidth
      sx={{
        bgcolor: theme.palette.primary.light,
        fontSize: '16px',
        height: '40px',
        textTransform: 'capitalize'
      }}
    >Hit me Up!</Button>
  );
};

export default HitMeUp;
