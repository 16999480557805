import { Typography } from "@mui/material"
import { useTheme } from "../../../../../../../../context/theme-context"
import { useContext } from "react";
import { UserContext } from "../../../../../../../../context/user-context";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingContext } from "../../../../../../../../context/loading-context";
import { useNavigate } from "react-router-dom";

const NotLogedIn = () => {
    const { theme, darkMode } = useTheme();
    const { user } = useContext(UserContext);
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    const { setIsLoading } = useContext(LoadingContext);

    const navigate = useNavigate();

    if (user) {
        return <></>
    }
    return (
        <Typography variant="body1"
            sx={{
                color: darkMode ? theme.palette.darkgrey.darkgreybody50
                    : theme.palette.darkgrey.darkgrey400,
                fontSize: '16px',
                fontWeight: '500',
            }}
        >
            <span
                onClick={async () => {
                    setIsLoading(true);
                    await loginWithRedirect({ connection: "google-oauth2" });
                    setIsLoading(false);
                }}
                style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}
            >Login </span>
            or
            <span
                onClick={() => {
                    navigate('/signup')
                }}
                style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}
            > create account </span> to view
        </Typography>
    )
};

export default NotLogedIn;