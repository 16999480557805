// // cropUtils.js
// export const getCroppedImg = (imageSrc, crop) => {
//   return new Promise((resolve, reject) => {
//     const canvas = document.createElement('canvas');
//     const ctx = canvas.getContext('2d');
//     if (!ctx) {
//       throw new Error("No 2d context");
//     }

//     const pixelRatio = window.devicePixelRatio;
//     const scaleX = imageSrc.naturalWidth / imageSrc.width;
//     const scaleY = imageSrc.naturalHeight / imageSrc.height;

//     canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
//     canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
    
//     ctx.scale(pixelRatio, pixelRatio);
//     ctx.imageSmoothingQuality = "high";
//     ctx.save();

//     const cropX = crop.x * scaleX;
//     const cropY = crop.y * scaleY;

//     ctx.translate(-cropX, -cropY);

//     ctx.drawImage(
//       imageSrc,
//       0,
//       0,
//       imageSrc.naturalWidth,
//       imageSrc.naturalHeight,
//       0,
//       0,
//       imageSrc.naturalWidth,
//       imageSrc.naturalHeight
//     );

//     // ctx.restore();
//     // Convert the cropped image to a data URL
//     const croppedImageUrl = canvas.toDataURL('image/png');

//     // Convert data URL to blob
//     fetch(croppedImageUrl)
//       .then((res) => res.blob())
//       .then((blob) => {
//         resolve(blob);
//       });
    
//   });
// };

// cropUtils.js
export const getCroppedImg = (imageSrc, crop) => {
  console.log("test image src: ", "naturalWidth:", imageSrc.naturalWidth, "naturalHeight",imageSrc.naturalHeight);
  console.log("test image src: ", "Width:", imageSrc.width, "Height",imageSrc.height);
  
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error("No 2d context");
    }

    const pixelRatio = window.devicePixelRatio;
    const scaleX = imageSrc.naturalWidth / imageSrc.width;
    const scaleY = imageSrc.naturalHeight / imageSrc.height;

    console.log("test scaleY naturalHeight: ",imageSrc.naturalHeight , "test scaleY height: ", imageSrc.height);
    console.log("test scaleX: ", scaleX , "test scaleY: ", scaleY);

    const cropWidth = crop.width * scaleX;
    const cropHeight = crop.height * scaleY;

    console.log("test image src: ", crop , "cropX: ", crop.x);
    console.log("test image src: ", crop , "cropY: ", crop.y);

    canvas.width = cropWidth * pixelRatio;
    canvas.height = cropHeight * pixelRatio;

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";
    ctx.save();

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    console.log("test scale: ", imageSrc,
      cropX, cropY, cropWidth, cropHeight,
      0, 0, cropWidth, cropHeight);

    // Ensure the correct part of the image is drawn
    ctx.drawImage(
      imageSrc,
      cropX, cropY, cropWidth, cropHeight,
      0, 0, cropWidth, cropHeight
    );

    ctx.restore();

    // Convert the cropped image to a data URL
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error("Canvas is empty"));
        return;
      }
      resolve(blob);
    }, 'image/png');
  });
};
