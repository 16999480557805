import { Box, Button } from "@mui/material";
import React, { useContext } from "react";
import styles from "./style.module.css";
import image from "../../../../assets/images/Color=Gredient, Size=Large.svg";
import InputBase from "@mui/material/InputBase";

import IconButton from "@mui/material/IconButton";

import SearchIcon from "@mui/icons-material/Search";
import { SearchContext } from "../../../../context/search-context";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../../context/theme-context";

const Logo = () => {
  const { theme, darkMode } = useTheme();
  const { searchKeyword, setSearchKeyWord, setSearchType } =
    useContext(SearchContext);
  const navigate = useNavigate();
  const handleSearchClick = (e) => {
    setSearchType("singleSearch");
    navigate("/search/results");
  };
  return (
    <Box className={styles.parent}>
      <Box className={styles.logo}>
        <img src={image} width="100%" height="100%" alert="lynx" />
      </Box>
      <Box className={styles.text}>
        <Box
          className={styles.searchForm}
          component="form"
          sx={{
            bgcolor: darkMode? '#3D3D3D' :theme.palette.lightgrey.lightgrey600,
          }}
        >
          <InputBase
            sx={{
              color: theme.palette.darkgrey.darkgrey600,
              flex: 1,
            }}
            value={searchKeyword}
            onChange={(e) => setSearchKeyWord(e.target.value)}
            placeholder="Name or Tagline Search"
          />
          <IconButton
            type="button"
            onClick={handleSearchClick}
            sx={{ color: theme.palette.darkgrey.darkgrey400, p: "10px" }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Logo;
