import React, { useRef, useState } from "react";
import styles from './style.module.css';  
import DialogBox from "./dialog-box";

const UploadSkillImage = ({ modal }) => {
    return (
        <React.Fragment>
            <DialogBox modal={modal} />    
        </React.Fragment>
    );
}

export default UploadSkillImage;