import { Box, Typography } from "@mui/material";
import styles from "./style.module.css";
import Header from "./components/header";
import { useTheme } from "../../../context/theme-context";
import Filters from "./components/filter";
import GridResult from "./components/grid";
import ListResult from "./components/list";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import FiltersSelect from "./components/filters-modal";
import supabase from "../../../config/supabaseClient";
import ProjectFiltersContext from "../../../context/project-filters-context";

const JoinAProject = () => {
  const { theme } = useTheme();
  const [tabOption, setTabOption] = useState("grid");
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const { results } = useContext(ProjectFiltersContext);

  const clickGridOption = () => {
    setTabOption("grid");
  };
  const clickListOption = () => {
    setTabOption("list");
  };

   useEffect(() => {
    console.log('test result length: ', results.length);
  },[results])

  return (
    <Box
      className={styles.joinAProject}
      sx={{ paddingBottom: '5rem', bgcolor: theme.palette.lightgrey.lightgrey500 }}
      // style={{ height: '80vh', overflowY: 'auto' }}
      // ref={resultsRef}
    >
      <Header
        clickGridOption={clickGridOption}
        clickListOption={clickListOption}
        tabOption={tabOption}
      />
      <Filters setIsModalOpen={setIsFiltersModalOpen} />
      <FiltersSelect
        isModalOpen={isFiltersModalOpen}
        setIsModalOpen={setIsFiltersModalOpen}
      />
      {tabOption === "grid" && <GridResult projects={results} />}
      {tabOption === "list" && <ListResult projects={results} />}
    </Box>
  );
};

export default JoinAProject;
