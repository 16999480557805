import { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./user-context";
import supabase from "../config/supabaseClient";

export const ProfileCompleteContext = createContext();

const ProfileCompleteContextProvider = ({ children }) => {
  const [imageAdded, setImageAdded] = useState(false);
  const [skillComplete, setSkillComplete] = useState(false);
  const [collaborator, setCollaborator] = useState(false);
  const [profileComplete, setProfileComplete] = useState(false);
  const { user, setUser } = useContext(UserContext);

  const checkProfileComplete = async () => {
    const { data, error } = await supabase
      .from("users")
      .select("*")
      .eq("id", user.id);
    setProfileComplete(data[0].is_profile_complete);
  };

  const handleProfileComplete = () => {
    if (imageAdded && skillComplete && collaborator) {
      setProfileComplete(true);
    } else {
      setProfileComplete(false);
    }

  };

  const updateProfileComplete = async () => {
    await supabase
      .from("users")
      .update({
        is_profile_complete: profileComplete,
      })
      .eq("id", user.id);

    const { data, error } = await supabase
      .from("users")
      .select()
      .eq("id", user.id);
    // add active campaign tag here!


    setUser(data[0]);
    // console.log(data[0], 'from profile complete');
  };

  useEffect(() => {
    if (user) {
      checkProfileComplete();
    }
  }, []);

  useEffect(() => {
    handleProfileComplete();
  }, [imageAdded, skillComplete, collaborator]);

  useEffect(() => {
    if (user) {
      updateProfileComplete();
    }
  }, [profileComplete]);

  const contextValue = {
    imageAdded,
    setImageAdded,
    skillComplete,
    setSkillComplete,
    collaborator,
    setCollaborator,
    profileComplete,
    setProfileComplete,
  };

  return (
    <ProfileCompleteContext.Provider value={contextValue}>
      {children}
    </ProfileCompleteContext.Provider>
  );
};

export default ProfileCompleteContextProvider;
