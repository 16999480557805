import moment from "moment";
import { createContext, useState } from "react";

export const AddSkillContext = createContext(null);
const AddSkillContextProvider = (props) => {
  const [category, setCategory] = useState("");
  const [skill, setSkill] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [isPrimary, setIsPrimary] = useState(false);
  const [description, setDescription] = useState("");
  const [portfolioLink, setPortfolioLink] = useState("");
  const [ageStarted, setAgeStarted] = useState();
  const [level, setLevel] = useState("");
  const [acceptMarket, setAcceptMarket] = useState(false);
  const contextValue = {
    category,
    setCategory,
    skill,
    setSkill,
    speciality,
    setSpeciality,
    isPrimary,
    setIsPrimary,
    description,
    setDescription,
    portfolioLink,
    setPortfolioLink,
    ageStarted,
    setAgeStarted,
    level,
    setLevel,
    acceptMarket,
    setAcceptMarket,
  };

  return (
    <AddSkillContext.Provider value={contextValue}>
      {props.children}
    </AddSkillContext.Provider>
  );
};
export default AddSkillContextProvider;
