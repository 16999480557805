import React, { useContext, useRef, useState } from "react";
import styles from "./style.module.css";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Input,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  AccountCircle,
  Close,
  FileUploadOutlined,
  Person,
  Person2,
  UploadFile,
} from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import uploadIcon from "../../../../../../../assets/images/upload-01.svg";
import cameraIcon from "../../../../../../../assets/images/camera-01.svg";
import editIcon from "../../../../../../../assets/images/edit-03.svg";
import deleteIcon from "../../../../../../../assets/images/trash-01.svg";
import { SkillImageContext } from "../../../../../../../context/skill-image-context";
import { SkillsBarContext } from "../../../../../../../context/skills-bar-context";
import { useTheme } from "../../../../../../../context/theme-context";

const DialogBox = ({ modal }) => {
  const { theme } = useTheme();
  const { isModalSkillOpen, setIsModalSkillOpen, skill_cover_image } = modal;
  const {
    selectedSkillImage,
    setSelectedSkillImage,
    setIsDeleteSkillImageOpen,
    setCroppedImage,
  } = useContext(SkillImageContext);
  const { activeSkill } = useContext(SkillsBarContext);

  const closeModal = () => {
    setIsModalSkillOpen(false);
    setSelectedSkillImage(null);
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // Process the selected file as needed
    if (selectedFile) {
      // Do something with the file
      setSelectedSkillImage(URL.createObjectURL(selectedFile));
    }

    setIsModalSkillOpen(false);
  };

  const videoRef = useRef(null);

  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      // Capture the initial frame
      capture();
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };
  const capture = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const video = videoRef.current;

    // Set canvas dimensions to match the video stream
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Draw the current video frame onto the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Convert the canvas content to a data URL
    const capturedImageUrl = canvas.toDataURL("image/png");

    // Set the data URL as the cropped image
    setCroppedImage(capturedImageUrl);
    setSelectedSkillImage(capturedImageUrl);
    setIsModalSkillOpen(false);
  };

  const deleteImage = () => {};

  const handleEdit = () => {
    setSelectedSkillImage(activeSkill?.original_cover_image);

    setIsModalSkillOpen(false);
  };
  const handleDelete = () => {
    deleteImage();
    setIsDeleteSkillImageOpen(true);
    setIsModalSkillOpen(false);
    setSelectedSkillImage(null);
  };

  const style = {
    bgcolor: theme.palette.lightgrey.lightgrey500,
  };

  return (
    <Box
      className={styles.coverImage}
      sx={{
        display: isModalSkillOpen ? "flex" : "none",
        backgroundImage: `url(${skill_cover_image})`, // Specify the path to your background image
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Modal
        open={isModalSkillOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              bgcolor: theme.palette.lightgrey.lightgrey500,
              width: {
                xs: "100%",
                md: "400px",
              },
            }}
            className={styles.dialogBox}
          >
            <Box sx={style} className={styles.container}>
              <Box sx={style} className={styles.text}>
                <Box sx={style} className={styles.subText}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: theme.palette.darkgrey.darkgrey800,
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    Upload skill cover photo
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: theme.palette.darkgrey.darkgrey500 }}
                  >
                    JPG, GIF, or PNG. Max size of 2MB
                  </Typography>
                </Box>
                <Close
                  onClick={closeModal}
                  sx={{
                    cursor: "pointer",
                    color: theme.palette.darkgrey.darkgrey300,
                  }}
                />
              </Box>
            </Box>
            <Divider />
            <Box sx={style} className={styles.upload}>
              <Box sx={style} className={styles.uploadPhoto}>
                <input
                  type="file"
                  accept="image/*"
                  id="imageInput"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <TextField
                  fullWidth
                  value={"Choose photo from gallery"}
                  onClick={() => document.getElementById("imageInput").click()}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <label htmlFor="imageInput" style={{ cursor: "pointer" }}>
                        <img src={uploadIcon} alt="upload" />
                      </label>
                    ),
                    style: {
                      backgroundColor: theme.palette.lightgrey.lightgrey00,
                      color: theme.palette.darkgrey.darkgrey600,
                      outline: "none",
                    },
                  }}
                />
              </Box>

              {/* <Box sx={style} className={styles.uploadPhoto}>
                <TextField
                  fullWidth
                  value="Open Camera"
                  onClick={openCamera}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <label style={{ cursor: "pointer" }}>
                        <img src={cameraIcon} alt="upload" />
                      </label>
                    ),
                    style: {
                      backgroundColor: theme.palette.lightgrey.lightgrey00,
                      color: theme.palette.darkgrey.darkgrey600,
                      outline: "none",
                    },
                  }}
                />
                <video ref={videoRef} style={{ display: "none" }} />
              </Box> */}

              <Box
                sx={style}
                style={{ display: skill_cover_image ? "flex" : "none" }}
                className={styles.uploadPhoto}
              >
                <TextField
                  fullWidth
                  value="Edit"
                  onClick={handleEdit}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <label style={{ cursor: "pointer" }}>
                        <img src={editIcon} alt="upload" />
                      </label>
                    ),
                    style: {
                      backgroundColor: theme.palette.lightgrey.lightgrey00,
                      color: theme.palette.darkgrey.darkgrey600,
                      outline: "none",
                    },
                  }}
                />
              </Box>
              <Box
                sx={style}
                style={{ display: skill_cover_image ? "flex" : "none" }}
                className={styles.uploadPhoto}
              >
                <TextField
                  fullWidth
                  value="Delete"
                  onClick={handleDelete}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <label style={{ cursor: "pointer" }}>
                        <img src={deleteIcon} alt="upload" />
                      </label>
                    ),
                    style: {
                      backgroundColor: theme.palette.lightgrey.lightgrey00,
                      color: theme.palette.danger.red500,
                      outline: "none",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DialogBox;
