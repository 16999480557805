import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import styles from "./style.module.css";
import Info from "../../../../../../../components/info";

import { UserContext } from "../../../../../../../context/user-context";
import supabase from "../../../../../../../config/supabaseClient";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { EditSkillContext } from "../../../../../../../context/edit-skill-context";
import { SkillsBarContext } from "../../../../../../../context/skills-bar-context";
import { LoadingContext } from "../../../../../../../context/loading-context";
import LoadingButton from "../../../../../../../components/loading/button";
import { useTheme } from "../../../../../../../context/theme-context";
import { useInfoBoxs } from "../../../../../../../context/info-boxs-context";
import checkProfileComplete from "../../../../../../../helpers/checkProfileComplete";
import checkSkillComplete from "../../../../../../../helpers/checkSkillComplete";
import { creatorDefaultImages } from "../../../../../../../constants";
import { checkPrimarySkill } from "../../../../../../../helpers/checkPrimarySkill";
import { SettingsContext } from "../../../../../../../context/settings-context";
import generateUserFeed from "../../../../../../../helpers/generateUserFeed";
import { DiscoverContext } from "../../../../../../../context/discover-context";

// Function to check if a value exists in an object
function doesValueExistInObject(obj, value) {
  return Object.values(obj).includes(value);
}

const EditDescription = () => {
  const { infoBoxes } = useInfoBoxs();
  const { theme } = useTheme();
  const {
    category,
    skill,
    speciality,
    isPrimary,
    description,
    portfolioLink,
    ageStarted,
    level,
    acceptMarket,
    setDescription,
    setPortfolioLink,
    setAcceptMarket,
    setLevel,
    setAgeStarted,
    clearEditState,
  } = useContext(EditSkillContext);
  const { activeSkill, setActiveSkill } = useContext(SkillsBarContext);
  const navigate = useNavigate();
  const { id: skillId } = useParams();
  const { user, setUser } = useContext(UserContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { recalculateScore } = useContext(SettingsContext);
  const { setPage } = useContext(DiscoverContext);

  const editSkill = async () => {
    setIsLoading(true);
    if (isPrimary) {
      await supabase
        .from("creator_skills")
        .update({ is_primary: false })
        .eq("user_id", user.id);
    }

    const updatedAgeStarted = ageStarted == 0 ? 11 : ageStarted;
    // console.log(updatedAgeStarted, "check ageStarted skill");
    const started_training_date = updatedAgeStarted 
      ? moment(user.birth_date).add(+updatedAgeStarted, "years").format("YYYY-MM-DD")
      : null;

    let original = activeSkill.original_cover_image;
    let cropp = activeSkill.skill_cover_image;
    
    const exists = doesValueExistInObject(creatorDefaultImages, original);

    console.log("test exists: ", exists);
    if (exists || original === null) {
      const targetCreatorType = category;
      const targetImage = creatorDefaultImages[targetCreatorType];
      original = targetImage;
      cropp = targetImage;
    }  
    
    const isSkillComplete = checkSkillComplete(skill, description, level, started_training_date);
    const { data, error } = await supabase
      .from("creator_skills")
      .update({
        name: skill,
        level,
        started_training_age: ageStarted,
        started_training_date,
        category,
        speciality,
        link: portfolioLink,
        description,
        is_primary: isPrimary,
        accept_only_paid_project: acceptMarket,
        is_completed: isSkillComplete,
        skill_cover_image: cropp,
        original_cover_image: original,
      })
      .eq("id", skillId);

    await checkPrimarySkill(user);

    const { data: updatedSkill, error: updatedSkillError } = await supabase
      .from("creator_skills")
      .select()
      .eq("id", activeSkill?.id);

    setActiveSkill(updatedSkill[0]);
    console.log(updatedSkill[0], "check updated skill");
      
    if (error) {
      console.log(error, "from error");
    }
    if (!error) {
      clearEditState();
      navigate("/profile");
    }

    setIsLoading(false);
    
    const profileStatus = await checkProfileComplete(user, setUser);
    if (profileStatus) {
      const feedResponse = await generateUserFeed(user);
      console.log(feedResponse, 'test profile status')
      if (feedResponse == 'User feed generated') {
        setPage(1);
        console.log('test page change');

      }
      
    }
    recalculateScore();
  };
  useEffect(() => {
    if (!portfolioLink?.startsWith("http")) {
      setPortfolioLink("https://");
    }
  }, [portfolioLink]);

  const handleCancel = () => {
    navigate("/profile");
  };

  return (
    <Box className={styles.editDescription}>
      <Box
        className={styles.communityStatus}
        sx={{
          width: {
            // md: "50%",
          },
          alignSelf: {
            xs: "stretch",
            // md: "auto",
          },
        }}
      >
        <Box className={styles.description}>
          <Box className={styles.category}>
            <Typography
              variant="body1"
              color={theme.palette.darkgrey.darkgrey600}
              fontWeight={500}
            >
              Tell everyone about your experience:
            </Typography>
            <TextField
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              multiline
              rows={3}
              inputProps={{ maxLength: 500 }}
              fullWidth
              sx={{
                lineHeight: "24px",
              }}
            />
            <Typography
              variant="caption"
              color={description?.length > 500 ? "error" : "textSecondary"}
              sx={{
                width: "100%",
                textAlign: "right",
              }}
            >
              {description?.length}/500 characters
            </Typography>
          </Box>
          <TextField
            label="Portfolio Link"
            value={portfolioLink}
            onChange={(e) => setPortfolioLink(e.target.value)}
            type="url"
            fullWidth
          />
          <Box className={styles.category}>
            <Typography
              variant="body1"
              color={theme.palette.darkgrey.darkgrey600}
              fontWeight={500}
            >
              At what age did you start training in this skill?
            </Typography>
            <TextField
              value={ageStarted}
              type="number"
              onChange={(e) => setAgeStarted(e.target.value)}
              sx={{
                width: "30%",
              }}
            />
          </Box>
          <Box className={styles.category}>
            <Typography
              variant="body1"
              color={theme.palette.darkgrey.darkgrey600}
              fontWeight={500}
            >
              How would you assess your skill level?
            </Typography>
            <RadioGroup
              row
              sx={{
                color: theme.palette.darkgrey.darkgrey500,
                "& .css-ahj2mt-MuiTypography-root": {
                  fontSize: "14px",
                },
              }}
              value={level}
              onChange={(e) => setLevel(e.target.value)}
            >
              <FormControlLabel
                value="Beginner"
                control={<Radio size="meduim" />}
                label="Beginner"
                fontSize="14px"
              />
              <FormControlLabel
                value="Intermediate"
                control={<Radio size="meduim" />}
                label="Intermediate"
              />
              <FormControlLabel
                value="Advanced"
                control={<Radio size="meduim" />}
                label="Advanced"
              />
              <FormControlLabel
                value="Master"
                control={<Radio size="meduim" />}
                label="Master"
              />
            </RadioGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              alignSelf: "stretch",
            }}
          >
            <Checkbox
              sx={{ m: "0px", p: "0px" }}
              checked={acceptMarket}
              onChange={(e) => setAcceptMarket(e.target.checked)}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                alignSelf: "stretch",
              }}
            >
              <Typography
                variant="body2"
                color={theme.palette.darkgrey.darkgrey500}
              >
                I am a professional and I only accept paid market-rate projects.
              </Typography>
              {infoBoxes.map((item) => {
                if (item.name == "pro") {    
                  return <Info
                    heading={item.title}
                    content={item.content}
                  />
                }
              })}
            </Box>
          </Box>
        </Box>
        <Box className={styles.buttons}>
          <Button
            onClick={editSkill}
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: isLoading && theme.palette.primary.dark,
            }}
          >
            {isLoading ? <LoadingButton /> : "Save"}
          </Button>
          <Button
            onClick={handleCancel}
            variant="text"
            fullWidth
            sx={{
              color: theme.palette.darkgrey.darkgrey400,
              textTransform: "capitalize",
              fontSize: '16px',
              fontWeight: '500',
              p: '0px'
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EditDescription;
