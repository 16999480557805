import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import BasicLogo from "./logo";
import styles from "./style.module.css";
import ProjectForm from "./project-form";
import { useTheme } from "../../../context/theme-context";
import { useParams } from "react-router-dom";
import AppModal from "../Manage-project/components/tabs/hosted/app-modal";

const EditProject = () => {
  const { theme } = useTheme();
  const { id } = useParams();

  useEffect(() => {
    console.log(id, 'id from edit project')
  },[])
  

  return (
    <Box
      className={styles.boardSubmission}
      style={{ paddingBottom: "5rem" }}
      sx={{ bgcolor: theme.palette.lightgrey.lightgrey500 }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
            // md: "50%",
          },
        }}
      >
        <BasicLogo />
        <ProjectForm />
      </Box>
    </Box>
  );
};

export default EditProject;
