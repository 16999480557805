import { Box, Button } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { UserContext } from "../../../context/user-context";
import Status from "./components/status";
import UserCollaborators from "./components/user-collaborators";
import UserInfo from "./components/user-info";
import { Link } from "react-router-dom";
import UserLocation from "./components/user-location";

import moment from "moment";
import LoadingButton from "../../../components/loading/button";
import { defaultProfilePrefix } from "../../../constants";

const AboutMe = () => {
  const { user } = useContext(UserContext);
  if (!user) {
    return (
      <>
        <LoadingButton />
      </>
    );
  }

  const userInfo = [
    {
      label: "My Real Name",
      value: user.real_name,
    },
    {
      label: "My Nickname",
      value: user.nick_name,
    },
    {
      label: "Tagline",
      value: user.tagline,
    },
    {
      label: "Profile Link",
      value: defaultProfilePrefix + user.id,
    },
    {
      label: "Public Email Address",
      value: user.public_email,
    },
    {
      label: "Registered Email Address",
      value: user.email,
    },
    {
      label: "Mobile Number",
      value: user.mobile,
    },
    {
      label: "Birth Date",
      value: moment(user.birth_date).format("MM-DD-YYYY"),
    },
    {
      label: "Pronouns",
      value: user.pronouns,
    },
  ];
  const userLocaton = [
    {
      label: "Country",
      value: user.country,
    },
    {
      label: "Postal Code",
      value: user.postal_code,
    },
    {
      label: "City, State, County",
      value: user?.city + ", " + user?.state + ", " + user?.county,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        padding: "16px 24px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "16px",
        alignSelf: "stretch",
      }}
    >
      <Status />
      <UserInfo data={userInfo} generation={user.generation} />
      <UserLocation data={userLocaton} />
      <UserCollaborators />
      <Link
        to="/about-edit"
        underline="none"
        color="#fff"
        style={{ width: "100%" }}
      >
        <Button
          variant="contained"
          sx={{
            width: {
              xs: "100%",
            },
          }}
        >
          Edit{" "}
        </Button>
      </Link>
    </Box>
  );
};

export default AboutMe;
