import { Box } from "@mui/material";
import styles from "./style.module.css";

import SkillsList from "./skills-list";
import { useContext, useEffect, useState } from "react";
import { ProfileCompleteContext } from "../../../../../context/profile-complete-context";

const SkillsBar = ({ userSkills }) => {
  const { skillComplete, setSkillComplete } = useContext(
    ProfileCompleteContext
  );
  const [isSkillComplete, setIsSkillComplete] = useState(false);
  const handleSkillsChange = () => {
    userSkills.map((skill) => {
      if (
        skill.name &&
        skill.description &&
        skill.level &&
        skill.started_training_age
      ) {
        setIsSkillComplete(true);
        console.log(skill.started_training_age, 'started_training_age')
      }
    });

    if (isSkillComplete && !skillComplete) {
      setSkillComplete(true);
    }
    if (!isSkillComplete && skillComplete) {
      setSkillComplete(false);
    }
  };

  useEffect(() => {
    handleSkillsChange();
    console.log(userSkills, 'userSkills');
  }, [userSkills]);
  return (
    <Box className={styles.skills}>
      <SkillsList userSkills={userSkills} />
    </Box>
  );
};

export default SkillsBar;
