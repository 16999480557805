import { createContext, useState } from "react";

export const SearchContext = createContext(null);
const SearchContextProvider = (props) => {
  const [searchKeyword, setSearchKeyWord] = useState("");
  const [searchType, setSearchType] = useState("singleSearch");
  const [category, setCategory] = useState("");
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedEditorChoice, setSelectedEditorChoice] = useState(false);
  const [selectedProsOnly, setSelectedProsOnly] = useState(false);
  const [selectedAgeRanges, setSelectedAgeRanges] = useState([]);
  const contextValue = {
    selectedSkill,
    selectedAgeRanges,
    setSelectedAgeRanges,
    setSelectedSkill,
    selectedSpecialty,
    setSelectedSpecialty,
    selectedCountry,
    setSelectedCountry,
    selectedState,
    setSelectedState,
    selectedEditorChoice,
    setSelectedEditorChoice,
    selectedProsOnly,
    setSelectedProsOnly,
    searchKeyword,
    setSearchKeyWord,
    searchType,
    setSearchType,
    category,
    setCategory,
  };

  return (
    <SearchContext.Provider value={contextValue}>
      {props.children}
    </SearchContext.Provider>
  );
};
export default SearchContextProvider;
