import { createContext, useContext, useEffect, useState } from "react";
import supabase from "../config/supabaseClient";


const InfoBoxsContext = createContext();

const InfoBoxsContextProvider = ({children}) => {
    const [infoBoxes, setInfoBoxes] = useState([]);   
    
    const fetchInfoBoxes = async () => {
        const { data, error } = await supabase
            .from("info_boxes")
            .select("*");
    
        setInfoBoxes(data);
    }

    useEffect(() => {
        fetchInfoBoxes();
    }, [])

    return (
        <InfoBoxsContext.Provider value={{ infoBoxes }}>
            {children}
        </InfoBoxsContext.Provider>
    );
}

const useInfoBoxs = () => {
    return useContext(InfoBoxsContext);
}

export { InfoBoxsContextProvider, useInfoBoxs };