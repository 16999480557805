import moment from "moment";
import { createContext, useState } from "react";

export const SkillImageContext = createContext(null);
const SkillImageContextProvider = (props) => {
  const [selectedSkillImage, setSelectedSkillImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isModalSkillOpen, setIsModalSkillOpen] = useState(false);
  const [isDeleteSkillImageOpen, setIsDeleteSkillImageOpen] = useState(false);

  const contextValue = {
    selectedSkillImage,
    setSelectedSkillImage,
    croppedImage,
    setCroppedImage,
    isModalSkillOpen,
    setIsModalSkillOpen,
    isDeleteSkillImageOpen,
    setIsDeleteSkillImageOpen
  };

  return (
    <SkillImageContext.Provider value={contextValue}>
      {props.children}
    </SkillImageContext.Provider>
  );
};
export default SkillImageContextProvider;
