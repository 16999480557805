import { Box, Typography } from '@mui/material';
import styles from './style.module.css';
import { useTheme } from '../../../../../context/theme-context';
import tabImg1 from '../../../../../assets/images/project-join-grid.svg';
import tabImg2 from '../../../../../assets/images/project-join-list.svg';
import tabImg1Dark from '../../../../../assets/images/project-join-grid-dark.svg';
import tabImg2Dark from '../../../../../assets/images/project-join-list-dark.svg';
import tabImg1Active from '../../../../../assets/images/project-join-grid-active.svg';
import tabImg2Active from '../../../../../assets/images/project-join-list-active.svg';

const Header = (props) => {
    const { theme, darkMode } = useTheme();
    const { clickGridOption, clickListOption, tabOption } = props;

    return (
        <Box className = {styles.container}>
            <Typography variant='body1'
                sx={{
                    fontSize: '20px',
                    fontWeight: '500',
                    color: theme.palette.darkgrey.darkgrey700,
                }}
            >Available Projects</Typography>
            <Box className={styles.tabs}>
                <Box className={styles.tabOption}
                    onClick={clickGridOption}
                    sx={{
                        display: 'flex',
                        border: `1px solid`,
                        borderColor: tabOption === 'grid' ? theme.palette.primary.main :
                            darkMode ? "#7B7A7A" : theme.palette.darkgrey.darkgrey500,
                        background: tabOption === 'grid' ? theme.palette.primary.main : 'initial',
                    }}
                >
                    <img src={tabOption === 'grid' ? tabImg1Active : darkMode? tabImg1Dark: tabImg1} alt='tab option' />
                </Box>
                <Box className={styles.tabOption}
                    onClick={clickListOption}
                    sx={{
                        display: 'flex',
                        border: '1px solid',
                        borderColor: tabOption === 'list' ? theme.palette.primary.main :
                            darkMode ? "#7B7A7A" : theme.palette.darkgrey.darkgrey500,
                        background: tabOption === 'list' ? theme.palette.primary.main : 'initial',
                    }}
                >
                    <img src={tabOption === 'list' ? tabImg2Active: darkMode? tabImg2Dark: tabImg2} alt='tab option' />
                </Box>
            </Box>
        </Box>  
    );
}

export default Header;