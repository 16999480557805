import axios from "axios";
import { server_link } from "../constants";


export default async function generateUserFeed(user){
    let myReturnedRes;
    await axios.post(`${server_link}api/generate-user-feed`,
        {
            userId: user?.id
        }
    ).then(function (response) {
        console.log('my response test', response);
        myReturnedRes = response;
    }).catch(function (error) {
        console.log(error);
    }).finally(function () {
        // always executed
    });

    return myReturnedRes?.data?.message;
}