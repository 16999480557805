import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import logo from "../../../assets/images/Color=Gredient, Size=Large.svg";
import image from "../../../assets/images/check-circle-broken.svg";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../../components/loading/default";
import { UserContext } from "../../../context/user-context";
import { useTheme } from "../../../context/theme-context";

const Thanks = ({ handleDataComplete, isLoading }) => {
  const { theme } = useTheme();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  if (isLoading) {
    return <Loading />;
  }
  if (user?.signup_completed) {
    navigate("/profile");
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        //justifyContent: "center",
        padding: "0px 24px",
        backgroundColor: theme.palette.lightgrey.lightgrey600,
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //justifyContent: "center",
          padding: "40px 0px",
        }}
      >
        <img src={logo} width="234px" alt="LYNX Logo" />
      </Box>
      <Box
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey00,
          p: "40px 24px",
          borderRadius: "12px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "48px",
              alignItems: "flex-start",
              gap: "8px",
              borderRadius: "100px",
              background:
                "linear-gradient(160deg, #0F5FF7 9.58%, #9373FF 51.07%, #E81CFF 93.44%)",
              color: theme.palette.lightgrey.lightgrey00,
            }}
          >
            <img src={image} alt="image" />
            {/* <TaskAlt style={{ fontSize: "80px" }} /> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              alignSelf: "stretch",
            }}
          >
            <Typography
              variant="h5"
              color={theme.palette.darkgrey.darkgrey700}
              sx={{
                textAlign: "center",
                fontWeight: "700",
                fontSize: "24px",
              }}
            >
              One more step!
            </Typography>
            <Typography
              variant="body1"
              color={theme.palette.darkgrey.darkgrey400}
              sx={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Please review and accept our
              <a
                href="https://uploads-ssl.webflow.com/651750892fc953e528ca7b72/65a28be0d84d81bc4859e400_LYNX%20Terms%20%26%20Conditions.pdf"
                target="_blank"
                style={{
                  color: theme.palette.secondary.dark,
                  textDecoration: "none",
                }}
              >
                {" "}
                Terms of Service
              </a>
            </Typography>
          </Box>

          <Button
            variant="contained"
            sx={{
              width: "269px",
            }}
            onClick={handleDataComplete}
          >
            {isLoading ? "Loading..." : "Accept & Submit"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Thanks;
