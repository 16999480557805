import { Button, Chip } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../context/theme-context";

const CollaboratorSkill = ({ type }) => {
  const { theme } = useTheme();

  return (
    <Chip
      label={type}
    />
  );
};

export default CollaboratorSkill;
