import { Box, Button, Chip, Divider, Typography } from '@mui/material';
import styles from './style.module.css';
import { useTheme } from '../../../../../../../context/theme-context';
import pencilImage from '../../../../../../../assets/images/pencil-line.svg';
import trash from '../../../../../../../assets/images/trash-02.svg';
import trashDark from '../../../../../../../assets/images/trash-02-dark.svg';
import userImage from '../../../../../../../assets/images/users-02.svg';
import { RoomOutlined } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { ManageProjectsContext } from '../../../../../../../context/manage-projects-context';
import DeleteConfirmation from './delete-confirmation';
import { useNavigate } from 'react-router-dom';
import { sendProjectCanceledByUserEmail } from '../../../../../../../helpers/emails';

const Card = ({key, project }) => {
    const { theme, darkMode } = useTheme();
    const { applications, setApplications, handleRemoveApplication } = useContext(ManageProjectsContext);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        console.log('project: ', project)
    },[])

    const handleRemove = async(project) => {
        // handle Remove here
        const updatedApplications = applications.filter(
            app => app.project_id !== project.project_id
        );
        setApplications(updatedApplications);

        handleRemoveApplication(project);
        // active campaign
        await sendProjectCanceledByUserEmail(project.user_id, project.project_id);
    }

    useEffect(() => {
        // console.log(project, 'test project')
    }, [])
    return (
        <>
            <DeleteConfirmation
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
                onDelete={handleRemove}
                project={project}
            />
            <Box className={styles.card}
                key={key}
                sx={{
                    bgcolor: darkMode? "#333": theme.palette.lightgrey.lightgrey00,
                }}
            >
                <Box className={styles.pic}
                    onClick={()=> navigate(`/projects/${project.project_id}`)}
                    sx={{
                        cursor: 'pointer',
                        backgroundImage: `url(${project.project_cover_image})`,
                    }}
                ></Box>
                <Box className={styles.content}>
                    <Box className={styles.frame}>
                        <Typography
                            sx={{
                                display: 'flex',
                                alignSelf: 'stretch',
                                color: darkMode ? theme.palette.darkgrey.darkgreybody50
                                    : theme.palette.darkgrey.darkgrey600,
                                fontSize: '16px',
                                fontWeight: '700',
                            }}
                        >{project.title}</Typography>
                        <Box className={styles.frame2}>
                            <Chip label={project.category}
                                sx={{
                                    height: 'auto',
                                    padding: '3px 12px',
                                    bgcolor: project.category === "Brand" && "#EC7508" ||
                                        project.category === "Cause" && "#05B240" ||
                                        project.category === "Creator" && "#09B999" ||
                                        project.category === "Media" && "#B90988" ,
                                    color: '#FFF',
                                    textTransform: 'capitalize',
                                    fontSize: '12px',
                                }}
                            />
                            <Chip label={project.status}
                                sx={{
                                    fontSize: '12px',
                                    height: 'auto',
                                    padding: '3px 12px',
                                    bgcolor: theme.palette.success.dark,
                                    color: '#FFF',
                                    textTransform: 'capitalize',

                                }}
                            />
                        </Box>
                        
                    </Box> 
                    <Box className={styles.action}>
                        <Box className={styles.locations}>
                            <RoomOutlined
                                sx={{
                                    width: '16px',
                                    height: '16px',
                                    color: theme.palette.darkgrey.darkgrey500,
                                }}
                            />
                            <Typography
                                sx={{
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: '1',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    color: theme.palette.darkgrey.darkgrey500,
                                    fontSize: '12px',
                                    fontWeight: '500',
                                }}
                            >
                                {project.location_name === ""? "Remote": project.location_name}
                            </Typography>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box className={styles.remove}
                            onClick = {()=> setIsDeleteModalOpen(true)}
                        >
                            <img src={darkMode? trashDark: trash} alt='remove' />
                            <Typography variant="body1"
                                color={theme.palette.darkgrey.darkgrey400}
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500'
                                }}
                            >
                                Remove
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default Card;