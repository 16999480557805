import { Box, Button, Chip, Modal } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import { Clear, ClearRounded, Close } from "@mui/icons-material";
// import { SearchContext } from "../../../../context/search-context";
import { useTheme } from "../../../../../context/theme-context";
import Divider from '@mui/material/Divider';
import ProjectFiltersContext from "../../../../../context/project-filters-context";

const FiltersSelect = ({isModalOpen, setIsModalOpen}) => {
  const {
    handleResetFilters,
    handleApplyFilters,
    selectedFilters,
    availableFilters,
    handleDeselectFilter,
    filters,
    handleSelectFilter } = useContext(ProjectFiltersContext);
  const { theme, darkMode } = useTheme();

  const handleDelete = (filter) => {
    handleDeselectFilter(filter)
  };
  const handleClear = () => {
    // clear all filters
  };
  const handleClick = () => {};
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      sx={{
        display: 'flex',
        alignfilters: 'center',
        justifyContent: 'center',
      }}
    >
      <Box className={styles.filtersContainer}
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey00
        }}
      >
        <Box className={styles.parent}>
          <Box className={styles.filters}>
            {filters.map((filter, index) => (
              index >= 16 &&
              <Chip
                key={filter.value}
                label={filter.name}
                onClick={handleClick}
                onDelete={()=> handleDelete(filter)}
                deleteIcon={<ClearRounded />}
                sx={{
                  gap: '8px',
                  padding: '6px 8px 6px 12px',
                }}
              />
            ))}
            {availableFilters.map((filter, index) => (
              <Chip
                key={filter.value}
                onClick={()=> handleSelectFilter(filter)}
                label={filter.name}
                sx={{
                  gap: '8px',
                  padding: '6px 12px',
                }}
              />
            ))}
            <Button
              variant="text"
              onClick={handleResetFilters}
              sx={{
                textTransform: "capitalize",
                fontSize: "14px",
                p: '0',
                color: darkMode && theme.palette.primary.purpel100,
              }}
            >
              reset filters
            </Button>
          </Box>    
        </Box>
        <Box    
          className={styles.button}
        >
          <Button
            variant="contained"
            onClick={() => { handleApplyFilters(); handleCloseModal()}}
            sx={{
              textTransform: "capitalize",
              flex: "1",
              p: '10px 1.5rem',
              borderRadius: '4px'
            }}
          >
            Apply Filter
          </Button>
        </Box>
        <Box className={styles.closeButton}>
          <Close sx={{fill: '#A4A2A2'}} onClick={handleCloseModal} />
        </Box>
      </Box>
    </Modal>
  );
};

export default FiltersSelect;
