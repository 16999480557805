import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import { useTheme } from "../../../../../../../context/theme-context";

const Image = ({ image, label }) => {
  const { theme } = useTheme();

  return (
    <Box className={styles.image}>
      <Box
        className={styles.primaryImg}
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></Box>
    </Box>
  );
};

export default Image;
