import { Box, Button, Chip, Divider, Typography } from "@mui/material";
import styles from "./style.module.css";
import Top from "./top";
import Image from "./image";
import { useTheme } from "../../../../../../context/theme-context";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ManageProjectsContext } from "../../../../../../context/manage-projects-context";
import { DiscoverContext } from "../../../../../../context/discover-context";
import { UserContext } from "../../../../../../context/user-context";
import { useSwipeable } from "react-swipeable";

const Card = ({ project }) => {
  const { theme, darkMode } = useTheme();
  const navigate = useNavigate();
  const {
    favorites,
    setFavorites,
    handleSwipeLeft,
    removing,
    handleSwipeRight,
    insertShoutout,
    deleteShoutout} = useContext(DiscoverContext);
  const [isFavorite, setIsFavorite] = useState(project?.shout_outed);
  const { fetchFavorite } = useContext(ManageProjectsContext);
  const { user } = useContext(UserContext);
  const [showConfetti, setShowConfetti] = useState(false);
  const [liking, setLiking] = useState(null);
  
  const handleClick = () => {
    setShowConfetti(true);
  };

  const handlers = useSwipeable({
    onSwipedRight: () => {
      handleSwipeLeft(project);
      AddFav();
    },
    onSwipedLeft: () => handleSwipeRight(project),
  });

  useEffect(() => {
    setIsFavorite(project.shout_outed)
  },[project])

  const removeFav = () => {
    setIsFavorite(false);
    deleteShoutout(project);
  }
  const AddFav = () => {
    setLiking(project)
    setIsFavorite(true)
    setTimeout(() => {
      setFavorites((prevItems) => [...prevItems, { sender_id: user.id, receiver_id: project.matched_user_id }]);
      handleClick();
      setLiking(null);
      insertShoutout(project);
    },100)
  }

  return (
    <Box {...handlers}
      className={`${styles.card} ${removing === project? styles.removing: ''} ${liking === project? styles.liking: ''}`}
      sx={{
        bgcolor: darkMode? theme.palette.lightgrey.lightgrey400:"#FFF",
      }}
    >
      <Top
        project={project}
        isFavorite={isFavorite}
        setIsFavorite={setIsFavorite}
        AddFav={AddFav}
        removeFav={removeFav}
        showConfetti={showConfetti}
        setShowConfetti={setShowConfetti}
      />
      <Divider sx={{ width: "100%" }} />
      <Box className={styles.content}>
        <Image image={project.skill_cover_image} />
        <Box className={styles.text}>
          <Typography
            component="h4"
            variant="body1"
            sx={{
              fontSize: "20px",
              fontWeight: "500",
              color: theme.palette.darkgrey.darkgrey600,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 1,
              textOverflow: "ellipsis",
              minHeight: "1.5em",
              width: '100%',
            }}
          >
            {project?.tagline || ''}
          </Typography>
          {/* <Typography
            className={styles.description}
            variant="body1"
            sx={{
              fontSize: "14px",
              color: theme.palette.darkgrey.darkgrey500,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 1,
              textOverflow: "ellipsis",
              // minHeight: "2em",
              width: '100%'
            }}
          >
            {project?.project_location}
          </Typography> */}
          {/* <Box className={styles.categories}>
            {project.actual_type.map((item, index) => (
              <Chip label={item} key={index} />
            ))}
          </Box> */}
        </Box>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box className={styles.button}>
        <Button
          variant="contained"
          onClick={() => navigate(`/creators/${project?.user_id}`)}
          sx={{
            textTransform: "capitalize",
            flex: "1",
            p: "10px 1.5rem",
            borderRadius: "4px",
          }}
        >
          View Creator
        </Button>
      </Box>
    </Box>
  );
};

export default Card;
