import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import { defaultSkillImage } from "../../../../../constants";
import { useTheme } from "../../../../../context/theme-context";

const Image = ({ image, editorChoice }) => {
  const { theme } = useTheme();

  return (
    <Box className={styles.image}>
      <Box
        className={styles.primaryImg}
        style={{ backgroundImage: image ? `url(${image})` : `url(${defaultSkillImage})` }}
      ></Box>
      <Box className={styles.options}>
        {editorChoice && (
          <Chip
            className={styles.editorChoice}
            label="Creator's Choice"
            sx={{
              bgcolor: theme.palette.warning.main,
              color: '#FFF',
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Image;
