import React from "react";
import styles from "./style.module.css";
import { Box, Typography } from "@mui/material";
import { Check, Info, PieChartOutlined } from "@mui/icons-material";
import { useTheme } from "../../../../../../../context/theme-context";

const Alert = (props) => {
  const { theme } = useTheme();
  const { type } = props;

  return (
    <React.Fragment>
      {(() => {
        switch (type) {
          case "complete":
            return (
              <Box
                className={styles.alert}
                sx={{
                  border: "1px solid",
                  borderColor: "#FFF",
                  background: theme.palette.success.dark,
                }}
              ></Box>
            );
          case "notComplete":
            return (
              <Box
                className={styles.alert}
                sx={{
                  border: "1px solid",
                  borderColor: '#FFF',
                  background: theme.palette.danger.red500,
                }}
              ></Box>
            );
          case "notAdded":
            return <></>;
          default:
            return null; // Default case, return null or another fallback value
        }
      })()}
    </React.Fragment>
  );
};

export default Alert;
