import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import styles from "./style.module.css";
import { useTheme } from "../../../../context/theme-context";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProfileTabs = ({ isIndecatorHide, setHideIndcator, data, realName }) => {
  const { theme } = useTheme();

  const handleChange = (event, newValue) => { 
    data.setValue(newValue);
    setHideIndcator(false);

  };

  return (
    <React.Fragment>
      <Box className={styles.tabs}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={data.value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              width: "100%",
              "& .MuiTab-root": {
                color: "#fff",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "rgba(0,0,0,.1)", // Hover background color
                  color: "#000",
                  transition: ".3s ease-in-out",
                },
                "&.Mui-selected": {
                  color: theme.palette.tertiary.golden500,
                  "&:hover": {
                    backgroundColor: "inherit", // Hover background color
                  },
                },
              },
              "& .MuiTabs-indicator": {
                backgroundColor: theme.palette.tertiary.golden500,
                height: isIndecatorHide? 0: '2px',

              },
            }}
          >
            <Tab label="About me" {...a11yProps(0)} />
            <Tab label="my skills" {...a11yProps(1)} />
            <Tab label="projects" {...a11yProps(2)} />
            <Tab {...a11yProps(3)} disabled sx={{minWidth: '0'}} />
          </Tabs>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ProfileTabs;
