import { Box, Button, Chip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import styles from "./style.module.css";
import { useTheme } from "../../context/theme-context";
import BasicTabs from "./components/tabs";
import supabase from "../../config/supabaseClient";

const ShoutOuts = ({ user }) => {
  const { theme } = useTheme();

  const [receivedShoutOuts, setReceivedShoutOuts] = useState([]);
  const [givenShoutOuts, setGivenShoutOuts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  
  
  useEffect(() => {
    const fetchGivenShoutOuts = async () => {
      setIsLoading(true);
      const { data, error } = await supabase
        .from("given_shoutouts")
        .select()
        .eq("sender_id", user?.id);
  
      setGivenShoutOuts(data);
  
      setIsLoading(false);
    };

    const fetchReceivedShoutOuts = async () => {
      setIsLoading(true);
      const { data, error } = await supabase
        .from("received_shoutouts")
        .select()
        .eq("receiver_id", user?.id);
      setReceivedShoutOuts(data);
      setIsLoading(false);
    };
    
    fetchReceivedShoutOuts();
    fetchGivenShoutOuts();
    console.log(givenShoutOuts, 'testGiven shout outs');
  }, [user]);

  return (
    <Box
      className={styles.newClassName}
      sx={{ backgroundColor: theme.palette.lightgrey.lightgrey600 }}
    >
      <Box className={styles.content}>
      <Typography variant="body1" 
                sx={{
                    fontSize: '1.5rem',
                    fontWeight: '500',
                    textAlign: 'centerd',
                    display: 'flex',
                    alignSelf: 'stretch',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: theme.palette.darkgrey.darkgrey600,
                }}
            >Shout Outs</Typography>
            <BasicTabs receivedShoutOuts={receivedShoutOuts} givenShoutOuts={givenShoutOuts} />
      </Box>
    </Box>
  );
};

export default ShoutOuts;
