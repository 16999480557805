import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import styles from "./style.module.css";
import { useTheme } from "../../../../../../../../../context/theme-context";
import { useContext, useEffect, useState } from "react";
import { Link } from "@mui/icons-material";
import NotLogedIn from "../not-loged"
import ContactEmail from "./components/contact-email";



const Content02 = ({
    creatorTypes,
    ageRanges,
    projectRoles,
    project }) => {
    
    const { theme, darkMode } = useTheme();
    
  useEffect(() => {
    // console.log(projectRoles, 'item test');
    },[])
  
    return (
        <Box className={styles.content02}>
        <Box className={styles.textItem}>
          <Typography
            variant="body1"
            sx={{
              color: darkMode ? theme.palette.darkgrey.darkgrey400
                : theme.palette.darkgrey.darkgrey600,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Creator Types
          </Typography>
          <Box className={styles.assitsChips}>
            {creatorTypes.map((item) => {
              return (
                <Chip
                  key={item.id}
                  label={item.creator_type}
                  sx={{
                    bgcolor: "#00B0F0",
                    color: "#FFF",
                  }}
                />
              );
            })}
          </Box>
        </Box>
        <Box className={styles.textItem}>
          <Typography
            variant="body1"
            sx={{
              color: darkMode ? theme.palette.darkgrey.darkgrey400
                : theme.palette.darkgrey.darkgrey600,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Category, Skills & Specialties
          </Typography>
          {projectRoles?.map((item) => (
            <Box className={styles.assitsChips}>
              <Chip
                key={item.id}
                label={item.category}
                sx={{
                  bgcolor: "#00B0F0",
                  color: "#FFF",
                }}
              />
              <Chip
                key={item.id}
                label={item.creator_skill}
                sx={{
                  bgcolor: "#00B0F0",
                  color: "#FFF",
                }}
              />
              <Chip
                key={item.id}
                label={item.speciality}
                sx={{
                  bgcolor: !darkMode && theme.palette.darkgrey.darkgrey500,
                  color: "#FFF",
                }}
              />
            </Box>
          ))}
          {/* <Box className={styles.assitsChips}>
            {projectRoles?.map((item) => (
              <Chip
                key={item.id}
                label={item.speciality}
                sx={{
                  bgcolor: "#00B0F0",
                  color: "#FFF",
                }}
              />
            ))}
          </Box> */}
        </Box>
        <Box className={styles.textItem}>
          <Typography
            variant="body1"
            sx={{
              color: darkMode ? theme.palette.darkgrey.darkgrey400
                : theme.palette.darkgrey.darkgrey600,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Age Range of Creators
          </Typography>
          <Box className={styles.assitsChips}>
            {ageRanges.map((item) => (
              <Chip
                key={item.id}
                label={item.age_range}
                sx={{
                  bgcolor: "#00B0F0",
                  color: "#FFF",
                }}
              />
            ))}
          </Box>
        </Box>
        <Box className={styles.textItem}>
          <Typography
            variant="body1"
            sx={{
              color: darkMode ? theme.palette.darkgrey.darkgrey400
                : theme.palette.darkgrey.darkgrey600,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Experience Level
          </Typography>
          <Box className={styles.assitsChips}>
            <Chip
              label={project.experience_level}
              sx={{
                bgcolor: "#00B0F0",
                color: "#FFF",
              }}
            />
          </Box>
        </Box>
        <Box className={styles.textItem}>
          <Typography
            variant="body1"
            sx={{
              color: darkMode ? theme.palette.darkgrey.darkgrey400
                : theme.palette.darkgrey.darkgrey600,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Project Contact Email
          </Typography>

          <NotLogedIn />
          <ContactEmail email={project.contact_email} />
          
        </Box>
      </Box>       
    )
}

export default Content02;