import styles from "./style.module.css";
import { Box, Link, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import Alert from "../alert";
import { SkillsBarContext } from "../../../../../../../context/skills-bar-context";
import { useNavigate } from "react-router-dom";

import activeImage from "../../../../../../../assets/images/Blueprint-Rocket-Plan-Architecture-Construction.svg";
import notActiveImage from "../../../../../../../assets/images/_x36_36_x2C__Art_x2C__Arts_x2C__Paint_x2C__Color.svg";
import { useTheme } from "../../../../../../../context/theme-context";

const Skill = ({ skill, index }) => {
  const { theme, darkMode } = useTheme();
  const navigate = useNavigate();
  const { activeSkill, setActiveSkill } = useContext(SkillsBarContext);
  const isActive = skill?.id === activeSkill?.id && skill.id !== undefined;
  const isSkillComplete =
    skill.name &&
    skill.description &&
    skill.level &&
    skill.started_training_age;

  const notCreated = skill.created === false;
  useEffect(() => {}, [activeSkill]);
  const setSkills = () => {};
  // Function to handle button click and update state
  const handleItemClick = () => {
    if (notCreated) navigate("/add-skill");
    if (!isActive) setActiveSkill(skill);
  };
  return (
    <Box className={styles.skill} key={index}>
      <Box
        className={styles.skillNum}
        sx={{
          background: isActive
            ? theme.palette.primary.main
            : theme.palette.lightgrey.lightgrey00,
        }}
        onClick={() => handleItemClick(index)}
      >
        {isActive ? (
          <img src={skill?.active_image} />
        ) : notCreated ? (
          <Add
            sx={{
              color: darkMode
                ? theme.palette.primary.purpel100
                : theme.palette.primary.main,
              fontSize: "32px",
            }}
          />
        ) : !isActive && !notCreated ? (
          <img src={skill.in_active_image} />
        ) : (
          <></>
        )}
      </Box>
      <Box className={styles.text}>
        {/* skill category*/}
        <Typography
          variant="h4"
          sx={{
            color: isActive
              ? darkMode
                ? "#FFF"
                : theme.palette.primary.main
              : darkMode
              ? theme.palette.darkgrey.darkgrey300
              : theme.palette.darkgrey.darkgrey600,
            fontSize: {
              xs: "12px",
              // md: "16px",
            },
            fontWeight: "500",
          }}
        >
          {notCreated ? "Add New" : skill.category}
        </Typography>
      </Box>
      <Alert
        type={
          isSkillComplete
            ? "complete"
            : skill.created === false
            ? "notAdded"
            : "notComplete"
        }
      />
    </Box>
  );
};

export default Skill;
