import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Link, Button, Chip } from "@mui/material";
import styles from "./style.module.css";
import { UserContext } from "../../../../../../context/user-context";
import supabase from "../../../../../../config/supabaseClient";
import { useTheme } from "../../../../../../context/theme-context";

const SkillStatus = ({ skill }) => {
  const { theme, darkMode } = useTheme();
  const [skillsReports, setSkillsReports] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchReports = async () => {
      const { data, error } = await supabase
        .from("skills_reports")
        .select()
        .match({ skill_id: skill.id, reporter_id: user?.id });
      setSkillsReports(data);
      if (error) fetchReports();
    };
    if (user?.id && skill) fetchReports();
  }, [skill, user]);
  const handleReportClick = async () => {
    if (skillsReports.length === 0) {
      await supabase
        .from("skills_reports")
        .insert({ skill_id: skill.id, reporter_id: user?.id });
      setSkillsReports([{ skill_id: skill.id, reporter_id: user?.id }]);
    } else {
    }
  };
  if (!skill) return <div></div>;

  return (
    <Box
      className={styles.skillsCommunityStatus}
      sx={{
        background: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Box className={styles.report}>
        <Button
          style={{
            backgroundColor: darkMode
              ? "#3D3D3D"
              : theme.palette.lightgrey.lightgrey600,
            padding: "4px 12px",
          }}
          onClick={handleReportClick}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "12px",
              color: theme.palette.darkgrey.darkgrey500,
            }}
          >
            {skillsReports.length > 0 ? "Reported" : "Report"}
          </Typography>
        </Button>
      </Box>
      <Box className={styles.skillsItem}>
        <Typography
          variant="body1"
          className={styles.heading}
          sx={{
            color: theme.palette.darkgrey.darkgrey600,
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          Category
        </Typography>
        <Box className={styles.tags}>
          <Chip label={skill.category} />
        </Box>
      </Box>
      <Box className={styles.skillsItem}>
        <Typography
          variant="body1"
          className={styles.heading}
          sx={{
            color: theme.palette.darkgrey.darkgrey600,
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          Skill
        </Typography>
        <Box className={styles.tags}>
          <Chip label={skill.name} />
        </Box>
      </Box>
      <Box className={styles.skillsItem}>
        <Typography
          variant="body1"
          className={styles.heading}
          sx={{
            color: theme.palette.darkgrey.darkgrey600,
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          Specialty
        </Typography>
        <Box className={styles.tags}>
          <Chip label={skill.speciality} />
        </Box>
      </Box>
    </Box>
  );
};

export default SkillStatus;
