import React, { useContext } from "react";
import { Box, Typography, Link, Button, Chip } from "@mui/material";
import styles from "./style.module.css";
import { SkillsBarContext } from "../../../../../context/skills-bar-context";
import { useTheme } from "../../../../../context/theme-context";

const SkillStatus = () => {
  const { theme } = useTheme();
  const { activeSkill } = useContext(SkillsBarContext);

  return (
    <Box
      className={styles.skillsCommunityStatus}
      sx={{
        background: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Box className={styles.skillsItem}>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.darkgrey.darkgrey600,
            fontSize: '18px',
            fontWeight: '700',
          }}
        >
          Category
        </Typography>
        <Box className={styles.tags}>
          {activeSkill?.category
            ? (
              <Chip
                label={activeSkill.category}
              />
            ) : (
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.darkgrey.darkgrey400,
                }}
              >No category selected ye!</Typography>
            )}
        </Box>
      </Box>
      <Box className={styles.skillsItem}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.darkgrey.darkgrey600,
              fontSize: '18px',
              fontWeight: '700'
            }}
          >
            Skill
          </Typography>
        </Box>
        <Box className={styles.tags}>
          {activeSkill?.name ? (
            <Chip
              label={activeSkill.name}
            />
          ) : (
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.darkgrey.darkgrey400,
              }}
            >No skill selected yet!</Typography>
          )}
        </Box>
      </Box>
      <Box className={styles.skillsItem}>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.darkgrey.darkgrey600,
            fontSize: '18px',
            fontWeight: '700',
          }}
        >
          Specialty
        </Typography>
        <Box className={styles.tags}>
            {activeSkill.speciality
              ? (
                <Chip
                  label={activeSkill.speciality}
                />
            ) : (
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.darkgrey.darkgrey400,
                }}
                >No specialty selected yet!</Typography>
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default SkillStatus;
