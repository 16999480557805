import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import styles from "./style.module.css";
import plane from "../../../../assets/images/plane.svg";
import shield from "../../../../assets/images/shield-01.svg";
import certificate from "../../../../assets/images/certificate-01.svg";
import trash from "../../../../assets/images/trash-01.svg";
import logOut from "../../../../assets/images/log-out-04.svg";
import mail from "../../../../assets/images/mail-01.svg";
import guidlines from "../../../../assets/images/users-edit.svg";
import { useNavigate } from "react-router-dom";
import { SettingsContext } from "../../../../context/settings-context";
import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "../../../../context/theme-context";

const CommunityGuidlines_Url =
  "https://uploads-ssl.webflow.com/651750892fc953e528ca7b72/65a28b6d7c89e05bfa45b376_LYNX%20Community%20Guidelines.pdf";
const Privacy_Url =
  "https://uploads-ssl.webflow.com/651750892fc953e528ca7b72/65a28b95390d57d916ca0b80_LYNX%20Privacy%20Policy.pdf";
const Terms_Url =
  "https://uploads-ssl.webflow.com/651750892fc953e528ca7b72/65a28be0d84d81bc4859e400_LYNX%20Terms%20%26%20Conditions.pdf";

const Menu = () => {
  const { theme, darkMode } = useTheme();
  const { logout } = useAuth0();
  const { openDeleteConfirmation, setOpenDeleteConfirmation } =
    useContext(SettingsContext);
  const navigate = useNavigate();
  const handleSubStatus = () => {
    // navigate("/subscription-status");
  };
  const handlePrivacy = () => {
    navigate("/privacy&policy");
  };
  const handleTermsOfService = () => {
    navigate("/terms-of-service");
  };
  const handleCommunityGuidlines = () => {
    /////////////
  };
  const handleEmailPreferences = () => {
    navigate("/email-preferences");
  };
  const handleDeleteAcount = () => {
    setOpenDeleteConfirmation(true);
  };
  // this function is used to download files
  const downloadFileAtUrl = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };
  const handleLogout = () => {
    const darkMode = localStorage.getItem("darkMode");
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("darkMode", darkMode);
    logout();
  };

  return (
    <Box className={styles.menu}>
      <Box className={styles.content}>
        <Typography
          variant="body1"
          sx={{
            color: darkMode
              ? theme.palette.darkgrey.darkgrey600
              : theme.palette.darkgrey.darkgrey300,
          }}
        >
          Settings
        </Typography>
        <Box className={styles.menus}>
          <Button
            startIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M2.69893 10.3007C2.47488 10.5245 2.29714 10.7903 2.17587 11.0828C2.05461 11.3754 1.99219 11.689 1.99219 12.0057C1.99219 12.3223 2.05461 12.6359 2.17587 12.9285C2.29714 13.221 2.47488 13.4868 2.69893 13.7107L10.2889 21.3007C10.5128 21.5247 10.7785 21.7024 11.0711 21.8237C11.3637 21.945 11.6772 22.0074 11.9939 22.0074C12.3106 22.0074 12.6242 21.945 12.9168 21.8237C13.2093 21.7024 13.4751 21.5247 13.6989 21.3007L21.2889 13.7107C21.513 13.4868 21.6907 13.221 21.812 12.9285C21.9333 12.6359 21.9957 12.3223 21.9957 12.0057C21.9957 11.689 21.9333 11.3754 21.812 11.0828C21.6907 10.7903 21.513 10.5245 21.2889 10.3007L13.6989 2.71065C13.4751 2.4866 13.2093 2.30886 12.9168 2.18759C12.6242 2.06632 12.3106 2.00391 11.9939 2.00391C11.6772 2.00391 11.3637 2.06632 11.0711 2.18759C10.7785 2.30886 10.5128 2.4866 10.2889 2.71065L2.69893 10.3007Z" stroke="#F4FCB9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.19922 9.19922H9.20922" stroke="#F4FCB9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.5 9.5L9.5 14.5" stroke="#F4FCB9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.6992 14.8008H14.7092" stroke="#F4FCB9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>}
            endIcon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
            <path d="M1.33203 12.998H14.6712" stroke="#D591F0" stroke-width="1.04758" stroke-miterlimit="10"/>
            <path d="M1.33984 3.66797L2.66678 8.33318V12.9984H13.3381V8.33318L14.672 3.66797" stroke="#D591F0" stroke-width="1.04758" stroke-miterlimit="10"/>
            <path d="M14.6696 3.6679L10.6678 5.66528L8 2.33398" stroke="#D591F0" stroke-width="1.04758" stroke-miterlimit="10"/>
            <path d="M8.00244 2.33398L5.33461 5.66528L1.33984 3.6679" stroke="#D591F0" stroke-width="1.04758" stroke-miterlimit="10"/>
            <path d="M8.00188 10.9948C8.73859 10.9948 9.3358 10.1005 9.3358 8.99738C9.3358 7.89426 8.73859 7 8.00188 7C7.26518 7 6.66797 7.89426 6.66797 8.99738C6.66797 10.1005 7.26518 10.9948 8.00188 10.9948Z" stroke="#D591F0" stroke-width="1.04758" stroke-miterlimit="10"/>
            <path d="M10.668 8.99609H13.3358" stroke="#D591F0" stroke-width="1.04758" stroke-miterlimit="10"/>
            <path d="M5.33189 8.99609H2.66406" stroke="#D591F0" stroke-width="1.04758" stroke-miterlimit="10"/>
            <path d="M7.9994 2.32693C8.36583 2.32693 8.66287 2.02989 8.66287 1.66347C8.66287 1.29704 8.36583 1 7.9994 1C7.63298 1 7.33594 1.29704 7.33594 1.66347C7.33594 2.02989 7.63298 2.32693 7.9994 2.32693Z" stroke="#D591F0" stroke-width="1.04758" stroke-miterlimit="10"/>
            <path d="M1.33143 3.66092C1.69786 3.66092 1.9949 3.36387 1.9949 2.99745C1.9949 2.63103 1.69786 2.33398 1.33143 2.33398C0.965012 2.33398 0.667969 2.63103 0.667969 2.99745C0.667969 3.36387 0.965012 3.66092 1.33143 3.66092Z" stroke="#D591F0" stroke-width="1.04758" stroke-miterlimit="10"/>
            <path d="M14.6713 3.66092C15.0377 3.66092 15.3347 3.36387 15.3347 2.99745C15.3347 2.63103 15.0377 2.33398 14.6713 2.33398C14.3049 2.33398 14.0078 2.63103 14.0078 2.99745C14.0078 3.36387 14.3049 3.66092 14.6713 3.66092Z" stroke="#D591F0" stroke-width="1.04758" stroke-miterlimit="10"/>
          </svg>}
            className={styles.item}
            onClick={()=> navigate('/user-ratings')}
            sx={{
              color: theme.palette.darkgrey.darkgrey400,
            }}
          >
            My Creator Rating
          </Button>
          <Button
            startIcon={<img src={plane} alt="palne" />}
            className={styles.item}
            onClick={handleSubStatus}
            sx={{
              color: theme.palette.darkgrey.darkgrey400,
            }}
          >
            Subscription Status
          </Button>
          <Button
            startIcon={<img src={shield} alt="shield" />}
            className={styles.item}
            onClick={() => downloadFileAtUrl(Privacy_Url)}
            sx={{ color: theme.palette.darkgrey.darkgrey400 }}
          >
            Privacy Policy
          </Button>
          <Button
            startIcon={<img src={certificate} alt="certificate" />}
            className={styles.item}
            onClick={() => downloadFileAtUrl(Terms_Url)}
            sx={{ color: theme.palette.darkgrey.darkgrey400 }}
          >
            Terms Of Service
          </Button>
          <Button
            startIcon={<img src={guidlines} alt="guidlines" />}
            className={styles.item}
            onClick={() => downloadFileAtUrl(CommunityGuidlines_Url)}
            sx={{ color: theme.palette.darkgrey.darkgrey400 }}
          >
            Community Guidelines
          </Button>
          <Button
            startIcon={<img src={mail} alt="mail" />}
            className={styles.item}
            onClick={handleEmailPreferences}
            sx={{ color: theme.palette.darkgrey.darkgrey400 }}
          >
            Email Preferences
          </Button>
          <Button
            startIcon={<img src={trash} alt="trash" />}
            className={styles.item}
            onClick={handleDeleteAcount}
            sx={{ color: theme.palette.darkgrey.darkgrey400 }}
          >
            Delete Account
          </Button>
          <Button
            startIcon={<img src={logOut} alt="log out" />}
            onClick={handleLogout}
            className={styles.item}
            sx={{ color: theme.palette.darkgrey.darkgrey400 }}
          >
            Sign out
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Menu;
