import { Box, Typography } from "@mui/material";
import styles from "./style.module.css";
import Header from "./components/header";
import { useTheme } from "../../../../../context/theme-context";
import Content from "./components/content";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import supabase from "../../../../../config/supabaseClient";
import { Favorite, FavoriteBorderOutlined } from "@mui/icons-material";
import { ManageProjectsContext } from "../../../../../context/manage-projects-context";

const ProjectDetails = () => {
  const { theme } = useTheme();
  const { id } = useParams();
  const [project, setProject] = useState({});
  const [projectCreators, setProjectCreators] = useState([]);
  const [projectAgeRanges, setProjectAgeRanges] = useState([]);
  const [projectUser, setProjectUser] = useState({});
  const [projectRoles, setProjectRoles] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const { handleFavorite, fetchFavorite } = useContext(ManageProjectsContext);


  const addToFavorite = (id) => {
    setIsFavorite(true);
    handleFavorite(id, 'add');
  }
  const removeFromFavorite = (id) => {
    setIsFavorite(false);
    handleFavorite(id, 'remove')
  }


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Optional: Adds smooth scrolling behavior
    });

    const fetchData = async () => {
      const { data, error } = await supabase
        .from("projects")
        .select()
        .eq("id", id);
      setProject(data[0]);
      // console.log(data, 'test returned data');
      const { data: creators } = await supabase
        .from("projects_creator_types")
        .select()
        .eq("project_id", id);
      setProjectCreators(creators);
      const { data: ageRanges } = await supabase
        .from("projects_age_ranges")
        .select()
        .eq("project_id", id);
      setProjectAgeRanges(ageRanges);
    };

    fetchData();

  }, []);
  useEffect(() => {
    const fetchData = async () => {
      if (project) {
        const { data } = await supabase
          .from("users")
          .select()
          .eq("id", project.user_id);
        const { data: storedProjectRoles } = await supabase
          .from("projects_roles")
          .select()
          .eq("project_id", project?.id);
        if (data && storedProjectRoles) {
          setProjectRoles(storedProjectRoles);
          setProjectUser(data[0]);
        }
      }
    };
    const fetchFavouriteResult = async () => {
      const checkIsFavourite = await fetchFavorite(id);
      setIsFavorite(checkIsFavourite);
    }
    
    fetchData();
    fetchFavouriteResult();

  }, [project]);

  return (
    <Box
      className={styles.projectDetails}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Header
        image={project?.project_cover_image}
        category={project?.category}
      />
      <Box className={styles.container}>
        <Box className={styles.title}>
          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              color: theme.palette.darkgrey.darkgrey600,
            }}
          >
            {project?.title}
          </Typography>

          {isFavorite ? (
            <Favorite
              sx={{ fill: theme.palette.primary.light }}
              onClick={()=>{removeFromFavorite(project.id)}}
            />
          
          ) : (
              <FavoriteBorderOutlined
                sx={{ fill: theme.palette.primary.light }}
                onClick={()=>{addToFavorite(project.id)}}
              />   
          )}

        </Box>
        <Content
          projectUser={projectUser}
          project={project}
          creatorTypes={projectCreators}
          ageRanges={projectAgeRanges}
          projectRoles={projectRoles}
        />
      </Box>
    </Box>
  );
};

export default ProjectDetails;
