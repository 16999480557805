import React, { useState } from 'react';
import { Autocomplete, Box, Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import styles from './style.module.css';
import { useTheme } from '../../../../../../context/theme-context';

const Gens = [ "X+", "X", "Y+", "Y", "Z", "Z+"];


const AgeRanges = (props) => {
    const { theme, darkMode } = useTheme();
    const { ageRanges, setAgeRanges } = props;

    const handleAgeRangesChange = (range) => {
        const index = ageRanges.findIndex((item) => item === range);
        if (index === -1) {
          setAgeRanges([...ageRanges, range]);
        } else {
          setAgeRanges([
            ...ageRanges.filter((item, itemIndex) => itemIndex !== index),
          ]);
        }
    };

    return (
        <Box className={styles.inputField}>
            <Typography
                variant="body1"
                color={theme.palette.darkgrey.darkgrey600}
                fontWeight={500}
            >
                Age range of creators
            </Typography>
            <FormGroup
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    rowGap: darkMode? '3px': '12px',
                    "& .css-1070ein-MuiTypography-root": {
                        fontSize: "14px",
                        lineHeight: '22px',
                    },
                    // "&.MuiFormGroup-root ": {
                    //     rowGap: '3px',
                    //     columnGap: '8px'
                    // }
                }}
            >
              {Gens.map((Gen, index) => (
                <Box
                  key={index}
                  className={styles.ageGen}
                  sx={{
                    "& .css-j204z7-MuiFormControlLabel-root": {
                      marginRight: "0",
                    },
                    "& .css-onpov0-MuiButtonBase-root-MuiCheckbox-root": {
                      padding: "0px 8px 0px 9px",
                    },
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox size="small" />}
                    defaultChecked={
                      ageRanges.findIndex((item) => item === Gen) === -1
                        ? false
                        : true
                    }
                    checked={
                      ageRanges.findIndex((item) => item === Gen) === -1
                        ? false
                        : true
                    }
                    onChange={() => handleAgeRangesChange(Gen)}
                    label={ Gen === "All"? Gen : `Gen ${Gen}`}
                    sx={{
                      color: theme.palette.darkgrey.darkgrey500,
                    }}
                  />
                </Box>
              ))}
            </FormGroup>    
        </Box>
    );
}

export default AgeRanges;