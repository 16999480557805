import React, { useContext, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import styles from "./style.module.css";
import trash from "../../../../assets/images/trash-03.svg";
import { useTheme } from "../../../../context/theme-context";

const FlagConfirmation = ({isOpen, setIsOpen, handleFlagClick}) => {
  const { theme, darkMode } = useTheme();
  
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        className={styles.delConfirm}
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey00,
          padding: '40px 0px !important',
          borderRadius: '8px !important',
          width: {
            xs: "320px",
            // sm: "360px",
          },
        }}
      >
        <Box className={styles.content}>
          <Box className={styles.frame}>
            <Box className={styles.icon}>
              <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none">
              <path d="M14.668 55.0006C14.668 55.0006 18.3346 51.334 29.3346 51.334C40.3346 51.334 47.668 58.6673 58.668 58.6673C69.668 58.6673 73.3346 55.0006 73.3346 55.0006V11.0007C73.3346 11.0007 69.668 14.6673 58.668 14.6673C47.668 14.6673 40.3346 7.33398 29.3346 7.33398C18.3346 7.33398 14.668 11.0007 14.668 11.0007V55.0006Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.668 80.6667V55" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </Box>
            <Box className={styles.text}>
              <Typography
                variant="body1"
                component="h4"
                sx={{
                  color: theme.palette.darkgrey.darkgrey600,
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                Do you want to Flag this User?
              </Typography>
            </Box>
          </Box>
          <Box className={styles.buttons}>
            <Button
              variant="text"
              onClick={handleClose}
              sx={{
                textTransform: "capitalize",
                color: theme.palette.darkgrey.darkgrey300,
                bgcolor: darkMode
                  ? "#3D3D3D"
                  : theme.palette.lightgrey.lightgrey500,
                padding: "8px",
                boxShadow: "none",
                fontSize: "16px",
                width: "120px",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                color: "#FFF",
                bgcolor: theme.palette.primary.main,
                padding: "8px",
                width: "120px",
                fontSize: "16px",
              }}
              onClick={() => { handleFlagClick(); handleClose() }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default FlagConfirmation;
