import { Box, Button } from "@mui/material";
import React, { useContext, useEffect } from "react";
import EditSkillMainInfo from "./comonents/editSkill";
import EditDescription from "./comonents/editDescription";
import { EditSkillContext } from "../../../../../context/edit-skill-context";
import { UserContext } from "../../../../../context/user-context";
import { SkillsBarContext } from "../../../../../context/skills-bar-context";
import { useTheme } from "../../../../../context/theme-context";

const EditSkill = () => {
  const { user } = useContext(UserContext);
  const { activeSkill, setActiveSkill } = useContext(SkillsBarContext);
  const { theme } = useTheme();
  
  const {
    setCategory,
    setSkill,
    setSpeciality,
    setIsPrimary,
    setDescription,
    setPortfolioLink,
    setAgeStarted,
    setLevel,
    setAcceptMarket,
  } = useContext(EditSkillContext);
  useEffect(() => {
    if (activeSkill && activeSkill?.user_id === user?.id) {
      setCategory(activeSkill.category);
      setSkill(activeSkill.name);
      setSpeciality(activeSkill.speciality);
      setIsPrimary(activeSkill.is_primary);
      setDescription(activeSkill.description);
      setPortfolioLink(activeSkill.link);
      setAgeStarted(activeSkill.started_training_age);
      setLevel(activeSkill.level);
      setAcceptMarket(activeSkill.accept_only_paid_project);
    }
  }, [user, activeSkill]);

  return (
    <Box style={{ paddingBottom: "5rem" }} sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}>
      <EditSkillMainInfo
        activeSkill={activeSkill}
        setActiveSkill={setActiveSkill}
      />
      <EditDescription
        activeSkill={activeSkill}
        setActiveSkill={setActiveSkill}
      />
    </Box>
  );
};

export default EditSkill;
