import moment from "moment";
import { createContext, useState } from "react";

export const EditSkillContext = createContext(null);
const EditSkillContextProvider = (props) => {
  const [category, setCategory] = useState("");
  const [skill, setSkill] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [isPrimary, setIsPrimary] = useState(false);
  const [description, setDescription] = useState("");
  const [portfolioLink, setPortfolioLink] = useState("");
  const [ageStarted, setAgeStarted] = useState("");
  const [level, setLevel] = useState("");
  const [acceptMarket, setAcceptMarket] = useState(false);
  const clearEditState = () => {
    setCategory("");
    setSkill("");
    setSpeciality("");
    setIsPrimary(false);
    setDescription("");
    setPortfolioLink("");
    setAgeStarted("");
    setLevel("");
    setAcceptMarket(false);
  };
  const contextValue = {
    clearEditState,
    category,
    setCategory,
    skill,
    setSkill,
    speciality,
    setSpeciality,
    isPrimary,
    setIsPrimary,
    description,
    setDescription,
    portfolioLink,
    setPortfolioLink,
    ageStarted,
    setAgeStarted,
    level,
    setLevel,
    acceptMarket,
    setAcceptMarket,
  };

  return (
    <EditSkillContext.Provider value={contextValue}>
      {props.children}
    </EditSkillContext.Provider>
  );
};
export default EditSkillContextProvider;
