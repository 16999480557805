import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '../../../../context/theme-context';
import { ManageProjectsContext } from '../../../../context/manage-projects-context';
import Received from '../../text/received';
import Given from '../../text/given';
import styles from './style.module.css';
import { useSearchParams } from 'react-router-dom';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: '1rem' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({receivedShoutOuts, givenShoutOuts}) {
    const [value, setValue] = React.useState(0);
    const { theme, darkMode } = useTheme();
    const [searchParams] = useSearchParams();
  
  const [tabName, setTabName] = React.useState(searchParams.get('shoutouts-tab'));
  console.log(tabName, "tab Name test");

  React.useEffect(() => {
    if (tabName === "given") {
      setValue(1);
    }else if (tabName === "received") {
      setValue(0);
    }
  }, [tabName])

    React.useEffect(() => {
      console.log(receivedShoutOuts, 'receivedShoutOuts');
      console.log(givenShoutOuts, 'givenShoutOuts');
    },[])
  
  
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered
                sx={{
                    '& .MuiTab-root': {
                        color: darkMode && theme.palette.darkgrey.darkgrey400,
                        textTransform: 'capitalize',
                        '&.Mui-selected': {
                            color: darkMode && theme.palette.darkgrey.darkgrey500,
                        },
                    },
                    
                }}
            >
            <Tab sx={{textTransform: 'capitalize'}} label={`Received(${receivedShoutOuts?.length})`} {...a11yProps(0)} />
            <Tab sx={{textTransform: 'capitalize'}} label={`Given(${givenShoutOuts?.length})`} {...a11yProps(1)} />
            </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box className={styles.card}>
            {receivedShoutOuts?.map((user, index) => (
              <Box key={index} className={styles.container}>
                <Received data={user} />
              </Box>
            ))}
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box className={styles.card}>
            {givenShoutOuts?.map((item, index) => (
              <Box key={index} className={styles.container}>
                {<Given data={item} />}{" "}
              </Box>
            )) }
          </Box>
        </CustomTabPanel>
        
        </Box>
    );
}