import React, { useContext, useEffect } from "react";
import styles from './style.module.css';
import { Box, Typography} from "@mui/material";
import BackButton from "../back-button";
import SubscriptionCard from "./subscription-card";
import { useTheme } from "../../../context/theme-context";

const SubscriptionStatus = () => {
    const { theme } = useTheme();

    return (
        <Box className={styles.subStatus}
            sx={{
                bgcolor: theme.palette.lightgrey.lightgrey500,
            }}
        >
            <Box className={styles.container}>
                <BackButton />
                <Box className={styles.details}>
                    <Box className={styles.content}>
                        <Typography variant="body1" component="h3"
                            sx={{
                                color: theme.palette.darkgrey.darkgrey700,
                                fontSize: '24px',
                                fontWeight: '500',
                            }}
                        >Subscription Status</Typography>
                        <SubscriptionCard />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
export default SubscriptionStatus;