import { Box, Button } from "@mui/material";
import styles from './styles.module.css';
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import { useTheme } from "../../../../context/theme-context";

function Mode() {
    const { theme, darkMode, toggleToDarkMode, toggleToLightMode } = useTheme();

    return (
        <Box className={styles.mode}
            sx={{
                bgcolor: theme.palette.lightgrey.lightgrey00,
            }}
        >
            <Button
                variant="contained"
                startIcon={<LightModeOutlined />}
                onClick={toggleToLightMode}
                sx={{
                    justifyContent: 'center',
                    fontSize: '14px',
                    fontWeight: '500',
                    letterSpacing: .7,
                    padding: '8px 12px',
                    flex: '1 0 0',
                }}
            >Light Mode</Button>
            <Button
                variant="text"
                startIcon={<DarkModeOutlined />}
                onClick={toggleToDarkMode}
                sx={{
                    justifyContent: 'center',
                    fontSize: '14px',
                    fontWeight: '500',
                    letterSpacing: .7,
                    padding: '8px 12px',
                    flex: '1 0 0',
                    bgcolor: darkMode
                        ? '#3D3D3D'
                        : theme.palette.lightgrey.lightgrey500,
                    color: theme.palette.darkgrey.darkgrey500,
                    boxShadow: '0'
                }}
            >Dark Mode</Button>
        </Box>
    ); 
}

export default Mode;