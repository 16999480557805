import { Box, Button, Divider, Typography } from "@mui/material";
import styles from "./style.module.css";
import Top from "./top";
import Image from "./image";
import { useTheme } from "../../../../../../context/theme-context";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ManageProjectsContext } from "../../../../../../context/manage-projects-context";
import { DiscoverContext } from "../../../../../../context/discover-context";
import { useSwipeable } from "react-swipeable";
import supabase from "../../../../../../config/supabaseClient";

const ProjectCard = ({ project, setIsConfetti }) => {
  const { theme, darkMode } = useTheme();
  const { handleSwipeLeft, removing, handleSwipeRight } = useContext(DiscoverContext);
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(project.is_favourite);
  const { fetchFavorite } = useContext(ManageProjectsContext);
  const { handleFavorite } = useContext(ManageProjectsContext);
  const [showConfetti, setShowConfetti] = useState(false);
  const [liking, setLiking] = useState(null);
  
  const handleClick = () => {
    setShowConfetti(true);
  };

  const addToFavorite = async(id) => {
    setLiking(project);
    setIsFavorite(true);
    setTimeout(() => { 
      if (project.is_favourite === false) {
        handleFavorite(id, 'add');
      }
      handleClick();
      setLiking(null);
    }, 100)
    if (project.is_favourite === false) {
      const { data } = await supabase
        .from('project_feed')
        .update({
          is_favourite: true
        })
        .eq('user_id', project.user_id)
        .eq('project_id', project.project_id);
      console.log("test matched user",data)
    }
  }

  const handlers = useSwipeable({
    onSwipedRight: () => {
      handleSwipeLeft(project);
      addToFavorite(project.project_id);
    },
    onSwipedLeft: () => handleSwipeRight(project),
  });

  useEffect(() => {
    setIsFavorite(project.is_favourite);
  }, [project]);

  return (
    <Box {...handlers}
      className={`${styles.card} ${removing === project? styles.removing: ''} ${liking === project? styles.liking: ''}`}
      sx={{
        bgcolor: darkMode? theme.palette.lightgrey.lightgrey400:"#FFF",
      }}
    >
      <Top
        project={project}
        isFavorite={isFavorite}
        setIsFavorite={setIsFavorite}
        showConfetti={showConfetti}
        setShowConfetti={setShowConfetti}
        addToFavorite={addToFavorite}
      />
      <Divider sx={{ width: "100%" }} />
      <Box className={styles.content}>
        <Image image={project.project_cover_image} />
        <Box className={styles.text}>
          <Typography
            component="h4"
            variant="body1"
            sx={{
              fontSize: "20px",
              fontWeight: "500",
              color: theme.palette.darkgrey.darkgrey600,
              // display: "-webkit-box",
              // WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 1,
              // textOverflow: "ellipsis",
              minHeight: "1.5em",
              width: '100%',
            }}
          >
            {project?.project_title}
          </Typography>
          {/* <Typography
            className={styles.description}
            variant="body1"
            sx={{
              fontSize: "14px",
              color: theme.palette.darkgrey.darkgrey500,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 4,
              textOverflow: "ellipsis",
              minHeight: "6em",
              width: '100%'
            }}
          >
            {project?.project_description}
          </Typography> */}
        </Box>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box className={styles.button}>
        <Button
          variant="contained"
          onClick={() => navigate(`/projects/${project?.project_id}`)}
          sx={{
            textTransform: "capitalize",
            flex: "1",
            p: "10px 1.5rem",
            borderRadius: "4px",
          }}
        >
          View Project
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectCard;
