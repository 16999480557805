import { Box, Chip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { useParams } from "react-router-dom";
import supabase from "../../../../../config/supabaseClient";
import { useTheme } from "../../../../../context/theme-context";

const CommunityStatus = () => {
  const { theme } = useTheme();
  const [status, setStatus] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchStatus = async () => {
      const { data, error } = await supabase
        .from("user_community_status")
        .select()
        .eq("user_id", id)
        .eq("is_active", true);

      if (error) console.log(error, "from error");
      setStatus(data.filter((item) => item.status !== "In Complete"));
    };
    fetchStatus();
  }, [id]);

  return (
    <Box
      className={styles.community}
      sx={{ bgcolor: theme.palette.lightgrey.lightgrey00, width: "100%" }}
    >
      <Typography
        variant="body"
        className={styles.heading}
        sx={{ color: theme.palette.darkgrey.darkgrey600 }}
      >
        Community Status
      </Typography>
      <Box className={styles.tags}>
        {status.map(({ status }, index) => (
          <Chip
            key={index}
            label={status}
            sx={{
              bgcolor:
                status == "Admin"
                  ? theme.palette.primary.main
                  : status == "Verified"
                  ? theme.palette.success.dark
                  : "intial",
              color:
                status == "Admin" || status == "Verified"
                  ? '#FFF'
                  : theme.palette.darkgrey.darkgrey500,
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CommunityStatus;
