import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import styles from "./style.module.css";
import trash from "../../../../../assets/images/trash-03.svg";
import { useTheme } from "../../../../../context/theme-context";

const DeleteConfirmation = ({ isOpenDelete, setIsOpenDelete, deleteSkill }) => {
  const { theme, darkMode } = useTheme();

  const handleClose = () => {
    setIsOpenDelete(false);
  };
  const handleSkillDelete = () => {
    deleteSkill();
    setIsOpenDelete(false);
  };
  return (
    <Modal open={isOpenDelete} onClose={handleClose}>
      <Box
        className={styles.delConfirm}
        sx={{
          width: {
            xs: "320px",
            sm: "360px",
          },
          bgcolor: theme.palette.lightgrey.lightgrey00,
        }}
      >
        <Box className={styles.content}>
          <Box className={styles.frame}>
            <Box className={styles.icon}>
              <img src={trash} alt="delete" />
            </Box>
            <Box className={styles.text}>
              <Typography
                variant="body1"
                component="h4"
                sx={{
                  color: theme.palette.darkgrey.darkgrey600,
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                Do you want delete this skill?
              </Typography>
            </Box>
          </Box>
          <Box className={styles.buttons}>
            <Button
              variant="text"
              onClick={handleClose}
              sx={{
                color: theme.palette.darkgrey.darkgrey300,
                bgcolor: darkMode
                  ? '#3D3D3D'
                  : theme.palette.lightgrey.lightgrey500,
                padding: "8px",
                boxShadow: "none",
                width: "120px",
                fontSize: '16px',
                textTransform: 'capitalize',
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "#FFF",
                bgcolor: theme.palette.danger.red500,
                padding: "8px",
                width: "120px",
                fontSize: '16px',
                textTransform: 'capitalize',

              }}
              onClick={handleSkillDelete}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default DeleteConfirmation;
