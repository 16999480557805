import { Box, Button, Chip, Typography } from "@mui/material";
import React, { useContext } from "react";
import styles from "./style.module.css";
import { UserContext } from "../../../../context/user-context";
import { Navigate, useNavigate } from "react-router-dom";
import { useTheme } from "../../../../context/theme-context";

const Top = () => {
  const { theme } = useTheme();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  return (
    <Box className={styles.top}>
      <Box className={styles.profile}>
        <Box
          className={styles.image}
          sx={{
            backgroundImage: `url(${user?.profile_image})`,
            width: "48px",
            height: "48px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        ></Box>
        <Box className={styles.name}>
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.darkgrey.darkgrey600,
              fontSize: "18px",
              fontWeight: '700',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '1',
              textTransform: 'capitalize',
            }}
          >
            {user?.real_name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.darkgrey.darkgrey300,
            }}
          >
            {user?.country}
          </Typography>
        </Box>
      </Box>
      <Box className={styles.Shouts}>
        <Chip
          label={'View My Card'}
          sx={{
            color: '#FFF',
            background: theme.palette.primary.main,
          }}
          onClick={() => {
            navigate("/profile");
          }}
        />
      </Box>
    </Box>
  );
};

export default Top;
