import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../context/theme-context";

const UnderageDialog = ({isOpen, setIsOpen}) => {
    const { theme } = useTheme();

    const handleClick = () => {
        setIsOpen(false);
    }
    return (
        <Modal 
            open={isOpen}
            onClose={() => setIsOpen(false)}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '25px',
                alignSelf: 'stretch',
                flex: '1 0 0',
                minHeight: '100vh',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '2.5rem 1.5rem',
                    borderRadius: '8px',
                    width: '330px',
                    backgroundColor: theme.palette.lightgrey.lightgrey00,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '2.5rem',
                        // width: '290px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '48px',
                            alignItems: 'flex-start',
                            gap: '8px',
                            borderRadius: '100px',
                            background: 'linear-gradient(160deg, #0F5FF7 9.58%, #9373FF 51.07%, #E81CFF 93.44%)',
                            color: theme.palette.lightgrey.lightgrey00,
                            
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '20px 1.5px 20px 1.5px',
                            }}
                        >
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    leadingTrim: 'both',
                                    textEdge: 'cap',
                                    fontSize: '48px',
                                    fontWeight: '700',
                                    lineHeight: '48px', 
                                }}
                            >
                                13+
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            // m: '20px auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px',
                            alignSelf: 'stretch',
                        }}
                    >
                        <Typography variant="body1"
                            color={theme.palette.darkgrey.darkgrey400}
                            sx={{
                                textAlign:'center'
                            }}
                        >
                            <span style={{color: theme.palette.darkgrey.darkgrey600}}>Oh shoot! </span>LYNX is only for creators 13 years old and over. Please check back on your 13th birthday 🙂
                        </Typography>
                    </Box>
                    <Button variant="contained"
                        onClick={handleClick}
                        fullWidth
                    >
                        Okay!
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default UnderageDialog;
