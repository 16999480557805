import { Box } from "@mui/material";
import Incomplete from "./incomplete";
import { useTheme } from "../../context/theme-context";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/user-context";
import Populating from "./populating";

const Discover = () => {
    const { theme, darkMode } = useTheme();
    const { user } = useContext(UserContext);
    
//     useEffect(() => {
//         console.log(user);    
//     },[])

    return (
        <Box
            sx={{
                width: '100%',
                bgcolor: theme.palette.lightgrey.lightgrey500,
                minHeight: '100vh',
                paddingBottom: '5rem',
            }}
        >
        {!user?.is_profile_complete && <Incomplete /> }
        </Box>
    );
}

export default Discover;