import { Box } from "@mui/material";
import styles from "./style.module.css";
import BasicTabs from "./components/tabs";
import { useTheme } from "../../../context/theme-context";
import AppModal from "./components/tabs/hosted/app-modal";
import { useContext, useEffect, useState } from "react";
import supabase from "../../../config/supabaseClient";
import { UserContext } from "../../../context/user-context";
import { ManageProjectsContext } from "../../../context/manage-projects-context";
const ProjectManage = () => {
  const { theme } = useTheme();

  return (
    <Box
      className={styles.projectManage}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey500,
      }}
    >
      <BasicTabs />
    </Box>
  );
};

export default ProjectManage;
