import moment from "moment";
import { createContext, useState } from "react";
import UseLocalStorageState from "../hooks/userLocalStorage";
import { useNavigate } from "react-router-dom";

export const ReversedRegistrationContext = createContext(null);
const ReversedRegistrationContextProvider = (props) => {
  const [creatorTypes, setCreatorTypes] = useState([]);
  const [collaboratorsTypes, setCollaboratorsTypes] = useState([]);
  const [country, setCountry] = useState("United States");
  const [postalCode, setPostalCode] = useState("");
  const [birthdate, setBirthdate] = useState("MM-DD-YYYY");
  const [pronounce, setPronounce] = useState("");
  const [completedInfo, setCompletedInfo] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState('+1');

  const [reversedRegistrationData, setReversedRegistrationData] =
    UseLocalStorageState("reversedRegistrationData", {});
  const [shouldRegisterFromReversed, setShouldRegisterFromReversed] =
    UseLocalStorageState("shouldRegisterFromReversed", false);
  const navigate = useNavigate();

  const handleDataComplete = async () => {
    setReversedRegistrationData({
      country,
      pronounce,
      postalCode,
      birthdate,
      collaboratorsTypes,
      creatorTypes,
      phoneNumber,
      countryCode
    });
    setShouldRegisterFromReversed(true);
    // await

    // console.log('reversedRegistrationData', reversedRegistrationData);
  };

  const contextValue = {
    completedInfo,
    setCompletedInfo,
    creatorTypes,
    setCreatorTypes,
    country,
    setCountry,
    postalCode,
    setPostalCode,
    pronounce,
    setPronounce,
    birthdate,
    collaboratorsTypes,
    setCollaboratorsTypes,
    setBirthdate,
    handleDataComplete,
    phoneNumber,
    setPhoneNumber,
    countryCode,
    setCountryCode
  };

  return (
    <ReversedRegistrationContext.Provider value={contextValue}>
      {props.children}
    </ReversedRegistrationContext.Provider>
  );
};
export default ReversedRegistrationContextProvider;
