import { Box, Typography } from "@mui/material";
import BasicTabs from "./components/tabs";
import styles from './style.module.css';
import { useTheme } from "../../context/theme-context";
import { useContext, useEffect } from "react";
import { ManageProjectsContext } from "../../context/manage-projects-context";

const Projects = ({user}) => {
    const { theme } = useTheme();
    const { fetchHostedProjects, fetchLikedProjects } = useContext(ManageProjectsContext);

    useEffect(() => {
        fetchHostedProjects(user);
        fetchLikedProjects(user);
    },[user])

    return (
        <Box className={styles.projects}>
            <Typography variant="body1" 
                sx={{
                    fontSize: '1.5rem',
                    fontWeight: '500',
                    textAlign: 'centerd',
                    display: 'flex',
                    alignSelf: 'stretch',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: theme.palette.darkgrey.darkgrey600,
                }}
            >Projects</Typography>
            <BasicTabs />
        </Box>
    );
}; 

export default Projects;