import { Box, Typography } from "@mui/material";
import { useTheme } from "../../../../context/theme-context";
import styles from './style.module.css';

const BlueHeader = () => {
    const { theme, darkMode } = useTheme();
    
    return (        
        <Box className={styles.blue}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                alignSelf: 'stretch',
            }}
        >
            <Typography variant='body1'
                sx={{
                    fontSize: '72px',
                    fontWeight: '700',
                    color: darkMode? '#FFF' :theme.palette.secondary.bg_blue,
                }}
            >Discover</Typography>
        </Box>
    );
};

export default BlueHeader;