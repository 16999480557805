import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/theme-context";
import RatingsHeader from "./components/header";
import RatingsContent from "./components/text";
import supabase from "../../../config/supabaseClient";
import { UserContext } from "../../../context/user-context";
import { SettingsContext } from "../../../context/settings-context";
import Angry from "./components/angry";
import Happy from "./components/happy";
import Sad from "./components/sad";
import BackButton from "../back-button";

const UserRatings = () => {
    const { theme } = useContext(ThemeContext);
    const { userScores, recalculateScore, fetchUserScores } = useContext(SettingsContext);
    useEffect(() => {
        async function fetchScore() {
            if (userScores == null) {
                await recalculateScore();
                await fetchUserScores();   
            }
        } 
        console.log(userScores);
        fetchScore();
    },[])

    return (
        <Box
            sx={{
                background: theme.palette.lightgrey.lightgrey600,
                minHeight: '100vh',
                width: '100%',
                marginBottom: '5rem'
            }}
        >
            <RatingsHeader />
            {/* <Angry />
            <Happy />
            <Sad /> */}
            <RatingsContent userScores={userScores} />
        </Box>
    )
}

export default UserRatings;