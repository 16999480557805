import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import styles from "./style.module.css";
import { useTheme } from "../../../../../../context/theme-context";
import supabase from "../../../../../../config/supabaseClient";

const Basics = (props) => {
  const { theme, darkMode } = useTheme();
  const [categories, setCategories] = useState([
    "Brand",
    "Creator",
    "Media",
    "Cause",
  ]);
  const {
    title,
    setTitle,
    primaryEntity,
    setPrimaryEntity,
    description,
    setDescription,
    country,
    setCountry,
    selectedCategory,
    setSelectedCategory,
    selectedCreatorTypes,
    setSelectedCreatorTypes,
    selectedAgeRanges,
    setSelectedAgeRanges,
    locationType,
    setLocationType,
  } = props;
  const [creatorTypes, setCreatorTypes] = useState([]);

  const handleChangeCategory = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  const handleTitle = (event) => {
    setTitle(event.target.value);
  };
  const handlePrimaryEntity = (event) => {
    setPrimaryEntity(event.target.value);
  };
  const handleDescription = (event) => {
    setDescription(event.target.value);
  };
  const handleCountry = (event) => {
    setCountry(event.target.value);
  };

  useEffect(() => {
    const fetchCreatorTypes = async () => {
      const { error, data } = await supabase
        .from("registeration_collaborator_types")
        .select("*");
      if (error) {
        console.log(error);
      }

      setCreatorTypes(data);
      console.log(data, "from project form");
    };
    fetchCreatorTypes();
  }, []);
  const handleCreatorTypeSelect = (event, newValue) => {
    setSelectedCreatorTypes(newValue);
    console.log(newValue, "from new value");
    console.log(selectedCreatorTypes, "creator type change");
    
  };
  return (
    <Box className={styles.basics}>
      <Box className={styles.content}>
        <Typography
          variant="h4"
          sx={{
            color: theme.palette.darkgrey.darkgrey600,
            fontSize: "20px",
            fontWeight: "500",
          }}
        >
          Basics
        </Typography>
      </Box>
      <Box className={styles.inputField}>
        <Autocomplete
          sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
          options={categories}
          getOptionLabel={(option) => option}
          value={selectedCategory}
          onChange={handleChangeCategory}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Please choose your category"
              variant="outlined"
            />
          )}
        />
      </Box>
      <Box className={styles.inputField}>
        <TextField
          sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
          label="Company, group or organization hosting the project"
          variant="outlined"
          fullWidth
          value={primaryEntity}
          onChange={handlePrimaryEntity}
        />
      </Box>
      <Box className={styles.inputField}>
        <TextField
          sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
          label="Give your project a title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={handleTitle}
        />
      </Box>
      <Box className={styles.options}>
        <Box className={styles.options}>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              color: theme.palette.darkgrey.darkgrey600,
              fontWeight: "500",
            }}
          >
            Project description
          </Typography>
          <TextareaAutosize
            style={{
              width: "100%",
              minHeight: 90,
              maxWidth: "100%",
              minWidth: "100%",
              borderRadius: "4px",
              border: "1px solid ",
              borderColor: darkMode
                ? theme.palette.lightgrey.lightgrey300
                : theme.palette.lightgrey.lightgrey800,
              outlineColor: theme.palette.primary.main,
              padding: "12px 13px",
              fontFamily: "Pretendard, sans-serif",
              fontSize: "medium",
              backgroundColor: darkMode && "#3D3D3D",
              color: darkMode && "#D9D9D9",
            }}
            value={description} // Controlled component: Set value from state
            onChange={handleDescription} // Handle changes
            placeholder="Tell us a little about your project"
          />
        </Box>
        <Box className={styles.category}>
          <Typography
            variant="body1"
            color={theme.palette.darkgrey.darkgrey600}
            fontWeight={500}
          >
            Project location
          </Typography>
          <RadioGroup
            row
            className={styles.radioGroup}
            sx={{
              gap: "12px",
              color: theme.palette.darkgrey.darkgrey300,
              "& .css-ahj2mt-MuiTypography-root": {
                fontSize: "14px",
              },
            }}
            value={locationType}
            onChange={(e) => setLocationType(e.target.value)}
          >
            <FormControlLabel
              value="Local"
              control={<Radio size="meduim" />}
              label="Local"
              fontSize="14px"
            />
            <FormControlLabel
              value="Remote"
              control={<Radio size="meduim" />}
              label="Remote"
            />
            <FormControlLabel
              value="Hybrid"
              control={<Radio size="meduim" />}
              label="Hybrid"
            />
          </RadioGroup>
        </Box>
        {locationType !== "Remote" && 
          <Box className={styles.country}>
            <TextField
              sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
              label="Your Location"
              variant="outlined"
              fullWidth
              value={country}
              onChange={handleCountry}
            />
          </Box>
        }

        <Box className={styles.inputField}>
          <Typography
            variant="body1"
            color={theme.palette.darkgrey.darkgrey600}
            fontWeight={500}
          >
            Creator Types
          </Typography>
          <Autocomplete
            sx={{
              bgcolor: theme.palette.lightgrey.lightgrey00,
              "& .MuiChip-label": {
                paddingRight: '10px !important',
              }
            }}
            options={creatorTypes}
            getOptionLabel={(option) => option.collaborator_category}
            value={selectedCreatorTypes}
            onChange={handleCreatorTypeSelect}
            fullWidth
            multiple // This enables multi-select
            renderInput={(params) => (
              <TextField {...params} label="" variant="outlined" />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Basics;
