import { Box, Button, Chip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import { Clear, ClearRounded } from "@mui/icons-material";
// import { SearchContext } from "../../../../context/search-context";
import { useTheme } from "../../../../../context/theme-context";
import Divider from '@mui/material/Divider';
import ProjectFiltersContext from "../../../../../context/project-filters-context";
import supabase from "../../../../../config/supabaseClient";

const Filters = ({setIsModalOpen}) => {
  const {filters, handleRemoveFilter} = useContext(ProjectFiltersContext);
  
  const { theme, darkMode } = useTheme();

  const handleDelete = (filter) => {
    handleRemoveFilter(filter)
  };
  const handleClear = () => {
    // clear all filters
  };
  const handleClick = () => {};

  const handleSeeMoreClick = () => {
    setIsModalOpen(true);
  };

  return (
    <Box className={styles.filter}>
      <Box className={styles.filters}>
        {filters.map((filter, index) => (
          index <16 &&
          <Chip
            key={filter.value}
            label={filter.name}
            onClick={handleClick}
            onDelete={()=>handleDelete(filter)}
            deleteIcon={<ClearRounded />}
            sx={{
              gap: '8px',
              padding: '6px 8px 6px 12px',
              fontWeight: 'normal',
              "& .MuiChip-deleteIcon": {
                fontSize: '18px',
              }
            }}
          />
        ))}
        {filters.length === 0 ?
        <Button
            variant="text"
            onClick={handleSeeMoreClick}
            sx={{
              textTransform: "capitalize",
              fontSize: "14px",
              p: '0',
              color: darkMode && theme.palette.primary.purpel100,
            }}
          >
          Add filters
        </Button> : filters.length !== 0 && filters.length < 16 &&
        <Button
          variant="text"
          onClick={handleSeeMoreClick}
          sx={{
            textTransform: "capitalize",
            fontSize: "14px",
            p: '0',
            color: darkMode && theme.palette.primary.purpel100,
          }}
        >
        See More
        </Button>
        }
      </Box>    
      <Divider sx={{width: '100%'}}/>
    </Box>
  );
};

export default Filters;
