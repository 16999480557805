import { Box, Chip, IconButton } from "@mui/material";
import styles from "./style.module.css";
import image from "../../../../../../../assets/images/project-details-header-image.png";
import { ArrowBack } from "@mui/icons-material";
import { useTheme } from "../../../../../../../context/theme-context";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Header = ({ image, category }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    // console.log(pathname, 'path name from project details');    
  }, []);


  return (
    <Box className={styles.header}>
      <Box className={styles.image}>
        <img src={image} alt={"header image"} />
      </Box>
      {/* <Box className={styles.overlay}></Box> */}
      <Box className={styles.back}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack sx={{ color: "#FFF" }} />
        </IconButton>
      </Box>
      <Box className={styles.options}>
        <Chip
          label={category}
          sx={{
            bgcolor: category === "Brand" && "#EC7508" ||
              category === "Cause" && "#05B240" ||
              category === "Creator" && "#09B999" ||
              category === "Media" && "#B90988" ,
            color: "#FFF",
          }}
        />
      </Box>
    </Box>
  );
};

export default Header;
