import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Hosted from '../hosted';
import Liked from '../liked';
import { useTheme } from '../../../../context/theme-context';
import { ManageProjectsContext } from '../../../../context/manage-projects-context';
import Applications from '../../../applications';
import { useSearchParams } from 'react-router-dom';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: '1rem' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const { theme, darkMode } = useTheme();
  const { likedProjects, hostedProjects, applications } = React.useContext(ManageProjectsContext);
  const { pathname } = window.location;
  const [isPublic, setIsPublic] = React.useState(pathname.includes('creators'));
  const [searchParams] = useSearchParams();
  
  const [tabName, setTabName] = React.useState(searchParams.get('projects-tab'));
  console.log(tabName, "tab Name test");

  React.useEffect(() => {
    if (tabName === "liked") {
      setValue(2);
    }else if (tabName === "applications") {
      setValue(1);
    }else if (tabName === "hosted") {
      setValue(0);
    }
  }, [tabName])
  const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered
                sx={{
                    '& .MuiTab-root': {
                        color: darkMode && theme.palette.darkgrey.darkgrey400,
                        textTransform: 'capitalize',
                        '&.Mui-selected': {
                            color: darkMode && theme.palette.darkgrey.darkgrey500,
                        },
                    },
                    
                }}
            >
            <Tab sx={{textTransform: 'capitalize'}} label={`Hosted(${hostedProjects?.length || 0})`} {...a11yProps(0)} />
            {!isPublic && <Tab sx={{ textTransform: 'capitalize' }} label={`Applications(${applications?.length || 0})`} {...a11yProps(1)} />}
            <Tab sx={{textTransform: 'capitalize'}} label={`Liked(${likedProjects?.length || 0})`} {...a11yProps(isPublic? 1: 2)} />
            </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
            <Hosted />
        </CustomTabPanel>
        {!isPublic && <CustomTabPanel value={value} index={1}>
            <Applications />
        </CustomTabPanel>}
        <CustomTabPanel value={value} index={isPublic? 1 : 2 }>
            <Liked />
        </CustomTabPanel>
        
        </Box>
    );
}