import { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./user-context";
import supabase from "../config/supabaseClient";
import { sendProjectDeletedemail } from "../helpers/emails";
import { DiscoverContext } from "./discover-context";

export const ManageProjectsContext = createContext();

const ManageProjectsContextProvider = (props) => {
  const [projects, setProjects] = useState([]);
  const [hostedProjects, setHostedProjects] = useState([]);
  const [likedProjects, setLikedProjects] = useState([]);
  const [applications, setApplications] = useState([]);
  const { user } = useContext(UserContext);
  const { setData } = useContext(DiscoverContext);
  const [projectApplicants, setProjectApplicants] = useState([]);
  const [editNavigate, setEditNavigate] = useState(false);
  const [isAppModalOpen, setIsAppModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setIsDrawerOpen(newOpen);
  }
  
  const handleFavorite = async (id, state) => {
    if (state === "add") {
      // case state 'add' add this project to favorites
      const { data, error } = await supabase.from("projects_favourite").insert([
        {
          project_id: id,
          user_id: user.id,
        },
      ]);
      console.log('test add');

      if (error) {
        console.log(error);
      } else {
        console.log("project added to favourites successfuly");
        setData((prev) => (
          prev.map(item => (
              item.project_id === id ? { ...item, is_favourite: true } : item
          )))
        )
      }
    } else if (state === "remove") {
      // case state 'remove' remove this project from favorites
      const { data, error } = await supabase
        .from("projects_favourite")
        .delete()
        .eq("project_id", id)
        .eq("user_id", user?.id);

      console.log('test delete');
      if (error) {
        console.log(error);
      } else {
        console.log("project deleted from favourites successfuly");
        setData((prev) => (
          prev.map(item => (
              item.project_id === id ? { ...item, is_favourite: false } : item
          )))
        )
      }
    }
  };

  const fetchFavorite = async (id) => {
    // check if this project is in user favorites
    if (user && user?.id) {
      const { data, error } = await supabase
        .from("projects_favourite")
        .select("*")
        .eq("project_id", id)
        .eq("user_id", user?.id);

      return data.length > 0;
    }
    return false;
  };

  const handleDeleteApplicants = async (item) => {
    const { data, error } = await supabase
      .from("projects_applications")
      .delete()
      .eq("project_id", item.project_id)
      .eq("user_id", item.user_id);
  };

  const handleRemoveApplication = async (project) => {
    const { data, error } = await supabase
      .from("projects_applications")
      .delete()
      .eq("project_id", project.project_id)
      .eq("user_id", project.user_id);  
  };

  const handleProjectDelete = async (project) => {
    const { data, error } = await supabase
      .from("projects")
      .update({status: 'deleted'})
      .eq("id", project.id);

    console.log(project, "from project delete");
  
    await sendProjectDeletedemail(project.id);
  };

  const fetchProjectApplicants = async (project) => {
    if (project) {
      const { data, error } = await supabase
        .from("project_applications_with_user_info")
        .select("*")
        .eq("project_id", project.id);

      setProjectApplicants(data || []);
      console.log(data, "from hosted Applications", project.id);
    }
  };

  const fetchLikedProjects = async (user) => {
    const { data, error } = await supabase
      .from("user_favorite_projects")
      .select()
      .eq("user_id", user?.id);
    setLikedProjects(data);
  };
  
  const fetchHostedProjects = async (user) => {
    const { data, error } = await supabase
      .from("projects")
      .select()
      .eq("user_id", user?.id);
    setHostedProjects(data);
  };
  
  const fetchProjects = async () => {
    const { data, error } = await supabase
      .from("project_info_with_total_applications")
      .select()
      .eq("user_id", user?.id)
    if (error) {
      console.error('Error fetching projects:', error.message);
      return;
    }

    // Filter out deleted records manually
    const filteredData = data.filter(project => project.status !== 'deleted');
    
    setProjects(filteredData);
    console.log(filteredData, "from projects");
  };

  const fetchApplications = async () => {
    const { data, error } = await supabase
      .from("project_applications_with_project_info")
      .select("*")
      .eq("user_id", user.id);

    setApplications(data || []);
    console.log(data, "from my hosted projects");
  };

  useEffect(() => {
    if (user) {
      fetchProjects();
      fetchApplications();
    }
  }, [user]);

  const contextValue = {
    projects,
    hostedProjects,
    likedProjects,
    setLikedProjects,
    setProjects,
    applications,
    setApplications,
    projectApplicants,
    setProjectApplicants,
    fetchProjectApplicants,
    handleProjectDelete,
    handleRemoveApplication,
    handleDeleteApplicants,
    fetchProjects,
    handleFavorite,
    fetchFavorite,
    fetchApplications,
    editNavigate,
    setEditNavigate,
    isAppModalOpen,
    setIsAppModalOpen,
    isDrawerOpen,
    setIsDrawerOpen,
    toggleDrawer,
    fetchHostedProjects,
    fetchLikedProjects,
  };

  return (
    <ManageProjectsContext.Provider value={contextValue}>
      {props.children}
    </ManageProjectsContext.Provider>
  );
};

export default ManageProjectsContextProvider;
