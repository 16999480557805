import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import styles from "./styles.module.css";
import UseLocalStorageState from "../../../hooks/userLocalStorage";
import profilePicture from "../../../assets/images/image-plus.svg";
import ProfileTabs, { a11yProps } from "./profile-tabs";
import ProfileImage from "./profile-image";
import DisplayTabs from "./profile-tabs/display-tabs";
import ProfileName from "./profile-name";
import { UserContext } from "../../../context/user-context";
import UploadProfileImage from "./profile-image/upload-profile-image";
import { ProfileImageContext } from "../../../context/profile-image-context";
import EditProfileImage from "./profile-image/edit";
import DeleteProfileImage from "./profile-image/delete";
import { SkillImageContext } from "../../../context/skill-image-context";
import EditSkillImage from "../my-skills/components/skill-cover-image.js/edit";
import DeleteSkillImage from "../my-skills/components/skill-cover-image.js/delete";
import UploadSkillImage from "../my-skills/components/skill-cover-image.js/upload-profile-image";
import { SkillsBarContext } from "../../../context/skills-bar-context";
import { LoadingContext } from "../../../context/loading-context";
import Loading from "../../../components/loading/default";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTheme } from "../../../context/theme-context";
import { Inbox, Mail, MoreHoriz } from "@mui/icons-material";
import { ManageProjectsContext } from "../../../context/manage-projects-context";
import drawerIconDark from "../../../assets/images/Shout Out Dark.svg";
import drawerIconLight from "../../../assets/images/Shout Out Light.svg";
import { useAuth0 } from "@auth0/auth0-react";

const MyProfile = () => {
  const { theme, darkMode } = useTheme();
  const { selectedImage, isModalOpen, setIsModalOpen, isDeleteOpen } =
    useContext(ProfileImageContext);
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0();
  const {
    selectedSkillImage,
    isModalSkillOpen,
    setIsModalSkillOpen,
    isDeleteSkillImageOpen,
  } = useContext(SkillImageContext);
  const { isDrawerOpen, setIsDrawerOpen, toggleDrawer } = useContext(
    ManageProjectsContext
  );
  const [value, setValue] = UseLocalStorageState("myprofile-bar", 1);
  const { user } = useContext(UserContext);
  const { activeSkill, setActiveSkill } = useContext(SkillsBarContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [hideIndcator, setHideIndcator] = UseLocalStorageState(
    "hideIndcator",
    false
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tabName, setTabName] = useState(searchParams.get("tab"));
  console.log(user, "from user");
  console.log(tabName, "tab Name test");

  useEffect(() => {
    if (tabName === "shoutouts") {
      setValue(3);
      setHideIndcator(true);
    } else if (tabName === "projects") {
      setValue(2);
    } else if (tabName === "skills") {
      setValue(1);
    } else if (tabName === "about-me") {
      setValue(0);
    }
  }, [tabName]);
  useEffect(() => {}, [user]);
  useEffect(() => {
    const redirectedUrl = localStorage.getItem("redirectedUrl");
    if (redirectedUrl && !isAuthLoading && isAuthenticated) {
      localStorage.removeItem("redirectedUrl");
      navigate(redirectedUrl);
    }
  }, [user]);
  let profile_image = user?.profile_image;
  useEffect(() => {
    profile_image = user?.profile_image;
  }, [user]);

  let skill_cover_image = activeSkill?.skill_cover_image;
  useEffect(() => {
    skill_cover_image = activeSkill?.skill_cover_image;
  }, [activeSkill]);

  const data = {
    value,
    setValue,
  };
  const modal = {
    isModalOpen,
    setIsModalOpen,
    profile_image,
  };
  const pImageData = {
    profile_image,
    setIsModalOpen,
  };

  const SkillImageModal = {
    isModalSkillOpen,
    setIsModalSkillOpen,
    skill_cover_image,
  };

  const clearSearchParams = () => {
    // Create a new URL object based on the current URL
    const url = new URL(window.location.href);

    // Clear all search parameters
    url.search = "";

    // Use history.replaceState to update the URL without reloading the page
    window.history.replaceState({}, document.title, url);
  };

  const handleShutClick = () => {
    setValue(3);
    setHideIndcator(true);
    clearSearchParams();
  };

  const DrawerList = (
    <Box role="presentation" onClick={toggleDrawer(false)}>
      <Box
        sx={{
          display: "flex",
          alignSelf: "stretch",
          justifyContent: "center",
          padding: "8px 16px 24px",
        }}
      >
        <Box
          sx={{
            width: "54px",
            height: "4px",
            borderRadius: "32px",
            background: theme.palette.darkgrey.darkgrey500,
          }}
        ></Box>
      </Box>
      <List sx={{ p: "8px 0px 24px" }}>
        <ListItem sx={{ p: "8px 0px" }}>
          <ListItemButton
            onClick={handleShutClick}
            sx={{ paddingLeft: "24px" }}
          >
            <ListItemIcon sx={{ minWidth: "50px" }}>
              {darkMode ? (
                <img src={drawerIconDark} height={"24px"} alt={"rock"} />
              ) : (
                <img src={drawerIconLight} height={"24px"} alt={"rock"} />
              )}
            </ListItemIcon>
            <ListItemText
              sx={{ color: theme.palette.darkgrey.darkgrey500 }}
              primary={"Shout Outs"}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!user) {
    return <Loading />;
  }

  return (
    <Box className={styles.parent}>
      <Box
        className={styles.cardDetails}
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey600,
        }}
      >
        {selectedImage && <EditProfileImage />}
        {isDeleteOpen && <DeleteProfileImage />}
        {selectedSkillImage && (
          <EditSkillImage
            activeSkill={activeSkill}
            setActiveSkill={setActiveSkill}
          />
        )}
        {isDeleteSkillImageOpen && <DeleteSkillImage />}
        <Box className={styles.myCardDetails}>
          <Box className={styles.userDetails}>
            <Box className={styles.details}>
              <ProfileImage data={pImageData} />
              <ProfileName />
            </Box>
          </Box>
          <Box className={styles.tabs}>
            <ProfileTabs
              data={data}
              isIndecatorHide={hideIndcator}
              setHideIndcator={setHideIndcator}
            />
            <IconButton onClick={toggleDrawer(true)}>
              <MoreHoriz sx={{ color: "#FFF" }} />
            </IconButton>
            <Drawer
              anchor="bottom"
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
              sx={{
                '& .MuiPaper-root':{
                  maxWidth:{
                    xs: '100%',
                  },
                  md: {
                    maxWidth: '400px !important',
                    left: '50% !important',
                    transform: 'translateX(-50%) !important',
                  } 
                }
              }}
            >
              {DrawerList}
            </Drawer>
          </Box>
        </Box>
        <DisplayTabs value={value} />
      </Box>
      <UploadProfileImage modal={modal} />
      <UploadSkillImage modal={SkillImageModal} />
    </Box>
  );
};

export default MyProfile;
