import { Box, Button, Typography } from '@mui/material';
import styles from './style.module.css'
import Info from '../../../../../components/info';
import { useContext, useEffect } from 'react';
import { ThemeContext } from '../../../../../context/theme-context';
import AM from '../../../../../assets/images/kind=A, Size=M.png';
import DM from '../../../../../assets/images/kind=D, Size=M.png';
import AMM from '../../../../../assets/images/kind=A-, Size=M.png';
import { SettingsContext } from '../../../../../context/settings-context';
import { useInfoBoxs } from '../../../../../context/info-boxs-context';
import BackButton from '../../../back-button';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import { server_link } from '../../../../../constants';

const RatingsHeader = () => {
    const { theme } = useContext(ThemeContext);
    const { score, recalculateScore } = useContext(SettingsContext);
    const { infoBoxes } = useInfoBoxs();
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/settings');
    }
    return (
        <Box className={styles.headerParent}>
            <Box sx={{
                position: 'absolute',
                left: '20px',
                top: '30px'
            }}>
                <Button className={styles.backButton}
                    startIcon={<ChevronLeft />}
                    onClick={handleClick}
                sx={{
                    color: "#FFF",
                    fontSize: '14px',
                    fontWeight: '400',
                }}
                >Back</Button>
            </Box>
            <Box className={styles.userDetails}>
                <Box className={styles.myRatingsCard}>
                    <Box className={styles.content}>
                        <Box className={styles.title}>
                            <Typography
                                variant='body1'
                                sx={{
                                    color: '#FFF',
                                    fontSize: '18px',
                                    fontWeight: '700',                                }}
                            >
                            My Creator Rating
                            </Typography>
                            {infoBoxes.map((item) => {
                                if (item.name === 'creatorRatingInfoBox') {    
                                    return <Info
                                    heading={item.title}
                                    content={item.content}
                                    />
                                }
                            })}
                        </Box>
                        <Button variant='contained'
                            onClick={recalculateScore}
                            sx={{
                                bgcolor: theme.palette.secondary.main,
                                padding: '8px 16px',
                                fontSize: '14px',
                                fontWeight: '700',
                                letterSpacing: '1px'
                            }}
                        > RECALCULATE</Button>
                    </Box>
                    <Box
                        sx={{
                            maxWidth: '140px',
                            height: '140px',
                            flexShrink: '0',
                        }}
                    >
                        <img style={{ width: '100%', height:'100%' }} src={`${server_link}${score}.png`} alt= 'rate' />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default RatingsHeader;