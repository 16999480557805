import { Box } from "@mui/material";
import styles from "./style.module.css";
import Card from "./card";
import ProjectFiltersContext from "../../../../../context/project-filters-context";
import { useContext } from "react";
import LoadingButton from "../../../../../components/loading/button";

const GridResult = ({ projects }) => {
  const { loading } = useContext(ProjectFiltersContext);

  return (
    <Box className={styles.result} >
      {projects.map((item) => (
        <Card key={item.project_id} project={item} />
      ))}
      {loading &&
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
          <LoadingButton />
        </Box>
      }
    </Box>
  );
};

export default GridResult;
