import { Box, Chip, Typography } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import styles from "./style.module.css";
import image from "../../../../../../../assets/images/profileImage.png";
import { useTheme } from "../../../../../../../context/theme-context";
import { Close, Favorite, FavoriteBorderOutlined, FavoriteOutlined } from "@mui/icons-material";
import { ManageProjectsContext } from "../../../../../../../context/manage-projects-context";
import { DiscoverContext } from "../../../../../../../context/discover-context";
import { UserContext } from "../../../../../../../context/user-context";
import supabase from "../../../../../../../config/supabaseClient";
import ConfettiComponent from "../../../../../../../components/common/FireWorks";
import ConfettiButton from "../../../../../../../components/common/Confetti/confettiButton";

const Top = ({
  AddFav,
  removeFav,
  project,
  isFavorite,
  setIsFavorite,
  showConfetti,
  setShowConfetti }) => {
  const { theme } = useTheme();
  const { handleCloseProject } = useContext(DiscoverContext);
  const { user } = useContext(UserContext);
  const containerRef = useRef(null);

  const handleShoutOutClick = async () => {
    if (isFavorite) {
      cancelShoutOut();
      return;
    }
    AddFav();
  };
  const cancelShoutOut = async () => {
    removeFav();
  };


  return (
    <Box className={styles.top}>
      <Box className={styles.profile}>
        <Box
          className={styles.image}
          sx={{
            backgroundImage: `url(${project.profile_image})`,
            width: "48px",
            height: "48px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        ></Box>
        <Box className={styles.name}>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.darkgrey.darkgrey600,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 1,
              textOverflow: "ellipsis",
            }}
          >
            {project.real_name}
          </Typography>
          {/* <Typography
            variant="body2"
            sx={{
              color: theme.palette.darkgrey.darkgrey300,
            }}
          >
            {project.nick_name}
          </Typography> */}
        </Box>
        <Box
          className={styles.options}
          sx={{
            "& .css-ixzp6w-MuiChip-root": {
              height: "auto",
            },
          }}
        >
          <Box
            ref={containerRef}
            sx={{
              position: "relative",
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <ConfettiButton
              showConfetti={showConfetti}
              setShowConfetti={setShowConfetti}
              containerRef={containerRef}
            />
            {isFavorite ? (
              <Favorite
                sx={{ fill: theme.palette.primary.main }}
                onClick={handleShoutOutClick}
              />
            
            ) : (
                <FavoriteBorderOutlined
                  sx={{ fill: theme.palette.primary.main }}
                  onClick={handleShoutOutClick}
                />   
            )}
          </Box>
          <Close
            onClick={()=> handleCloseProject(project)}
            sx={{
              color: theme.palette.darkgrey.darkgrey400,
              cursor: 'pointer',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Top;
