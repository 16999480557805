// ConfettiComponent.js
import React from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

const ConfettiComponent = ({ isConfettiVisible }) => {
	const { width, height } = useWindowSize();

	return (
		<>
			{ isConfettiVisible && 
				<div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none', zIndex: 1000 }}>
					<Confetti width={width} height={height} />
				</div>
			}
		</>
  );
};

export default ConfettiComponent;
