import { Box, Typography, Link, Button, Chip } from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import moment from "moment";
import { useTheme } from "../../../../../../context/theme-context";

const AboutSkill = ({ skill }) => {
  const { theme, darkMode } = useTheme();
  const trainingYears = moment().diff(skill.started_training_date, "years");

  if (!skill) return <div></div>;

  return (
    <Box
      className={styles.skillsCommunityStatus}
      sx={{ background: theme.palette.lightgrey.lightgrey00 }}
    > 
      <Box className={styles.skillsItem}>
        <Typography
          variant="body1"
          className={styles.heading}
          sx={{
            color: theme.palette.darkgrey.darkgrey600,
            fontSize: '18px',
            fontWeight: '700',
          }}  
        >
          My Experience
        </Typography>
        <Box className={styles.tags}>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.darkgrey.darkgrey400,
              width: '100%',
            }}
          >
            {skill.description}
          </Typography>
        </Box>
      </Box>
      <Box className={styles.skillsItem}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            variant="body1"
            className={styles.heading}
            sx={{
              color: theme.palette.darkgrey.darkgrey600,
              fontSize: '18px',
              fontWeight: '700',
            }}  
          >
            Portfolio Link
          </Typography>
        </Box>
        <Box className={styles.tags}>
          <Link
            href={skill?.link}
            variant="body2"
            sx={{
              color: theme.palette.darkgrey.darkgrey400,
            }}
          >
            {skill.link}
          </Link>
        </Box>
      </Box>
      <Box className={styles.skillsItem}>
        <Typography
          variant="body1"
          className={styles.heading}
          sx={{
            color: theme.palette.darkgrey.darkgrey600,
            fontSize: '18px',
            fontWeight: '700',
          }}
        >
          Skill Level
        </Typography>
        <Box className={styles.tags}>
          <Chip
            label={skill.level}
            sx={{
              bgcolor: !darkMode && theme.palette.darkgrey.darkgrey400,
              color: !darkMode && theme.palette.lightgrey.lightgrey00,
            }}
          />
        </Box>
      </Box>
      <Box className={styles.skillsItem}>
        {trainingYears ?? 0 !==
          0 ? (
          <>
            <Typography
              variant="body1"
              className={styles.heading}
                sx={{
                  color: theme.palette.darkgrey.darkgrey600,
                  fontSize: '18px',
                  fontWeight: '700',
                }}
            >
              Experience
            </Typography>
            <Box className={styles.tags}>
              <Chip
                label={`${trainingYears} Years`}
                sx={{
                  color: !darkMode && theme.palette.lightgrey.lightgrey00,
                  bgcolor: theme.palette.secondary.main,
                }}
              />
            </Box>
          </>
        ) : (
          <Chip
            label={"0 Year"}
            sx={{
              color: theme.palette.darkgrey.darkgrey500,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default AboutSkill;
