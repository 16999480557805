import { MoreHoriz } from "@mui/icons-material";
import { Box } from "@mui/material";
import styles from "./style.module.css";
import { useTheme } from "../../../../../../../../../../context/theme-context";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useContext, useState } from "react";
import supabase from "../../../../../../../../../../config/supabaseClient";
import { useNavigate } from "react-router-dom";
import { ManageProjectsContext } from "../../../../../../../../../../context/manage-projects-context";

const Options = ({setStatus, item}) => {
    const { theme } = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const { projectApplicants, setProjectApplicant } = useContext(ManageProjectsContext);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleRemove = async () => {
        handleClose();
        setStatus("Accepted")
        const { data, error } = await supabase
            .from('projects_applications')
            .update({ status: 'Accepted' })
            .eq('project_id', item.project_id)
            .eq('user_id', item.user_id);
        
        if (error) {
            throw error;
        } 

    }

    const handleDelete = async() => {
        // handle Remove here

        const updatedApplicants = projectApplicants.filter(
            app => app.project_id !== item.project_id && app.user_id !== item.user_id
        );
        setProjectApplicant(updatedApplicants);

        const { data, error } = await supabase
            .from('projects_applications')
            .delete()
            .eq('project_id', item.project_id)
            .eq('user_id', item.user_id);
    }


    return (
        <Box className={styles.dots}>
            <MoreHoriz sx={{ fill: "#A4A2A2" }}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            />    
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={ handleRemove }>Remove Completed Status</MenuItem>
                {/* <MenuItem onClick={handleClose}>View Job Details</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem> */}
            </Menu>
        </Box>  
    );
}

export default Options; 