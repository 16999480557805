import React, { useRef, useState, useEffect, useContext } from "react";

import "cropperjs/dist/cropper.min.css";
import { Box, Typography } from "@mui/material";
import styles from "./style.module.css";
import ImageCropper from "./cropper";
import supabase from "../../../../../../../../config/supabaseClient";
import { uploadImage } from "../../../../../../../../helpers/uploadImageToS3";
import { LoadingContext } from "../../../../../../../../context/loading-context";
import imageCompression from "browser-image-compression";
import { ProjectImageContext } from "../../../../../../../../context/project-cover-image-context";

const EditProjectImage = () => {
  const {
    setIsModalProjectOpen,
    setCroppedImage,
    selectedProjectImage,
    setSelectedProjectImage,
    setOriginalProjectImage
  } = useContext(ProjectImageContext);
  
  const [isProjectUploading, setIsProjectUploading] = useState(false);
  // const { isLoading, setIsLoading } = useContext(LoadingContext);

  const handleCrop = async (croppedImage) => {
    try {
      // Convert the Uint8Array to a Blob
      const blob = new Blob([croppedImage], { type: "image/png" });
      const originalBlob = blob;
      
      // Compress the image using browser-image-compression library
      const options = {
        maxSizeMB: 1, // Set the maximum size of the compressed image to 0.5 MB
        maxWidthOrHeight: 800, // Set the maximum width or height of the compressed image
        useWebWorker: true, // Use a web worker for faster compression
        quality: 1, // Adjust the quality parameter (0.7 is a good starting point)
      };

      const compressedBlob = await imageCompression(originalBlob, options);
      
      const blobURL = await uploadImage(
        compressedBlob,
        "project-cover-image-" + Math.random() + "-v-" + new Date().getTime()
      );

      setCroppedImage(blobURL);
      setSelectedProjectImage(null);
      setIsModalProjectOpen(false);
      

      // handle original image upload
      const response = await fetch(selectedProjectImage);
      const blobData = await response.blob();

      // Compress the image using browser-image-compression library
      const originalOptions = {
        maxSizeMB: 1, // Set the maximum size of the compressed image
        maxWidthOrHeight: 600, // Set the maximum width or height of the compressed image
        useWebWorker: true, // Use a web worker for faster compression
        quality: 1,
      };

      const compressedOrBlob = await imageCompression(blobData, originalOptions);
      
      const originalImageUrl = await uploadImage(
        compressedOrBlob,
        "original-project-cover-image-" +
        "-v-" +
        new Date().getTime()
      );
      
      setOriginalProjectImage(originalImageUrl);

    } catch (error) {
      console.error("Error handling upload:", error);
    }

  };
  const handleCancel = () => {
    setSelectedProjectImage(null);
    setIsModalProjectOpen(false);
  };
  
  return (
    <Box className={styles.parent}>
      <Box className={styles.container}>
        <ImageCropper
          imageSrc={selectedProjectImage}
          onCrop={handleCrop}
          onCancel={handleCancel}
          isProfileUploading={isProjectUploading}
          setIsProfileUploading={setIsProjectUploading}
        />
      </Box>
    </Box>
  );
};

export default EditProjectImage;
