export const resizeImage = (file, width, height, quality = 0.99) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target.result;
      img.onload = () => {
        const { naturalWidth, naturalHeight } = img;
        // Check if resizing is necessary
        if (naturalWidth <= width && naturalHeight <= height) {
          // No resizing needed, return the original file as a Blob
          console.log("resolve")
          resolve(file);
          return;
        }

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        // Draw the image with a high-quality resampling algorithm
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = "high"; // Use 'high' for better results

        ctx.drawImage(img, 0, 0, width, height);

        // Convert the canvas to a file or data URL with the specified quality
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Canvas is empty'));
          }
        }, file.type || 'image/jpeg', quality); // quality parameter for JPEG
      };
    };

    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};
