import React, { useState } from "react";
import styles from "./style.module.css";
import Landing from "./landing";
import DeleteConfirmation from "./delete-account";
import { Box } from "@mui/material";
const Settings = () => {
  const [isDeleteConfirmationOpened, setIsDeleteConfirmationOpened] =
    useState(true);
  return (
    <Box>
      <Landing />
      <DeleteConfirmation
        isOpened={isDeleteConfirmationOpened}
        setIsOpend={setIsDeleteConfirmationOpened}
      />
    </Box>
  );
};
export default Settings;
