import { Box, Button, Divider } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import Filter from "./filters";
import ResponsiveCarousel from "./Cards";
import { ChevronLeft } from "@mui/icons-material";
import { SearchContext } from "../../../context/search-context";
import supabase from "../../../config/supabaseClient";
import { useTheme } from "../../../context/theme-context";
import PrimaryHeader from "../components/purple-header";
import Header from "./components/header";
import GridResult from './components/grid';
import ListResult from  './components/list';
import BlueHeader from "../components/blue-header";
import { DiscoverContext } from "../../../context/discover-context";
import LoadingButton from "../../../components/loading/button";
import Fireworks from "../../../components/common/FireWorks";

const SearchResult = () => {
  const [searchResults, setSearchResults] = useState(null);
  const { theme } = useTheme();
  const [tabOption, setTabOption] = useState("grid");
  const navigate = useNavigate();
  const {
    searchKeyword,
    searchType,
    selectedSkill,
    selectedSpecialty,
    category,
    selectedCountry,
    selectedState,
    selectedEditorChoice,
    selectedProsOnly,
    selectedAgeRagnes,
  } = useContext(SearchContext);
  
  const { data, loading } = useContext(DiscoverContext);

  useEffect(() => {
    const fetchKeywordSearch = async () => {
      const { data, error } = await supabase
        .from("users_primary_skills")
        .select();
      // .filter(
      //   "tagline",
      //   "ilike",
      //   `%${searchKeyword}%`,
      //   "OR",
      //   "real_name",
      //   "ilike",
      //   `%${searchKeyword}%`
      // );

      setSearchResults(
        data.map((item) => {
          return {
            profileImage: item.profile_image,
            nickname: item.nick_name,
            name: item.real_name,
            primaryImage: item.skill_cover_image,
            skill: item.description,
            user_id: item.user_id,
          };
        })
      );
    };
    const fetchCustomSearchResults = async () => {
      const { data, error } = await supabase
        .from("users_types_skills")
        .select();
      // .match({ category, skill: selectedSkill, country: selectedCountry });

      setSearchResults(
        data.map((item) => {
          return {
            profileImage: item.profile_image,
            nickname: item.nick_name,
            name: item.real_name,
            primaryImage: item.skill_cover_image,
            skill: item.description,
            user_id: item.user_id,
          };
        })
      );
    };
    if (searchType === "singleSearch") fetchKeywordSearch();
    if (searchType === "custom") fetchCustomSearchResults();
  }, []);

  const clickGridOption = () => {
    setTabOption("grid");
  };
  const clickListOption = () => {
    setTabOption("list");
  };

  return (
    <Box
      className={styles.result}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey500,
        minHeight: "100vh",
        paddingBottom: "4rem",
      }}
    >
      <Box
        className={styles.container}
        sx={{
          width: {
            xs: "100%",
          },
        }}
      >
        <Box className={styles.topGradient}
          sx={{
            background: tabOption === "grid"? `linear-gradient(180deg, #00B0F0 0%, rgba(41, 41, 41, 0.00) 100%)`: `linear-gradient(180deg, #8C20B3 0%, rgba(245, 242, 246, 0.00) 100%)`,    
            
          }}
        >
          {tabOption === "grid" && <BlueHeader />}
          {tabOption === "list" && <PrimaryHeader />}  
        </Box>
        <Header
          clickGridOption={clickGridOption}
          clickListOption={clickListOption}
          tabOption={tabOption}
        />

        {tabOption === "grid" && <GridResult projects={data} />}
        {tabOption === "list" && <ListResult projects={data} />}
      </Box>
    </Box>
  );
};

export default SearchResult;
