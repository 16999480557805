import { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./user-context";
import supabase from "../config/supabaseClient";
import { mapScoreToDescription } from "../helpers/mapScoreToDescription";
import axios from "axios";
import { server_link } from "../constants";

export const SettingsContext = createContext(null);
const SettingsContextProvider = (props) => {
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [userScores, setUserScores] = useState([])
    const { user, setUser } = useContext(UserContext);
    const [score, setScore] = useState('');

    const recalculateScore = async() => {
        try {
            const {data} = await axios.get(`${server_link}api/users_scores?userId=${user.id}`)
            console.log(data, 'check response from recalculateScore method')
            if (data.message === "User Score Updated") {
                setScore(()=>mapScoreToDescription(data.totalScore).finalScore);
                setUser(prevUser => ({
                    ...prevUser,
                    score: data.totalScore
                }));
            }

        } catch{
            console.error();
        }
    
    }

    const fetchUserScores = async () => {
        const { data, error } = await supabase
            .from('users_scores')
            .select('*').eq('user_id', user?.id);
        setUserScores(data[0]);
        console.log(data, 'test user scores');   
    }

    useEffect(() => {
        const userScore = mapScoreToDescription(user?.score);
        setScore(userScore.finalScore);
        fetchUserScores();
    },[user])

    const contextValue = {
        openDeleteConfirmation,
        setOpenDeleteConfirmation,
        userScores,
        score,
        recalculateScore,
        fetchUserScores
    };
    
    return (
        <SettingsContext.Provider value={contextValue}>
        {props.children}
        </SettingsContext.Provider>
    );
};
export default SettingsContextProvider;