// cropUtils.js
export const getCroppedImg = (imageSrc, crop) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error("No 2d context");
    }

    const pixelRatio = window.devicePixelRatio;
    const scaleX = imageSrc.naturalWidth / imageSrc.width;
    const scaleY = imageSrc.naturalHeight / imageSrc.height;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
    
    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";
    ctx.save();

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    ctx.translate(-cropX, -cropY);

    ctx.drawImage(
      imageSrc,
      0,
      0,
      imageSrc.naturalWidth,
      imageSrc.naturalHeight,
      0,
      0,
      imageSrc.naturalWidth,
      imageSrc.naturalHeight
    );

    // ctx.restore();
    // Convert the cropped image to a data URL
    const croppedImageUrl = canvas.toDataURL('image/png');

    // Convert data URL to blob
    fetch(croppedImageUrl)
      .then((res) => res.blob())
      .then((blob) => {
        resolve(blob);
      });

  });
};
