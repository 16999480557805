import { Box, Typography } from "@mui/material";
import React from "react";
import styles from "./style.module.css";

import rockAndRoll from "../../../../../assets/images/rock-and-roll.png";
import { useTheme } from "../../../../../context/theme-context";

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.slice(0, maxLength) + "...";
  }
}

const Top = ({ data }) => {
  const { theme } = useTheme();

  return (
    <Box className={styles.top}>
      <Box className={styles.profile}>
        <Box
          className={styles.image}
          sx={{
            backgroundImage: `url(${data.profile_image})`,
            width: "32px",
            height: "32px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        ></Box>
        <Box className={styles.name}>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.darkgrey.darkgrey600,
            }}
          >
            {truncateText(data.real_name, 5)}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.darkgrey.darkgrey300,
            }}
          >
            {data.nick_name}
          </Typography>
        </Box>
      </Box>
      {/* <Box
        className={styles.Shouts}
        sx={{
          bgcolor: theme.palette.darkgrey.darkgrey200,
        }}
      >
        <img src={rockAndRoll} width="100%" height="100%" alt="rock-and-roll" />
      </Box> */}
    </Box>
  );
};

export default Top;
