import { Hidden, Typography } from "@mui/material"
import { useTheme } from "../../../../../../../../../../context/theme-context"
import { useContext } from "react";
import { UserContext } from "../../../../../../../../../../context/user-context";

const Description = ({description}) => {
    const { theme } = useTheme();
    const { user } = useContext(UserContext);

    if (!user) {
        return <></>
    }
    return (
        <Typography variant="body1"
            sx={{
                color: theme.palette.darkgrey.darkgrey400,
                fontSize: '16px',
                fontWeight: '500',
                width: '100%',
                overflow: 'hidden',
            }}
        >
            {description}
        </Typography> 
    )
};

export default Description;