import moment from "moment";
import { createContext, useState } from "react";

export const ProfileImageContext = createContext(null);
const ProfileImageContextProvider = (props) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const contextValue = {
    selectedImage,
    setSelectedImage,
    croppedImage,
    setCroppedImage,
    isModalOpen,
    setIsModalOpen,
    isDeleteOpen,
    setIsDeleteOpen
  };

  return (
    <ProfileImageContext.Provider value={contextValue}>
      {props.children}
    </ProfileImageContext.Provider>
  );
};
export default ProfileImageContextProvider;
