import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import myCard from "../../assets/images/nav-my-card.svg";
import myCardActive from "../../assets/images/nav-my-card-active.svg";
import discover from "../../assets/images/nav-discover.svg";
import discoverActive from "../../assets/images/nav-discover-active.svg";
import projects from "../../assets/images/nav-projects.svg";
import projectsActive from "../../assets/images/nav-projects-active.svg";
import settings from "../../assets/images/nav-settings.svg";
import settingsActive from "../../assets/images/nav-settings-active.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/user-context";
import ReactGA from "react-ga4";
import { useTheme } from "../../context/theme-context";

function BottomNavBar() {
  const { pathname } = useLocation();
  const [value, setValue] = React.useState(0);
  const { user } = React.useContext(UserContext);
  const location = useLocation();
  const { theme, darkMode } = useTheme();

  React.useEffect(() => {
    if (user) {
      ReactGA.send({
        hitType: "pageview",
        page: pathname,
        title: pathname,
      });
      console.log("sent to google");
    }
  }, [location]);

  const isMyCardSelected = value === 0;
  const isDiscoverSelected = value === 1;
  const isProjectsSelected = value === 2;
  const isSettingsSelected = value === 3;

  if (
    !user ||
    pathname === "/" ||
    pathname === "/signup" ||
    pathname === "/onboarding" ||
    pathname === "/about-edit" ||
    pathname === "/add-skill" ||
    pathname === "/edit-skill" ||
    pathname === "/editSkill/:id" ||
    pathname === "/terms-of-service" 
    // pathname === "/discover"
  )
    return <div></div>;

  return (
    <Box
      sx={{
        zIndex: "999",
        width: {
          xs: "100%",
          md: "400px",
        },
        position: "fixed",
        bottom: "0",
        background: darkMode ? "#333333" : theme.palette.lightgrey.lightgrey00,
        padding: "12px 24px",
        borderTop: "1px solid",
        borderColor: darkMode
          ? "#525252"
          : theme.palette.lightgrey.lightgrey700,
        "& .MuiBottomNavigationAction-label": {
          gap: "8px",
          "&.Mui-selected": {
            color: darkMode && "#FFF",
          },
        },
        "& .MuiBottomNavigation-root": {
          backgroundColor: "inherit",
        },
      }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="My Card"
          icon={
            isMyCardSelected ?(
              darkMode ?
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M4.00002 21.8174C4.6026 22 5.41649 22 6.8 22H17.2C18.5835 22 19.3974 22 20 21.8174M4.00002 21.8174C3.87082 21.7783 3.75133 21.7308 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C20.2487 21.7308 20.1292 21.7783 20 21.8174M4.00002 21.8174C4.00035 21.0081 4.00521 20.5799 4.07686 20.2196C4.39249 18.6329 5.63288 17.3925 7.21964 17.0769C7.60603 17 8.07069 17 9 17H15C15.9293 17 16.394 17 16.7804 17.0769C18.3671 17.3925 19.6075 18.6329 19.9231 20.2196C19.9948 20.5799 19.9996 21.0081 20 21.8174M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>:
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M4.00002 21.8174C4.6026 22 5.41649 22 6.8 22H17.2C18.5835 22 19.3974 22 20 21.8174M4.00002 21.8174C3.87082 21.7783 3.75133 21.7308 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C20.2487 21.7308 20.1292 21.7783 20 21.8174M4.00002 21.8174C4.00035 21.0081 4.00521 20.5799 4.07686 20.2196C4.39249 18.6329 5.63288 17.3925 7.21964 17.0769C7.60603 17 8.07069 17 9 17H15C15.9293 17 16.394 17 16.7804 17.0769C18.3671 17.3925 19.6075 18.6329 19.9231 20.2196C19.9948 20.5799 19.9996 21.0081 20 21.8174M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5Z" stroke="#8C20B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            ) : (
                darkMode ?
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M4.00002 21.8174C4.6026 22 5.41649 22 6.8 22H17.2C18.5835 22 19.3974 22 20 21.8174M4.00002 21.8174C3.87082 21.7783 3.75133 21.7308 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C20.2487 21.7308 20.1292 21.7783 20 21.8174M4.00002 21.8174C4.00035 21.0081 4.00521 20.5799 4.07686 20.2196C4.39249 18.6329 5.63288 17.3925 7.21964 17.0769C7.60603 17 8.07069 17 9 17H15C15.9293 17 16.394 17 16.7804 17.0769C18.3671 17.3925 19.6075 18.6329 19.9231 20.2196C19.9948 20.5799 19.9996 21.0081 20 21.8174M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5Z" stroke="#A4A2A2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> :
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M4.00002 21.8174C4.6026 22 5.41649 22 6.8 22H17.2C18.5835 22 19.3974 22 20 21.8174M4.00002 21.8174C3.87082 21.7783 3.75133 21.7308 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C20.2487 21.7308 20.1292 21.7783 20 21.8174M4.00002 21.8174C4.00035 21.0081 4.00521 20.5799 4.07686 20.2196C4.39249 18.6329 5.63288 17.3925 7.21964 17.0769C7.60603 17 8.07069 17 9 17H15C15.9293 17 16.394 17 16.7804 17.0769C18.3671 17.3925 19.6075 18.6329 19.9231 20.2196C19.9948 20.5799 19.9996 21.0081 20 21.8174M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5Z" stroke="#9C9A9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>  
            ) 
          }
          LinkComponent={Link}
          to="/profile"
        />

        <BottomNavigationAction
          label="Discover"
          icon={
            isDiscoverSelected ? (
              darkMode ?
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path d="M14.334 11.6582H8.33398M10.334 15.6582H8.33398M16.334 7.6582H8.33398M20.334 11.1582V7.4582C20.334 5.77805 20.334 4.93797 20.007 4.29623C19.7194 3.73175 19.2604 3.2728 18.696 2.98518C18.0542 2.6582 17.2141 2.6582 15.534 2.6582H9.13398C7.45383 2.6582 6.61375 2.6582 5.97201 2.98518C5.40753 3.2728 4.94858 3.73175 4.66096 4.29623C4.33398 4.93797 4.33398 5.77805 4.33398 7.4582V17.8582C4.33398 19.5384 4.33398 20.3784 4.66096 21.0202C4.94858 21.5847 5.40753 22.0436 5.97201 22.3312C6.61375 22.6582 7.45383 22.6582 9.13398 22.6582H11.834M22.334 22.6582L20.834 21.1582M21.834 18.6582C21.834 20.5912 20.267 22.1582 18.334 22.1582C16.401 22.1582 14.834 20.5912 14.834 18.6582C14.834 16.7252 16.401 15.1582 18.334 15.1582C20.267 15.1582 21.834 16.7252 21.834 18.6582Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>:
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path d="M14.334 11.6582H8.33398M10.334 15.6582H8.33398M16.334 7.6582H8.33398M20.334 11.1582V7.4582C20.334 5.77805 20.334 4.93797 20.007 4.29623C19.7194 3.73175 19.2604 3.2728 18.696 2.98518C18.0542 2.6582 17.2141 2.6582 15.534 2.6582H9.13398C7.45383 2.6582 6.61375 2.6582 5.97201 2.98518C5.40753 3.2728 4.94858 3.73175 4.66096 4.29623C4.33398 4.93797 4.33398 5.77805 4.33398 7.4582V17.8582C4.33398 19.5384 4.33398 20.3784 4.66096 21.0202C4.94858 21.5847 5.40753 22.0436 5.97201 22.3312C6.61375 22.6582 7.45383 22.6582 9.13398 22.6582H11.834M22.334 22.6582L20.834 21.1582M21.834 18.6582C21.834 20.5912 20.267 22.1582 18.334 22.1582C16.401 22.1582 14.834 20.5912 14.834 18.6582C14.834 16.7252 16.401 15.1582 18.334 15.1582C20.267 15.1582 21.834 16.7252 21.834 18.6582Z" stroke="#8C20B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            ) : (
                darkMode ?
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path d="M14.334 11H8.33398M10.334 15H8.33398M16.334 7H8.33398M20.334 10.5V6.8C20.334 5.11984 20.334 4.27976 20.007 3.63803C19.7194 3.07354 19.2604 2.6146 18.696 2.32698C18.0542 2 17.2141 2 15.534 2H9.13398C7.45383 2 6.61375 2 5.97201 2.32698C5.40753 2.6146 4.94858 3.07354 4.66096 3.63803C4.33398 4.27976 4.33398 5.11984 4.33398 6.8V17.2C4.33398 18.8802 4.33398 19.7202 4.66096 20.362C4.94858 20.9265 5.40753 21.3854 5.97201 21.673C6.61375 22 7.45383 22 9.13398 22H11.834M22.334 22L20.834 20.5M21.834 18C21.834 19.933 20.267 21.5 18.334 21.5C16.401 21.5 14.834 19.933 14.834 18C14.834 16.067 16.401 14.5 18.334 14.5C20.267 14.5 21.834 16.067 21.834 18Z" stroke="#A4A2A2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>:    
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path d="M14.334 11H8.33398M10.334 15H8.33398M16.334 7H8.33398M20.334 10.5V6.8C20.334 5.11984 20.334 4.27976 20.007 3.63803C19.7194 3.07354 19.2604 2.6146 18.696 2.32698C18.0542 2 17.2141 2 15.534 2H9.13398C7.45383 2 6.61375 2 5.97201 2.32698C5.40753 2.6146 4.94858 3.07354 4.66096 3.63803C4.33398 4.27976 4.33398 5.11984 4.33398 6.8V17.2C4.33398 18.8802 4.33398 19.7202 4.66096 20.362C4.94858 20.9265 5.40753 21.3854 5.97201 21.673C6.61375 22 7.45383 22 9.13398 22H11.834M22.334 22L20.834 20.5M21.834 18C21.834 19.933 20.267 21.5 18.334 21.5C16.401 21.5 14.834 19.933 14.834 18C14.834 16.067 16.401 14.5 18.334 14.5C20.267 14.5 21.834 16.067 21.834 18Z" stroke="#9C9A9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>    
            )
          }
          LinkComponent={Link}
          to= {user.is_profile_complete? "/discover/results" : "/discover" }
        />

        <BottomNavigationAction
          label="Projects"
          icon={
            isProjectsSelected ? (
              darkMode ? 
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path d="M2.66699 12.6578L12.3092 17.4789C12.4404 17.5445 12.506 17.5773 12.5748 17.5902C12.6357 17.6016 12.6983 17.6016 12.7592 17.5902C12.828 17.5773 12.8936 17.5445 13.0248 17.4789L22.667 12.6578M2.66699 17.6578L12.3092 22.4789C12.4404 22.5445 12.506 22.5773 12.5748 22.5902C12.6357 22.6016 12.6983 22.6016 12.7592 22.5902C12.828 22.5773 12.8936 22.5445 13.0248 22.4789L22.667 17.6578M2.66699 7.65778L12.3092 2.83667C12.4404 2.77107 12.506 2.73828 12.5748 2.72537C12.6357 2.71394 12.6983 2.71394 12.7592 2.72537C12.828 2.73828 12.8936 2.77107 13.0248 2.83667L22.667 7.65778L13.0248 12.4789C12.8936 12.5445 12.828 12.5773 12.7592 12.5902C12.6983 12.6016 12.6357 12.6016 12.5748 12.5902C12.506 12.5773 12.4404 12.5445 12.3092 12.4789L2.66699 7.65778Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>:
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path d="M2.66699 12.6578L12.3092 17.4789C12.4404 17.5445 12.506 17.5773 12.5748 17.5902C12.6357 17.6016 12.6983 17.6016 12.7592 17.5902C12.828 17.5773 12.8936 17.5445 13.0248 17.4789L22.667 12.6578M2.66699 17.6578L12.3092 22.4789C12.4404 22.5445 12.506 22.5773 12.5748 22.5902C12.6357 22.6016 12.6983 22.6016 12.7592 22.5902C12.828 22.5773 12.8936 22.5445 13.0248 22.4789L22.667 17.6578M2.66699 7.65778L12.3092 2.83667C12.4404 2.77107 12.506 2.73828 12.5748 2.72537C12.6357 2.71394 12.6983 2.71394 12.7592 2.72537C12.828 2.73828 12.8936 2.77107 13.0248 2.83667L22.667 7.65778L13.0248 12.4789C12.8936 12.5445 12.828 12.5773 12.7592 12.5902C12.6983 12.6016 12.6357 12.6016 12.5748 12.5902C12.506 12.5773 12.4404 12.5445 12.3092 12.4789L2.66699 7.65778Z" stroke="#8C20B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            ) : (
              darkMode ?
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path d="M2.66602 11.9996L12.3082 16.8207C12.4394 16.8863 12.505 16.9191 12.5738 16.932C12.6348 16.9434 12.6973 16.9434 12.7582 16.932C12.827 16.9191 12.8926 16.8863 13.0238 16.8207L22.666 11.9996M2.66602 16.9996L12.3082 21.8207C12.4394 21.8863 12.505 21.9191 12.5738 21.932C12.6348 21.9434 12.6973 21.9434 12.7582 21.932C12.827 21.9191 12.8926 21.8863 13.0238 21.8207L22.666 16.9996M2.66602 6.99958L12.3082 2.17846C12.4394 2.11287 12.505 2.08008 12.5738 2.06717C12.6348 2.05574 12.6973 2.05574 12.7582 2.06717C12.827 2.08008 12.8926 2.11287 13.0238 2.17846L22.666 6.99958L13.0238 11.8207C12.8926 11.8863 12.827 11.9191 12.7582 11.932C12.6973 11.9434 12.6348 11.9434 12.5738 11.932C12.505 11.9191 12.4394 11.8863 12.3082 11.8207L2.66602 6.99958Z" stroke="#A4A2A2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>:
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path d="M2.66602 11.9996L12.3082 16.8207C12.4394 16.8863 12.505 16.9191 12.5738 16.932C12.6348 16.9434 12.6973 16.9434 12.7582 16.932C12.827 16.9191 12.8926 16.8863 13.0238 16.8207L22.666 11.9996M2.66602 16.9996L12.3082 21.8207C12.4394 21.8863 12.505 21.9191 12.5738 21.932C12.6348 21.9434 12.6973 21.9434 12.7582 21.932C12.827 21.9191 12.8926 21.8863 13.0238 21.8207L22.666 16.9996M2.66602 6.99958L12.3082 2.17846C12.4394 2.11287 12.505 2.08008 12.5738 2.06717C12.6348 2.05574 12.6973 2.05574 12.7582 2.06717C12.827 2.08008 12.8926 2.11287 13.0238 2.17846L22.666 6.99958L13.0238 11.8207C12.8926 11.8863 12.827 11.9191 12.7582 11.932C12.6973 11.9434 12.6348 11.9434 12.5738 11.932C12.505 11.9191 12.4394 11.8863 12.3082 11.8207L2.66602 6.99958Z" stroke="#9C9A9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            )
            
          }
          LinkComponent={Link}
          to="projects"
        />
        <BottomNavigationAction
          label="Settings"
          icon={
            isSettingsSelected ? (
              darkMode ?
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M9.39504 19.3711L9.97949 20.6856C10.1532 21.0768 10.4368 21.4093 10.7957 21.6426C11.1547 21.8759 11.5736 22.0001 12.0017 22C12.4298 22.0001 12.8488 21.8759 13.2077 21.6426C13.5667 21.4093 13.8502 21.0768 14.0239 20.6856L14.6084 19.3711C14.8164 18.9047 15.1664 18.5159 15.6084 18.26C16.0532 18.0034 16.5677 17.8941 17.0784 17.9478L18.5084 18.1C18.934 18.145 19.3636 18.0656 19.7451 17.8713C20.1265 17.6771 20.4434 17.3763 20.6573 17.0056C20.8714 16.635 20.9735 16.2103 20.951 15.7829C20.9285 15.3555 20.7825 14.9438 20.5306 14.5978L19.6839 13.4344C19.3825 13.0171 19.2214 12.5148 19.2239 12C19.2238 11.4866 19.3864 10.9864 19.6884 10.5711L20.535 9.40778C20.7869 9.06175 20.933 8.65007 20.9554 8.22267C20.9779 7.79528 20.8759 7.37054 20.6617 7C20.4478 6.62923 20.1309 6.32849 19.7495 6.13423C19.3681 5.93997 18.9385 5.86053 18.5128 5.90556L17.0828 6.05778C16.5722 6.11141 16.0576 6.00212 15.6128 5.74556C15.1699 5.48825 14.8199 5.09736 14.6128 4.62889L14.0239 3.31444C13.8502 2.92317 13.5667 2.59072 13.2077 2.3574C12.8488 2.12408 12.4298 1.99993 12.0017 2C11.5736 1.99993 11.1547 2.12408 10.7957 2.3574C10.4368 2.59072 10.1532 2.92317 9.97949 3.31444L9.39504 4.62889C9.18797 5.09736 8.83792 5.48825 8.39504 5.74556C7.95026 6.00212 7.43571 6.11141 6.92504 6.05778L5.4906 5.90556C5.06493 5.86053 4.63534 5.93997 4.25391 6.13423C3.87249 6.32849 3.55561 6.62923 3.34171 7C3.12753 7.37054 3.02549 7.79528 3.04798 8.22267C3.07046 8.65007 3.2165 9.06175 3.46838 9.40778L4.31504 10.5711C4.61698 10.9864 4.77958 11.4866 4.77949 12C4.77958 12.5134 4.61698 13.0137 4.31504 13.4289L3.46838 14.5922C3.2165 14.9382 3.07046 15.3499 3.04798 15.7773C3.02549 16.2047 3.12753 16.6295 3.34171 17C3.55582 17.3706 3.87274 17.6712 4.25411 17.8654C4.63548 18.0596 5.06496 18.1392 5.4906 18.0944L6.9206 17.9422C7.43127 17.8886 7.94581 17.9979 8.3906 18.2544C8.83513 18.511 9.18681 18.902 9.39504 19.3711Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.9999 15C13.6568 15 14.9999 13.6569 14.9999 12C14.9999 10.3431 13.6568 9 11.9999 9C10.3431 9 8.99992 10.3431 8.99992 12C8.99992 13.6569 10.3431 15 11.9999 15Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> :
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M9.39504 19.3711L9.97949 20.6856C10.1532 21.0768 10.4368 21.4093 10.7957 21.6426C11.1547 21.8759 11.5736 22.0001 12.0017 22C12.4298 22.0001 12.8488 21.8759 13.2077 21.6426C13.5667 21.4093 13.8502 21.0768 14.0239 20.6856L14.6084 19.3711C14.8164 18.9047 15.1664 18.5159 15.6084 18.26C16.0532 18.0034 16.5677 17.8941 17.0784 17.9478L18.5084 18.1C18.934 18.145 19.3636 18.0656 19.7451 17.8713C20.1265 17.6771 20.4434 17.3763 20.6573 17.0056C20.8714 16.635 20.9735 16.2103 20.951 15.7829C20.9285 15.3555 20.7825 14.9438 20.5306 14.5978L19.6839 13.4344C19.3825 13.0171 19.2214 12.5148 19.2239 12C19.2238 11.4866 19.3864 10.9864 19.6884 10.5711L20.535 9.40778C20.7869 9.06175 20.933 8.65007 20.9554 8.22267C20.9779 7.79528 20.8759 7.37054 20.6617 7C20.4478 6.62923 20.1309 6.32849 19.7495 6.13423C19.3681 5.93997 18.9385 5.86053 18.5128 5.90556L17.0828 6.05778C16.5722 6.11141 16.0576 6.00212 15.6128 5.74556C15.1699 5.48825 14.8199 5.09736 14.6128 4.62889L14.0239 3.31444C13.8502 2.92317 13.5667 2.59072 13.2077 2.3574C12.8488 2.12408 12.4298 1.99993 12.0017 2C11.5736 1.99993 11.1547 2.12408 10.7957 2.3574C10.4368 2.59072 10.1532 2.92317 9.97949 3.31444L9.39504 4.62889C9.18797 5.09736 8.83792 5.48825 8.39504 5.74556C7.95026 6.00212 7.43571 6.11141 6.92504 6.05778L5.4906 5.90556C5.06493 5.86053 4.63534 5.93997 4.25391 6.13423C3.87249 6.32849 3.55561 6.62923 3.34171 7C3.12753 7.37054 3.02549 7.79528 3.04798 8.22267C3.07046 8.65007 3.2165 9.06175 3.46838 9.40778L4.31504 10.5711C4.61698 10.9864 4.77958 11.4866 4.77949 12C4.77958 12.5134 4.61698 13.0137 4.31504 13.4289L3.46838 14.5922C3.2165 14.9382 3.07046 15.3499 3.04798 15.7773C3.02549 16.2047 3.12753 16.6295 3.34171 17C3.55582 17.3706 3.87274 17.6712 4.25411 17.8654C4.63548 18.0596 5.06496 18.1392 5.4906 18.0944L6.9206 17.9422C7.43127 17.8886 7.94581 17.9979 8.3906 18.2544C8.83513 18.511 9.18681 18.902 9.39504 19.3711Z" stroke="#8C20B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.9999 15C13.6568 15 14.9999 13.6569 14.9999 12C14.9999 10.3431 13.6568 9 11.9999 9C10.3431 9 8.99992 10.3431 8.99992 12C8.99992 13.6569 10.3431 15 11.9999 15Z" stroke="#8C20B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            ) : (
              darkMode ?
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M9.39504 19.3711L9.97949 20.6856C10.1532 21.0768 10.4368 21.4093 10.7957 21.6426C11.1547 21.8759 11.5736 22.0001 12.0017 22C12.4298 22.0001 12.8488 21.8759 13.2077 21.6426C13.5667 21.4093 13.8502 21.0768 14.0239 20.6856L14.6084 19.3711C14.8164 18.9047 15.1664 18.5159 15.6084 18.26C16.0532 18.0034 16.5677 17.8941 17.0784 17.9478L18.5084 18.1C18.934 18.145 19.3636 18.0656 19.7451 17.8713C20.1265 17.6771 20.4434 17.3763 20.6573 17.0056C20.8714 16.635 20.9735 16.2103 20.951 15.7829C20.9285 15.3555 20.7825 14.9438 20.5306 14.5978L19.6839 13.4344C19.3825 13.0171 19.2214 12.5148 19.2239 12C19.2238 11.4866 19.3864 10.9864 19.6884 10.5711L20.535 9.40778C20.7869 9.06175 20.933 8.65007 20.9554 8.22267C20.9779 7.79528 20.8759 7.37054 20.6617 7C20.4478 6.62923 20.1309 6.32849 19.7495 6.13423C19.3681 5.93997 18.9385 5.86053 18.5128 5.90556L17.0828 6.05778C16.5722 6.11141 16.0576 6.00212 15.6128 5.74556C15.1699 5.48825 14.8199 5.09736 14.6128 4.62889L14.0239 3.31444C13.8502 2.92317 13.5667 2.59072 13.2077 2.3574C12.8488 2.12408 12.4298 1.99993 12.0017 2C11.5736 1.99993 11.1547 2.12408 10.7957 2.3574C10.4368 2.59072 10.1532 2.92317 9.97949 3.31444L9.39504 4.62889C9.18797 5.09736 8.83792 5.48825 8.39504 5.74556C7.95026 6.00212 7.43571 6.11141 6.92504 6.05778L5.4906 5.90556C5.06493 5.86053 4.63534 5.93997 4.25391 6.13423C3.87249 6.32849 3.55561 6.62923 3.34171 7C3.12753 7.37054 3.02549 7.79528 3.04798 8.22267C3.07046 8.65007 3.2165 9.06175 3.46838 9.40778L4.31504 10.5711C4.61698 10.9864 4.77958 11.4866 4.77949 12C4.77958 12.5134 4.61698 13.0137 4.31504 13.4289L3.46838 14.5922C3.2165 14.9382 3.07046 15.3499 3.04798 15.7773C3.02549 16.2047 3.12753 16.6295 3.34171 17C3.55582 17.3706 3.87274 17.6712 4.25411 17.8654C4.63548 18.0596 5.06496 18.1392 5.4906 18.0944L6.9206 17.9422C7.43127 17.8886 7.94581 17.9979 8.3906 18.2544C8.83513 18.511 9.18681 18.902 9.39504 19.3711Z" stroke="#A4A2A2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.9999 15C13.6568 15 14.9999 13.6569 14.9999 12C14.9999 10.3431 13.6568 9 11.9999 9C10.3431 9 8.99992 10.3431 8.99992 12C8.99992 13.6569 10.3431 15 11.9999 15Z" stroke="#A4A2A2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>: 
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M9.39504 19.3711L9.97949 20.6856C10.1532 21.0768 10.4368 21.4093 10.7957 21.6426C11.1547 21.8759 11.5736 22.0001 12.0017 22C12.4298 22.0001 12.8488 21.8759 13.2077 21.6426C13.5667 21.4093 13.8502 21.0768 14.0239 20.6856L14.6084 19.3711C14.8164 18.9047 15.1664 18.5159 15.6084 18.26C16.0532 18.0034 16.5677 17.8941 17.0784 17.9478L18.5084 18.1C18.934 18.145 19.3636 18.0656 19.7451 17.8713C20.1265 17.6771 20.4434 17.3763 20.6573 17.0056C20.8714 16.635 20.9735 16.2103 20.951 15.7829C20.9285 15.3555 20.7825 14.9438 20.5306 14.5978L19.6839 13.4344C19.3825 13.0171 19.2214 12.5148 19.2239 12C19.2238 11.4866 19.3864 10.9864 19.6884 10.5711L20.535 9.40778C20.7869 9.06175 20.933 8.65007 20.9554 8.22267C20.9779 7.79528 20.8759 7.37054 20.6617 7C20.4478 6.62923 20.1309 6.32849 19.7495 6.13423C19.3681 5.93997 18.9385 5.86053 18.5128 5.90556L17.0828 6.05778C16.5722 6.11141 16.0576 6.00212 15.6128 5.74556C15.1699 5.48825 14.8199 5.09736 14.6128 4.62889L14.0239 3.31444C13.8502 2.92317 13.5667 2.59072 13.2077 2.3574C12.8488 2.12408 12.4298 1.99993 12.0017 2C11.5736 1.99993 11.1547 2.12408 10.7957 2.3574C10.4368 2.59072 10.1532 2.92317 9.97949 3.31444L9.39504 4.62889C9.18797 5.09736 8.83792 5.48825 8.39504 5.74556C7.95026 6.00212 7.43571 6.11141 6.92504 6.05778L5.4906 5.90556C5.06493 5.86053 4.63534 5.93997 4.25391 6.13423C3.87249 6.32849 3.55561 6.62923 3.34171 7C3.12753 7.37054 3.02549 7.79528 3.04798 8.22267C3.07046 8.65007 3.2165 9.06175 3.46838 9.40778L4.31504 10.5711C4.61698 10.9864 4.77958 11.4866 4.77949 12C4.77958 12.5134 4.61698 13.0137 4.31504 13.4289L3.46838 14.5922C3.2165 14.9382 3.07046 15.3499 3.04798 15.7773C3.02549 16.2047 3.12753 16.6295 3.34171 17C3.55582 17.3706 3.87274 17.6712 4.25411 17.8654C4.63548 18.0596 5.06496 18.1392 5.4906 18.0944L6.9206 17.9422C7.43127 17.8886 7.94581 17.9979 8.3906 18.2544C8.83513 18.511 9.18681 18.902 9.39504 19.3711Z" stroke="#9C9A9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.9999 15C13.6568 15 14.9999 13.6569 14.9999 12C14.9999 10.3431 13.6568 9 11.9999 9C10.3431 9 8.99992 10.3431 8.99992 12C8.99992 13.6569 10.3431 15 11.9999 15Z" stroke="#9C9A9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> 
                  
            )
          }
          LinkComponent={Link}
          to="settings"
        />
      </BottomNavigation>
    </Box>
  );
}

export default BottomNavBar;
