import { useContext, useEffect } from "react"
import { UserContext } from "../context/user-context"
import supabase from "../config/supabaseClient";

const checkSkillComplete = (name, description, level, started_training_age) => {
    if(
        name
        && description
        && level
        && started_training_age !== null
    ) {
        console.log(started_training_age, 'check started_training_age')
        return true;
    }

    return false;

}

export default checkSkillComplete;