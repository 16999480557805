import styles from "./style.module.css";
import { Box, Typography } from "@mui/material";
import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../../../../../../context/theme-context";

const Skill = ({ skill, index, activeSkill, setActiveSkill }) => {
  const { theme, darkMode } = useTheme();
  const navigate = useNavigate();

  const isActive = skill?.id === activeSkill?.id && skill.id !== undefined;
  const notCreated = skill.created === false;

  const setSkills = () => {};
  // Function to handle button click and update state
  const handleItemClick = () => {
    if (!isActive) setActiveSkill(skill);
  };
  return (
    <Box className={styles.skill} key={index}>
      <Box
        className={styles.skillNum}
        sx={{
          background: isActive
            ? theme.palette.primary.main
            : theme.palette.lightgrey.lightgrey00,
        }}
        onClick={() => handleItemClick(index)}
      >
        {isActive ? (
          <img src={skill?.active_image} />
        ) : (
          <img src={skill.in_active_image} />
        )}
      </Box>
      <Box className={styles.text}>
        {/* skill category*/}
        <Typography
          variant="h4"
          sx={{
            color: isActive 
              ? darkMode
                ? '#FFF'
                : theme.palette.primary.main
              : darkMode
                ? theme.palette.darkgrey.darkgrey300
                : theme.palette.darkgrey.darkgrey600,
            fontSize: {
              xs: "12px",
              // md: "16px",
            },
            fontWeight: "500",
          }}
        >
          {skill.category}
        </Typography>
      </Box>
    </Box>
  );
};

export default Skill;
