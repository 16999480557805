import {
  ClickAwayListener,
  Fade,
  Button,
  Tooltip,
  Typography,
  tooltipClasses,
  Chip,
} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "../../../../../../../context/theme-context";

const InfoTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.lightgrey.lightgrey500,
    maxWidth: "260px",
    padding: "16px",
    boxShadow: theme.shadows[1],
  },
}));

const GenerationalTag = ({ ageCategory, infoText }) => {
  const { theme } = useTheme();

  const getContent = () => {
    switch (ageCategory) {
      case "X+":
        return "includes all creators born in 1964 or before.";
      case "X":
        return "includes all creators born between 1965 and 1979.";
      case "Y+":
        return "includes all creators born between 1980 and 1987.";
      case "Y":
        return "includes all creators born between 1988 and 1995.";
      case "Z+":
        return "includes all creators born between 1996 and 2004.";
      case "Z":
        return "includes all creators born in 2005 or after.";
      default:
        return "";
    }
  };

  const content = getContent();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <InfoTooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 300 }}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableTouchListener
          title={
            <React.Fragment>
              <Typography
                color={theme.palette.darkgrey.darkgrey600}
                variant="body1"
              >
                {ageCategory ? `Gen ${ageCategory}` : "Info tab"}
              </Typography>
              <Typography
                color={theme.palette.darkgrey.darkgrey300}
                variant="body2"
              >
                {infoText
                  ? infoText
                  : "supporting line text lorem ipsum dolar sit amet,sghsdgh"}
              </Typography>
            </React.Fragment>
          }
        >
          <Chip
            onClick={handleTooltipOpen}
            aria-label="info"
            label={`Gen ${ageCategory}`}
          />
        </InfoTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default GenerationalTag;
