import axios from "axios";
import { server_link } from "../constants";
const serverBaseUrl = `${server_link}api/emails/`;

export const sendShoutOutReceivedEmail = async (receiverId) => {
  axios.get(serverBaseUrl + "shoutout-received" + "?userId=" + receiverId);
};

export const sendFeedbackEmail = async (userId, ticketNumber) => {
  axios.get(
    serverBaseUrl +
      "feedback" +
      "?userId=" +
      userId +
      "&ticketNumber=" +
      ticketNumber
  );
};

export const sendClassificationHelpEmail = async (userId, ticketNumber) => {
  axios.get(
    serverBaseUrl +
      "classification-help-received" +
      "?userId=" +
      userId +
      "&ticketNumber=" +
      ticketNumber
  );
};

export const sendProjectSubmissionEmail = async (userId, projectId) => {
  axios.get(
    serverBaseUrl +
      "project-submitted" +
      "?userId=" +
      userId +
      "&projectId=" +
      projectId
  );
};

export const sendRemovedByHostEmail = async (projectId, removedUserId) => {
  axios.get(
    serverBaseUrl +
      "removed-by-host" +
      "?userId=" +
      removedUserId +
      "&projectId=" +
      projectId
  );
  console.log(projectId, "Check project id from sendRemovedByHostEmail ");
  console.log(removedUserId, "Check user id from sendRemovedByHostEmail ");
};

export const sendProjectCompletedEmail = async (projectId, userId) => {
  axios.get(
    serverBaseUrl +
      "project-completed" +
      "?userId=" +
      userId +
      "&projectId=" +
      projectId
  );
};

export const sendProjectDeletedemail = async (projectId) => {
  axios.get(serverBaseUrl + "project-cancelled" + "?projectId=" + projectId);
};

export const sendProjectApplicationAcceptedEmail = async (
  applicantId,
  projectId
) => {
  axios.get(
    serverBaseUrl +
      "project-application-accepted" +
      "?applicantId=" +
      applicantId +
      "&projectId=" +
      projectId
  );
};

export const sendProjectCanceledByUserEmail = async (
  applicantId,
  projectId
) => {
  console.log(applicantId, projectId, "check apllicat id and project id ");
};

export const sendProjectApplyConfirmationEmail = async (
  applicantId,
  projectId
) => {
  axios.get(
    serverBaseUrl +
      "project-application-received" +
      "?userId=" +
      applicantId +
      "&projectId=" +
      projectId
  );
};
