import React from 'react';
import { Box, Chip, Container, Typography } from '@mui/material';
import LynxLogo from '../../../../../assets/images/Color=Gredient, Size=Large.svg';
import styles from './style.module.css';
import { useTheme } from '../../../../../context/theme-context';

const BasicLogo = () => {
    const { theme } = useTheme();

    return (
        <Container className={styles.basicLogo}>
            <Box className={styles.content}>
                <Box className={styles.top}>
                    <img src={LynxLogo} className={styles.logo} alt="Logo" />
                    <Box className={styles.beta}>
                        <Typography variant="h4" align="center" color={theme.palette.darkgrey.darkgrey700}
                            sx={{
                                color: theme.palette.darkgrey.darkgrey600,
                                fontSize: '20px',
                                fontWeight: '500',
                            }}
                        >
                        Projects Board
                        </Typography> 
                        <Chip label="Beta"
                            sx={{
                                bgcolor: theme.palette.warning.main,
                                color: '#fff',
                                height: '20px',
                                fontSize: '10px',
                                fontWeight: '600',
                                padding: '4px 8px',
                                '& .css-6od3lo-MuiChip-label': {
                                    padding: '0px'
                                }
                            }}
                        />
                    </Box>
                </Box>
                <Typography variant="body1" align="center" color={theme.palette.darkgrey.darkgrey400}>
                    SUBMISSION FORM
                </Typography>      
            </Box>    
        </Container>
    );
}

export default BasicLogo;