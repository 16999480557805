import * as Sentry from "@sentry/react";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import UserContextProvider from "./context/user-context";
import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import React, { useEffect } from "react";
import Splash from "./pages/Start-Page/Splash";
import Login from "./pages/Start-Page/Register-Login/register-login";
import CreatorRegistration from "./pages/registeration";
import RegistrationContextProvider from "./context/registration-context";
import Thanks from "./pages/registeration/RegisterationThanks/thanks";
import MyProfile from "./pages/My-Profile-View/profile";
import AddSkill from "./pages/My-Profile-View/my-skills/components/add-skill";
import AddSkillContextProvider from "./context/add-skill-context";
import EditSkill from "./pages/My-Profile-View/my-skills/components/edit-skill";
import EditSkillContextProvider from "./context/edit-skill-context";
import SkillsBarContextProvider from "./context/skills-bar-context";
import AboutEdit from "./pages/My-Profile-View/About-Edit/aboutEdit";
import DiscoverHome from "./pages/Discover&Search/Discover-Home";
import BottomNavBar from "./pages/Footer";
// change
import CreatorSearch from "./pages/Discover&Search/search/search";
import PublicProfileView from "./pages/public-profile-view/index";
import SearchContextProvider from "./context/search-context";
import SearchResult from "./pages/Discover&Search/Results";
import ProjectLanding from "./pages/Project-View/landing";
import BoardSubmitionForm from "./pages/Project-View/Post-project/board-submission-form";
import EditProfileImage from "./pages/My-Profile-View/profile/profile-image/edit";
import DeleteProfileImage from "./pages/My-Profile-View/profile/profile-image/delete";
import ProfileImageContextProvider from "./context/profile-image-context";
import SettingsContextProvider from "./context/settings-context";
import PrivacyAndPolicy from "./pages/settings/privacy&policy";
import TermsOfService from "./pages/settings/terms-of-service";
import SubscriptionStatus from "./pages/settings/subscription-status";
import Settings from "./pages/settings";
import SkillImageContextProvider from "./context/skill-image-context";
import MakeLynxBetter from "./pages/My-Profile-View/feedback/makeLynxBetter";
import HelpWithClassification from "./pages/My-Profile-View/feedback/helpWithClassificaton";
import RoleContextProvider from "./context/role-context";
import "./index.css";
import { getAddressInfo } from "./helpers/getAddressInfo";
import LoadingContextProvider from "./context/loading-context";
import DiscoverPlaceholder from "./pages/Discover&Search/discover-placeholder";
import EmailPreferences from "./pages/settings/email-preferences";
import { useTheme } from "./context/theme-context";
import { InfoBoxsContextProvider } from "./context/info-boxs-context";
import axios from "axios";
import ProfileCompleteContextProvider from "./context/profile-complete-context";
import CreatorReversedRegistration from "./pages/reversed-registeration";
import ReversedRegistrationContextProvider from "./context/reversed-registration-context";
import ProjectImageContextProvider from "./context/project-cover-image-context";
import JoinAProject from "./pages/Project-View/Join-project";
import ProjectDetails from "./pages/Project-View/Join-project/components/project-details";
import ProjectDetailsContextProvider from "./context/project-details-context";
import ProjectManage from "./pages/Project-View/Manage-project";
import ManageProjectsContextProvider from "./context/manage-projects-context";
import EditProject from "./pages/Project-View/Edit_project";
import Discover from "./pages/Discover&Search";
import DiscoverContextProvider from "./context/discover-context";
import UserRatings from "./pages/settings/User Ratings";
import { ProjectFiltersProvider } from "./context/project-filters-context";

function App() {
  const { isLoading, error } = useAuth0();
  const { theme, darkMode } = useTheme();

  return (
    <main
      className="column"
      style={{
        backgroundColor: darkMode ? "#fff" : "#040d12",
      }}
    >
      <Box
        className="app"
        sx={{
          width: {
            xs: "100%",
            md: "400px",
          },
        }}
      >
        <InfoBoxsContextProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <ProjectDetailsContextProvider>
                <ProjectImageContextProvider>
                  <LoadingContextProvider>
                    <ReversedRegistrationContextProvider>
                      <RegistrationContextProvider>
                        <SkillsBarContextProvider>
                          <EditSkillContextProvider>
                            <AddSkillContextProvider>
                              <UserContextProvider>
                                <SearchContextProvider>
                                  <ProfileImageContextProvider>
                                    <SkillImageContextProvider>
                                      <RoleContextProvider>
                                        <SettingsContextProvider>
                                          <ProfileCompleteContextProvider>
                                            <ProjectFiltersProvider>
                                              <DiscoverContextProvider>
                                                <ManageProjectsContextProvider>
                                                  {!error && !isLoading && (
                                                    <>
                                                      <Routes>
                                                        <Route
                                                          path="/"
                                                          element={<Login />}
                                                        />
                                                        {/* <Route
                                                        path="/signup"
                                                        element={<Login />}
                                                      /> */}
                                                        <Route
                                                          path="/profile"
                                                          element={<MyProfile />}
                                                        />
                                                        <Route
                                                          path="/profile/edit-profile-image"
                                                          element={
                                                            <EditProfileImage />
                                                          }
                                                        />
                                                        <Route
                                                          path="/profile/delete-profile-image"
                                                          element={
                                                            <DeleteProfileImage />
                                                          }
                                                        />
                                                        <Route
                                                          path="/thanks"
                                                          element={<Thanks />}
                                                        />
                                                        <Route
                                                          path="/add-skill"
                                                          element={<AddSkill />}
                                                        />
                                                        <Route
                                                          path="/editSkill/:id"
                                                          element={<EditSkill />}
                                                        />
                                                        <Route
                                                          path="/about-edit"
                                                          element={<AboutEdit />}
                                                        />
                                                        <Route
                                                          path="/make-lynx-better"
                                                          element={<MakeLynxBetter />}
                                                        />
                                                        <Route
                                                          path="/help-with-classification"
                                                          element={
                                                            <HelpWithClassification />
                                                          }
                                                        />

                                                        <Route
                                                          path="/discover"
                                                          element={
                                                            <Discover />
                                                          }
                                                        />

                                                        <Route
                                                          path="/discover/search"
                                                          element={<CreatorSearch />}
                                                        />
                                                        <Route
                                                          path="/discover/results"
                                                          element={<SearchResult />}
                                                        />
                                                        <Route
                                                          path="/projects"
                                                          element={<ProjectLanding />}
                                                        />
                                                        <Route
                                                          path="/project-board-submission"
                                                          element={
                                                            <BoardSubmitionForm />
                                                          }
                                                        />
                                                        <Route
                                                          path="/project-join"
                                                          element={<JoinAProject />}
                                                        />
                                                        <Route
                                                          path="/projects/:id"
                                                          element={<ProjectDetails />}
                                                        />
                                                        <Route
                                                          path="/projects/:id/edit"
                                                          element={<EditProject />}
                                                        />
                                                        <Route
                                                          path="/project-manage"
                                                          element={<ProjectManage />}
                                                        />
                                                        <Route
                                                          path="/settings"
                                                          element={<Settings />}
                                                        />
                                                        <Route
                                                          path="/subscription-status"
                                                          element={
                                                            <SubscriptionStatus />
                                                          }
                                                        />
                                                        <Route
                                                          path="/user-ratings"
                                                          element={
                                                            <UserRatings />
                                                          }
                                                        />
                                                        <Route
                                                          path="/privacy&policy"
                                                          element={
                                                            <PrivacyAndPolicy />
                                                          }
                                                        />
                                                        <Route
                                                          path="/terms-of-service"
                                                          element={<TermsOfService />}
                                                        />
                                                        <Route
                                                          path="/email-preferences"
                                                          element={
                                                            <EmailPreferences />
                                                          }
                                                        />
                                                        <Route
                                                          path="/creators/:id"
                                                          element={
                                                            <PublicProfileView />
                                                          }
                                                        />
                                                        <Route
                                                          path="/onboarding"
                                                          element={
                                                            <CreatorRegistration />
                                                          }
                                                        />
                                                        <Route
                                                          path="/signup"
                                                          element={
                                                            <CreatorReversedRegistration />
                                                          }
                                                        />

                                                        <Route
                                                          path="*"
                                                          element={
                                                            <h1>
                                                              404 Page not found
                                                            </h1>
                                                          }
                                                        />
                                                      </Routes>
                                                      <BottomNavBar />
                                                    </>
                                                  )}
                                                </ManageProjectsContextProvider>
                                              </DiscoverContextProvider>
                                            </ProjectFiltersProvider>
                                          </ProfileCompleteContextProvider>
                                        </SettingsContextProvider>
                                      </RoleContextProvider>
                                    </SkillImageContextProvider>
                                  </ProfileImageContextProvider>
                                </SearchContextProvider>
                              </UserContextProvider>
                            </AddSkillContextProvider>
                          </EditSkillContextProvider>
                        </SkillsBarContextProvider>
                      </RegistrationContextProvider>
                    </ReversedRegistrationContextProvider>
                  </LoadingContextProvider>
                </ProjectImageContextProvider>
              </ProjectDetailsContextProvider>
            </BrowserRouter>
          </ThemeProvider>
        </InfoBoxsContextProvider>
      </Box>
    </main>
  );
}

export default Sentry.withProfiler(App);
