import React, { useContext, useEffect, useState } from "react";
import styles from "./step2.module.css";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { RegistrationContext } from "../../../context/registration-context";
import { ChevronRight } from "@mui/icons-material";
import { useTheme } from "../../../context/theme-context";

const Step2 = ({ onButtonClick, collaboratorsTypes }) => {
  const { theme } = useTheme();
  const [checked, setChecked] = React.useState([]);
  const { creatorTypes, setCreatorTypes } = useContext(RegistrationContext);

  const handleToggle = (value) => () => {
    const itemIndex = creatorTypes.findIndex(
      (item) => item.category === value.category
    );
    if (itemIndex === -1) {
      if (creatorTypes.length === 3) return;
      return setCreatorTypes((items) => [...items, value]);
    }
    // item exists remove it
    const filteredItems = creatorTypes.filter(
      (item) => item.category !== value.category
    );
    setCreatorTypes([...filteredItems]);
  };

  useEffect(() => {
    console.log('test collaborators types', collaboratorsTypes)
  },[])

  if (!collaboratorsTypes) {
    return (
      <div className={styles.form}>
        <Typography variant="body1"
          color={theme.palette.darkgrey.darkgrey600}
          sx={{
            fontSize: '18px',
            fontWeight: '700',
          }}
        >
          Choose up to 3 creator types which best describe your skill sets:
        </Typography>
        <Box sx={{p: '1.5rem'}}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            endIcon={
              <ChevronRight/>
            }
            sx={{
              p: "8px 13px 8px 24px",
              fontSize: '18px',
              fontWeight: '700',
              background: 'linear-gradient(88deg, #F00 0.46%, #F74E00 21.46%, #F90 46.78%, #AB168F 98.21%)',
              display: 'flex',
              justifyContent: 'space-between',
              "& .css-9tj150-MuiButton-endIcon": {
                background: 'linear-gradient(196deg, #F407C7 6.9%, #FC0079 90.91%)',
                height: '2em',
                width: '2em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
              }
            }}
            onClick={onButtonClick}
          >
            Next Step
          </Button>
        </Box>
      </div>
    );
  }

  return (
    <Box className={styles.form}>
      <Box
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey400,
          p: '1.5rem',
        }}
      >
        <Typography variant="body1"
          color={theme.palette.darkgrey.darkgrey600}
          sx={{
            fontSize: '18px',
            fontWeight: '700',
          }}
        >
          Choose up to 3 creator types which best describe your skill sets:
        </Typography>
        <List sx={{
          width: "100%",
          mt: "24px",
          m: 0,
          color: theme.palette.darkgrey.darkgrey500,
        }}>
          {collaboratorsTypes.map((type) => {
            const labelId = `checkbox-list-label-${type.category}`;
            return (
              <ListItem key={labelId} disablePadding
                sx={{
                  padding: 0,
                  '& .css-cveggr-MuiListItemIcon-root': {
                    minWidth: 'auto',
                  }
                }}
              >
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(type)}
                  dense
                  sx={{
                    paddingLeft: '0px',
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={
                        creatorTypes.findIndex(
                          (item) => item.category === type.category
                        ) !== -1
                      }
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={`${type.creator_description}`}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Box sx={{p: '1.5rem'}}>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          endIcon={
            <ChevronRight/>
          }
          sx={{
            p: "8px 13px 8px 24px",
            justifyContent: 'space-between',
            "& span:nth-of-type(1)": {
              background: theme.palette.primary.purpel400,
              height: '2em',
              width: '2em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
            }
          }}
          onClick={onButtonClick}
        >
          Next Step
        </Button>
      </Box>
    </Box>
  );
};

export default Step2;
