import { useContext, useEffect } from "react";
import { ManageProjectsContext } from "../../../../../../context/manage-projects-context";
import Card from "./card";
import { Box, Typography } from "@mui/material";
import styles from './style.module.css';
import { useTheme } from "../../../../../../context/theme-context";
import { UserContext } from "../../../../../../context/user-context";

const Liked = () => {
    const { likedProjects, fetchLikedProjects } = useContext(ManageProjectsContext);
    const { theme, darkMode } = useTheme();
    const { user } = useContext(UserContext);

    useEffect(() => {
        fetchLikedProjects(user);
    }, [])

    const renderProjects = likedProjects?.map((project, index) => {
        return (
            <Card project={project} key={index} />
        )
    })
    
    return (
        <Box className={styles.hosted}>
            <Typography
                variant="body1"
                sx={{
                color: theme.palette.darkgrey.darkgrey600,
                fontSize: "24px",
                fontWeight: "500",
                }}
            >
                {likedProjects.length} Liked Projects
            </Typography>
            <Box className={styles.liked}>
                {renderProjects}
            </Box>
        </Box>
    );
};

export default Liked;

