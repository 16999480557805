import React, { useContext, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import styles from "./style.module.css";
import Basics from "./components/basics";
import Contact from "./components/contact";
import RolesList from "./components/RolesList";

import { useNavigate, useParams } from "react-router-dom";
import supabase from "../../../../config/supabaseClient";
import { RoleContext } from "../../../../context/role-context";
import moment from "moment";
import { UserContext } from "../../../../context/user-context";
import { useTheme } from "../../../../context/theme-context";
import ProjectCoverImage from "./components/project-cover-image.js";
import { ProjectImageContext } from "../../../../context/project-cover-image-context";
import ExprienceLevel from "./components/exprience-level";
import AgeRanges from "./components/age-range";
import { ManageProjectsContext } from "../../../../context/manage-projects-context";
import AppModal from "../../Manage-project/components/tabs/hosted/app-modal";
import { creatorDefaultImages } from "../../../../constants";

const ProjectForm = () => {
  const { id } = useParams();
  const { theme } = useTheme();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedExprienceLevel, setSelectedExprienceLevel] =
    useState("Advanced or higher");
  const [selectedAgeRanges, setSelectedAgeRanges] = useState([]);
  const [title, setTitle] = useState("");
  const [primaryEntity, setPrimaryEntity] = useState("");
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [locationType, setLocationType] = useState("Local");
  const [emailAddress, setEmailAddress] = useState("");
  const { roles, setRoles } = useContext(RoleContext);
  const { user } = useContext(UserContext);
  const { croppedImage, setCroppedImage, setOriginalProjectImage, originalProjectImage } = useContext(ProjectImageContext);
  const [ageRanges, setAgeRanges] = useState([]);
  const [selectedCreatorTypes, setSelectedCreatorTypes] = useState([]);
  const [status, setStatus] = useState();
  const [project, setProject] = useState();
  const navigate = useNavigate();
  const { fetchProjects, setEditNavigate, editNavigate, isAppModalOpen, setIsAppModalOpen } = useContext(ManageProjectsContext);

  const setProjectFields = (project) => {
    setSelectedCategory(project.category);
    setSelectedExprienceLevel(project.experience_level)
    setTitle(project.title);
    setPrimaryEntity(project.primary_entity);
    setDescription(project.description);
    setCountry(project.location_name);
    setLocationType(project.location_type);
    setEmailAddress(project.contact_email);
    setCroppedImage(project.project_cover_image);
    setStatus(project.status);
    setProject(project);
    setOriginalProjectImage(project.original_cover_image);

  }
  const setCreatorTypes = (data) => {
    const tempCreatorTypes = data.map((item) => {
      return item;
    })
    console.log(tempCreatorTypes, 'test Temp creator types');
    setSelectedCreatorTypes(tempCreatorTypes)
  }
  useEffect(() => {
    const fetchProject = async() => {
      const { data, error } = await supabase
        .from('projects')
        .select('*').eq('id', id);    
      setProjectFields(data[0]);
    }
    const fetchCreatorTypes = async () => {
      const { data, error } = await supabase
        .from("projects_creator_types")
        .select('*')
        .eq('project_id', id);
      setCreatorTypes(data);
      // console.log(data, 'test creator types');
    }
    const fetchRoles = async () => {
      const { data, error } = await supabase
        .from("projects_roles")
        .select('*')
        .eq('project_id', id);
      if (data.length > 0) {
        const tempRoles = data.map((item, index) => {
          return { 
            selectedCategory: item.category,
            selectedSkill: item.creator_skill,
            selectedSpecialty: item.speciality,
            num: index + 1
          };
        }) 
        console.log(tempRoles, 'test coming roles');
        setRoles(tempRoles);
      }
      console.log(data, 'selectd rols from project edit ');
    }
    const fetchAgeRanges = async () => {
      const { data, error } = await supabase
        .from("projects_age_ranges")
        .select('*')
        .eq('project_id', id);
      const tempAgeRanges = data.map((item) => {
        return item.age_range;        
      })
      setAgeRanges(tempAgeRanges);
      // console.log(tempAgeRanges, 'selectd age Ranges from project edit ');
    }
    fetchProject();
    fetchCreatorTypes();
    fetchRoles();
    fetchAgeRanges();
  },[id])

  const basicProps = {
    title: title,
    setTitle: setTitle,
    primaryEntity,
    setPrimaryEntity,
    description: description,
    setDescription: setDescription,
    country: country,
    setCountry: setCountry,
    selectedCategory,
    setSelectedCategory,
    locationType,
    setLocationType,
    selectedCreatorTypes,
    setSelectedCreatorTypes,
  };

  const contactProps = {
    emailAddress: emailAddress,
    setEmailAddress: setEmailAddress,
  };
  const exprienceProps = {
    selectedExprienceLevel,
    setSelectedExprienceLevel,
  };
  const ageRangesProps = {
    ageRanges,
    setAgeRanges,
  };

  const handleNavigate = () => {
    navigate("/job-submission");
  };

  useEffect(() => {
    console.log(roles, 'test roles change');
  },[roles])

  const handleSubmitProject = async () => {
    await supabase
      .from("projects_roles")
      .delete()
      .eq('project_id', id);
    
    await supabase
      .from("projects_creator_types")
      .delete()
      .eq('project_id', id);
    
    await supabase
      .from("projects_age_ranges")
      .delete()
      .eq('project_id', id);
    
      let original = originalProjectImage || null;
      let cropp = croppedImage || null;
      
      if (originalProjectImage === null && selectedCreatorTypes.length !== 0) {
        const targetCreatorType = selectedCreatorTypes[0].category;
        const targetImage = creatorDefaultImages[targetCreatorType];
        console.log("Hello, I'm still null", targetImage);
        original = targetImage;
        cropp = targetImage;
      }
    
    const { data, error } = await supabase
      .from("projects")
      .update({
        title: title,
        user_id: user.id,
        primary_entity: primaryEntity,
        description: description,
        location: country,
        status: status,
        contact_email: emailAddress,
        submitted_by: user.id,
        created_at: new Date(),
        project_cover_image: cropp,
        original_cover_image: original,
        category: selectedCategory,
        location_type: locationType,
        location_name: country,
        experience_level: selectedExprienceLevel,
      })
      .eq('id', id);

    if (data) {
    } else if (error) {
      console.error("Error inserting into projects table:", error);
    }

    // const { error: projects_status } = await supabase
    //   .from("projects_status")
    //   .insert({
    //     project_id: project.id,
    //     status: "Under Review",
    //     date_acquired: moment().format("YYYY-MM-DD"),
    //   });
    await roles.map(async (item) => {
      const record = {
        project_id: project.id,
        category: item.selectedCategory,
        creator_skill: item.selectedSkill,
        speciality: item.selectedSpecialty,
      };
      console.log(record, 'this is record roles');
      const { error: projects_roles } = await supabase
        .from("projects_roles")
        .insert(record);
    });

    await supabase.from("projects_creator_types").insert(
      selectedCreatorTypes.map((item) => ({
        project_id: project.id,
        creator_type: item.creator_type,
        category: item.category,
        collaborator_category: item.collaborator_category,
      }))
    );
    await supabase
      .from("projects_age_ranges")
      .insert(
        ageRanges.map((item) => ({ project_id: project.id, age_range: item }))
      );
    setRoles([]);
    // help to go back to previous page
    handleCancel();
    
    setCroppedImage('https://lynx-react-images.s3.us-east-2.amazonaws.com/project-cover-image-0.48025884403475594-v-1710946773530');
    setOriginalProjectImage(null);
    fetchProjects();
  };

  const handleCancel = () => {
    if (editNavigate) {
      navigate(`/project-manage`);
      setEditNavigate(false);
    } else {
      navigate(`/projects/${id}`);
    }
    setCroppedImage('https://lynx-react-images.s3.us-east-2.amazonaws.com/project-cover-image-0.48025884403475594-v-1710946773530');
    setOriginalProjectImage(null);

  };

  return (
    <Box component="form" className={styles.projectForm}>
      <ProjectCoverImage project={project} />
      <Basics {...basicProps} />

      <RolesList roles={roles}/>
      <AgeRanges {...ageRangesProps} />
      <ExprienceLevel {...exprienceProps} />
      <Contact {...contactProps} />
      <Box className={styles.button}>
        <Button variant="contained" onClick={handleSubmitProject} fullWidth>
          Save
        </Button>
        <Button
          onClick={handleCancel}
          variant="text"
          fullWidth
          sx={{
            color: theme.palette.darkgrey.darkgrey400,
            textTransform: "capitalize",
            fontSize: "16px",
            fontWeight: "500",
            p: "0px",
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectForm;
