import React, { useContext, useState } from "react";
import { Box, Button } from "@mui/material";
import styles from "./style.module.css";
import Basics from "./components/basics";
import Contact from "./components/contact";
import RolesList from "./components/RolesList";

import { useNavigate } from "react-router-dom";
import supabase from "../../../../../config/supabaseClient";
import { RoleContext } from "../../../../../context/role-context";
import moment from "moment";
import { UserContext } from "../../../../../context/user-context";
import { useTheme } from "../../../../../context/theme-context";
import ProjectCoverImage from "./components/project-cover-image.js";
import { ProjectImageContext } from "../../../../../context/project-cover-image-context";
import ExprienceLevel from "./components/exprience-level";
import AgeRanges from "./components/age-range";
import { ManageProjectsContext } from "../../../../../context/manage-projects-context";
import { sendProjectSubmissionEmail } from "../../../../../helpers/emails";
import { creatorDefaultImages } from "../../../../../constants";

const ProjectForm = ({ setIsOpen }) => {
  const { theme } = useTheme();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedExprienceLevel, setSelectedExprienceLevel] =
    useState("All");
  const [selectedAgeRanges, setSelectedAgeRanges] = useState([]);
  const [title, setTitle] = useState("");
  const [primaryEntity, setPrimaryEntity] = useState("");
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [locationType, setLocationType] = useState("Local");
  const [emailAddress, setEmailAddress] = useState("");
  const { roles, setRoles } = useContext(RoleContext);
  const { user } = useContext(UserContext);
  const { croppedImage, setCroppedImage, setOriginalProjectImage, originalProjectImage } = useContext(ProjectImageContext);
  const [ageRanges, setAgeRanges] = useState([]);
  const [selectedCreatorTypes, setSelectedCreatorTypes] = useState([]);
  const navigate = useNavigate();
  const { fetchProjects } = useContext(ManageProjectsContext);


  const basicProps = {
    title: title,
    setTitle: setTitle,
    primaryEntity,
    setPrimaryEntity,
    description: description,
    setDescription: setDescription,
    country: country,
    setCountry: setCountry,
    selectedCategory,
    setSelectedCategory,
    locationType,
    setLocationType,
    selectedCreatorTypes,
    setSelectedCreatorTypes,
  };

  const contactProps = {
    emailAddress: emailAddress,
    setEmailAddress: setEmailAddress,
  };
  const exprienceProps = {
    selectedExprienceLevel,
    setSelectedExprienceLevel,
  };
  const ageRangesProps = {
    ageRanges,
    setAgeRanges,
  };

  const handleNavigate = () => {
    navigate("/job-submission");
  };

  const handleSubmitProject = async () => {
    let original = originalProjectImage || null;
    let cropp = croppedImage || null;
    
    if (originalProjectImage === null && selectedCreatorTypes.length !== 0) {
      const targetCreatorType = selectedCreatorTypes[0].category;
      const targetImage = creatorDefaultImages[targetCreatorType];
      console.log("Hello, I'm still null", targetImage);
      original = targetImage;
      cropp = targetImage;
    }
    
    const { data, error } = await supabase
      .from("projects")
      .insert({
        title: title,
        user_id: user.id,
        primary_entity: primaryEntity,
        description: description,
        location: country,
        status: "Under Review",
        contact_email: emailAddress,
        submitted_by: user.id,
        created_at: new Date(),
        project_cover_image: cropp,
        original_cover_image: original,
        category: selectedCategory,
        location_type: locationType,
        location_name: country,
        experience_level: selectedExprienceLevel,
      })
      .select();

    const project = data[0];
    console.log(data, "from data after submitting the project");
    if (data) {
    } else if (error) {
      console.error("Error inserting into projects table:", error);
    }

    // const { error: projects_status } = await supabase
    //   .from("projects_status")
    //   .insert({
    //     project_id: project.id,
    //     status: "Under Review",
    //     date_acquired: moment().format("YYYY-MM-DD"),
    //   });
    await roles.map(async (item) => {
      const record = {
        project_id: project.id,
        category: item.selectedCategory,
        creator_skill: item.selectedSkill,
        speciality: item.selectedSpecialty,
      };

      const { error: projects_roles } = await supabase
        .from("projects_roles")
        .insert(record);
    });

    await supabase.from("projects_creator_types").insert(
      selectedCreatorTypes.map((item) => ({
        project_id: project.id,
        creator_type: item.creator_type,
        category: item.category,
        collaborator_category: item.collaborator_category,
      }))
    );
    await supabase
      .from("projects_age_ranges")
      .insert(
        ageRanges.map((item) => ({ project_id: project.id, age_range: item }))
      );
    setRoles([
      {
        num: 1,
        selectedCategory: "",
        selectedSkill: "",
        selectedSpecialty: "",
      },
    ]);
    await sendProjectSubmissionEmail(user.id, project.id);
    await setIsOpen(true);
    fetchProjects();
    setCroppedImage('https://lynx-react-images.s3.us-east-2.amazonaws.com/project-cover-image-0.48025884403475594-v-1710946773530');
    setOriginalProjectImage(null);
  };

  const handleCancel = () => {
    setCroppedImage('https://lynx-react-images.s3.us-east-2.amazonaws.com/project-cover-image-0.48025884403475594-v-1710946773530');
    setOriginalProjectImage(null);
    navigate("/projects");
  };

  return (
    <Box component="form" className={styles.projectForm}>
      <ProjectCoverImage />
      <Basics {...basicProps} />

      <RolesList />
      <AgeRanges {...ageRangesProps} />
      <ExprienceLevel {...exprienceProps} />
      <Contact {...contactProps} />
      <Box className={styles.button}>
        <Button variant="contained" onClick={handleSubmitProject} fullWidth>
          submit
        </Button>
        <Button
          onClick={handleCancel}
          variant="text"
          fullWidth
          sx={{
            color: theme.palette.darkgrey.darkgrey400,
            textTransform: "capitalize",
            fontSize: "16px",
            fontWeight: "500",
            p: "0px",
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectForm;
