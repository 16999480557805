import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "../../../../../../../../../context/theme-context";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../../../../../../context/user-context";
import { ProjectDetailsContext } from "../../../../../../../../../context/project-details-context";
import styles from "./style.module.css";
import supabase from "../../../../../../../../../config/supabaseClient";
import { ManageProjectsContext } from "../../../../../../../../../context/manage-projects-context";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingContext } from "../../../../../../../../../context/loading-context";
import { useNavigate, useParams } from "react-router-dom";
import DeleteConfirmation from "./delete-confirmation";
import { sendProjectApplyConfirmationEmail } from "../../../../../../../../../helpers/emails";
// import { add_new_project_by_host, add_project_by_user, add_project_is_cancelled_by_user } from "../../../../../../../../../helpers/emails";

const Apply = ({ project }) => {
  const { theme, darkMode } = useTheme();
  const { user } = useContext(UserContext);
  const { status, setStatus } = useContext(ProjectDetailsContext);
  const { fetchApplications } = useContext(ManageProjectsContext);
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const { setIsLoading } = useContext(LoadingContext);
  const navigate = useNavigate(); 
  const { id } = useParams();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const handleEditClick = () => {
    navigate(`/projects/${id}/edit`);    
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabase
        .from("projects_applications")
        .select("status")
        .eq("project_id", project.id)
        .eq("user_id", user.id);

      if (error) {
        throw error;
      }

      // Assuming there's only one result
      if (data && data.length > 0) {
        setStatus(data[0].status);
        console.log(data[0].status, "dtafrom apply");
      } else {
        setStatus("");
      }
    };
    if (user?.id && project.id) {
      fetchData();
    }
    console.log(project, 'test project from applay');
  }, [user, project]); // Run effect when projectId or userId changes

  const handleApplyClick = async () => {
    const { data, error } = await supabase
      .from("projects_applications")
      .insert([{ project_id: project.id, user_id: user.id }]);

    setStatus("Pending");
    fetchApplications();

    /// active campign
    await sendProjectApplyConfirmationEmail(user.id, project.id);
    
    const { data: creatorTypes } = await supabase
      .from("creator_types")
      .select("*")
      .eq('user_id', id);
    
    // add_new_project_by_host(user, project, creatorTypes.slice(0, 3));
    // add_project_by_user(user, project);

  };

  const handleCancel = async (project) => {
    // handle cancel here
    const { data, error } = await supabase
      .from("projects_applications")
      .delete()
      .eq("project_id", project.id)
      .eq("user_id", user.id);

    setStatus("");

    // active campaign
    // add_project_is_cancelled_by_user(user, project);
  };

  if (!user) {
    return (
      <Box className={styles.login}>
        <Typography
          variant="body1"
          sx={{
            color: darkMode
              ? theme.palette.darkgrey.darkgreybody50
              : theme.palette.darkgrey.darkgrey500,
            fontSize: "18px",
            textAlign: "center",
          }}
        >
          To apply to this project, please
          <span
            onClick={async () => {
              setIsLoading(true);
              await loginWithRedirect({ connection: "google-oauth2" });
              setIsLoading(false);
            }}
            style={{
              color: darkMode
                ? theme.palette.secondary.blue300
                : theme.palette.secondary.main,
              cursor: "pointer",
            }}
          >
            {" "}
            login{" "}
          </span>
          or
          <span
            onClick={() => {
                navigate('/signup')
            }}
            style={{
              color: theme.palette.secondary.main,
              cursor: "pointer",
            }}
          >
            {" "}
            create an account
          </span>
          .
        </Typography>
      </Box>
    );
  }

  if (user.id === project.user_id) {
    return (
      <Button variant="contained" fullWidth onClick={handleEditClick}>
          Edit
      </Button>
    );
  }

  return (
    <>
      {project.status === "Completed" ? (
        <Button
          variant="contained"
          fullWidth
          sx={{
            bgcolor: "#00B0F0",
          }}
        >
          Completed
        </Button>
      ) : (    
        status === "Pending" && (
          <Button
            fullWidth
            sx={{
              bgcolor: darkMode? '#5C595C': theme.palette.darkgrey.darkgrey500,
              color:  theme.palette.lightgrey.lightgrey950,
            }}
            disabled
          >
            Applied
          </Button>
        ) ||
        status === "" && (
          <Button variant="contained" fullWidth onClick={handleApplyClick}>
            Apply Now
          </Button>
        ) ||
        status === "Accepted" && (
          <Box className={styles.button}>
            <DeleteConfirmation
              isOpen={isCancelModalOpen}
              setIsOpen={setIsCancelModalOpen}
              onDelete={handleCancel}
              project={project}
              text={'cancel'}
            />
            <Button
              variant="contained"
              fullWidth
              sx={{
                bgcolor: theme.palette.success.dark,
              }}
            >
              Accepted
            </Button>
            <Button
              onClick={()=> setIsCancelModalOpen(true)}
              variant="text"
              fullWidth
              sx={{
                color: theme.palette.darkgrey.darkgrey400,
                textTransform: "capitalize",
                fontSize: "16px",
                fontWeight: "500",
                p: "0px",
              }}
            >
              Cancel
            </Button>
          </Box>
        )
      )}
    </>
  );
};

export default Apply;
