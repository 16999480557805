import { Typography } from "@mui/material"
import { useTheme } from "../../../../../../../../../../context/theme-context"
import { useContext } from "react";
import { UserContext } from "../../../../../../../../../../context/user-context";

const ContactEmail = ({email}) => {
    const { theme, darkMode } = useTheme();
    const { user } = useContext(UserContext);

    if (!user) {
        return <></>
    }
    return (
        <Typography variant="body1"
            sx={{
                color: darkMode ? theme.palette.darkgrey.darkgreybody50
                    : theme.palette.darkgrey.darkgrey400,
                fontSize: '16px',
                fontWeight: '500',
            }}
        >{email}</Typography> 
    )
};

export default ContactEmail;