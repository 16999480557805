import { Box, Button, Typography } from '@mui/material';
import styles from './style.module.css';
import { useTheme } from '../../../context/theme-context';
import BlueHeader from '../components/blue-header';
import { useNavigate } from 'react-router-dom';

const Incomplete = () => {
    const { theme, darkMode } = useTheme();
    const navigate = useNavigate();

    return (
        <Box className={styles.container}
            sx={{
                width: '100%',
            }}
        >
            <Box className={styles.topGradient}>
                <BlueHeader />
            </Box>
            <Box className={styles.basics}
                sx={{ bgcolor: theme.palette.lightgrey.lightgrey500 }}
            >
                <Box className={styles.icon}
                    sx={{bgcolor: theme.palette.lightgrey.lightgrey500}}
                >
                    <Box className={styles.successIcon}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none">
                            <path d="M29.3294 18.333H14.6628C12.6377 18.333 10.9961 19.9746 10.9961 21.9997V36.6663C10.9961 38.6914 12.6377 40.333 14.6628 40.333H29.3294C31.3545 40.333 32.9961 38.6914 32.9961 36.6663V21.9997C32.9961 19.9746 31.3545 18.333 29.3294 18.333Z" stroke="white" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.9961 62.3333L18.3294 69.6667L32.9961 55" stroke="white" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M47.6641 22H76.9974" stroke="white" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M47.6641 44H76.9974" stroke="white" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M47.6641 66H76.9974" stroke="white" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </Box>
                </Box>
                <Box className={styles.content}>
                    <Box className={styles.text}>
                        <Typography variant='body1'
                            sx={{
                                color: theme.palette.darkgrey.darkgrey700,
                                fontSize: '1.5rem',
                                fontWeight: 700,
                                textAlign: 'center',
                            }}
                        >
                            Need More Info!
                        </Typography>
                        <Typography variant='body1'
                            sx={{
                                color: theme.palette.darkgrey.darkgrey500,
                                fontSize: '1rem',
                                fontWeight: 400,
                                textAlign: 'center',
                            }}
                        >
                            Your profile is incomplete. We can't find good matches.
                        </Typography>
                    </Box>
                    <Button variant='contained' fullWidth
                        onClick={()=> navigate('/profile')}
                        sx={{ bgcolor: '#00B0F0' }}
                    >Complete My Profile</Button>
                </Box>
            </Box>
        </Box>
    );
}

export default Incomplete;