import { Box } from "@mui/material";
import styles from "./style.module.css";
import Card from "./card";
import { useContext } from "react";
import { DiscoverContext } from "../../../../../context/discover-context";
import LoadingButton from "../../../../../components/loading/button";
import ProjectCard from "./projectCard";
import { useState } from "react";
import ConfettiComponent from "../../../../../components/common/FireWorks";

const ListResult = ({ projects }) => {
  const { loading } = useContext(DiscoverContext);

  return (
    <Box className={styles.result}>
      {projects.map((project, index) => (
        project.key === "user" && <Card key={index} project={project} /> ||
        project.key === "project" && <ProjectCard key={index} project={project} />
      ))}
      {loading && <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><LoadingButton /></Box> }
    </Box>
  );
};

export default ListResult;
