import { Box, Button, Divider } from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import Logo from "./logo";
import InputFilter from "./input-filter";
import { useTheme } from "../../../context/theme-context";

const CreatorSearch = () => {
  const { theme } = useTheme();

  return (
    <Box
      className={styles.search}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey500,
        minHeight: "100vh",
      }}
    >
      <Box
        className={styles.container}
        sx={{
          width: {
            xs: "100%",
            //sm: "75%",
            //md: "50%",
          },
        }}
      >
        <Logo />
        <Divider
          sx={{
            width: {
              xs: "100%",
              color: theme.palette.darkgrey.darkgrey400,
            },
          }}
        >
          Or
        </Divider>
        <InputFilter />
      </Box>
    </Box>
  );
};

export default CreatorSearch;
