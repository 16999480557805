import { Box, Chip, Typography } from "@mui/material";
import React, { useContext } from "react";
import styles from "./style.module.css";
import image from "../../../../../../../assets/images/profileImage.png";
import { useTheme } from "../../../../../../../context/theme-context";
import { Favorite, FavoriteBorderOutlined, FavoriteOutlined } from "@mui/icons-material";
import { ManageProjectsContext } from "../../../../../../../context/manage-projects-context";

const Top = ({ project, isFavorite, setIsFavorite }) => {
  const { theme } = useTheme();
  const { handleFavorite } = useContext(ManageProjectsContext);

  const addToFavorite = (id) => {
    setIsFavorite(true);
    handleFavorite(id, 'add');
  }
  const removeFromFavorite = (id) => {
    setIsFavorite(false);
    handleFavorite(id, 'remove')
  }

  return (
    <Box className={styles.top}>
      <Box className={styles.profile}>
        <Box
          className={styles.image}
          sx={{
            backgroundImage: `url(${project.profile_image})`,
            width: "48px",
            height: "48px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        ></Box>
        <Box className={styles.name}>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.darkgrey.darkgrey600,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 1,
              textOverflow: "ellipsis",
            }}
          >
            {project.real_name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.darkgrey.darkgrey300,
            }}
          >
            {project.nick_name}
          </Typography>
        </Box>
        <Box
          className={styles.options}
          sx={{
            "& .css-ixzp6w-MuiChip-root": {
              height: "auto",
            },
          }}
        >
          <Chip
            label={project.category}
            sx={{
              bgcolor: project.category === "Brand" && "#EC7508" ||
                project.category === "Cause" && "#05B240" ||
                project.category === "Creator" && "#09B999" ||
                project.category === "Media" && "#B90988",
              color: "#FFF",
              fontSize: "12px",
              // fontWeight: '500',
              p: "4px 12px",
              "& .css-193rxqu-MuiChip-label": {
                lineHeight: "inherit",
              },
              height: 'auto'
            }}
          />
          {isFavorite ? (
            <Favorite
              sx={{ fill: theme.palette.primary.main }}
              onClick={()=>{removeFromFavorite(project.project_id)}}
            />
          
          ) : (
              <FavoriteBorderOutlined
                sx={{ fill: theme.palette.primary.main }}
                onClick={()=>{addToFavorite(project.project_id)}}
              />   
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Top;
