import React, { useState } from 'react';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import styles from './style.module.css';
import { useTheme } from '../../../../../../context/theme-context';

const ExprienceLevel = (props) => {
    const { theme } = useTheme();
    const { selectedExprienceLevel, setSelectedExprienceLevel } = props;
    const [exprienceList, setExprienceList] = useState(['All', 'Advanced or higher', 'Pros only']);

    const handleChangeExprience = (event, newValue) => {
        setSelectedExprienceLevel(newValue);
    };

    return (
        <Box className={styles.inputField}>
            <Typography
                variant="body1"
                color={theme.palette.darkgrey.darkgrey600}
                fontWeight={500}
            >
                Experience level
            </Typography>
            <Autocomplete
                sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
                options={exprienceList}
                getOptionLabel={(option) => option}
                value={selectedExprienceLevel}
                onChange={handleChangeExprience}
                fullWidth
                renderInput={(params) => (
                    <TextField {...params} label="" variant="outlined" />
                )}
            />
        </Box>
    );
}

export default ExprienceLevel;