import { Add } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useTheme } from "../../../../../../../../../context/theme-context";

const AddNewRoleButton = ({ handleModal, handleAddRole }) => {
  const { theme } = useTheme();

  const handleClick = () => {
    handleModal(true);
    handleAddRole();
  };

  return (
    <Button
      variant="text"
      startIcon={<Add />}
      onClick={handleClick}
      sx={{
        color: theme.palette.darkgrey.darkgrey400,
        textTransform: "capitalize",
        fontSize: "16px",
        fontWeight: "400",
        padding: 0,
      }}
    >
      Add New Role
    </Button>
  );
};

export default AddNewRoleButton;
