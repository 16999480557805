import React, { useContext, useState } from "react";
import { Box, Button, Chip } from "@mui/material";
import styles from "./style.module.css";
import { FileUploadOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { SkillImageContext } from "../../../../../../context/skill-image-context";
import { useTheme } from "../../../../../../context/theme-context";
const CoverImageUploader = () => {
  const { setIsModalSkillOpen} = useContext(SkillImageContext);
  const { theme, darkMode } = useTheme();
  
  return (
    <Box
      className={styles.primarySkills}
      sx={{
        borderColor: theme.palette.lightgrey.lightgrey800,
        background: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Box className={styles.content}>
        <Button
          variant="text"
          onClick={()=> setIsModalSkillOpen(true)}
          startIcon={<FileUploadOutlined />}
          sx={{
            color: darkMode
              ? theme.palette.darkgrey.darkgrey300
              : theme.palette.darkgrey.darkgrey400,
            textTransform: "capitalize",
          }}
        >
          Upload skill cover image
        </Button>
      </Box>
    </Box>
  );
};
export default CoverImageUploader;
