import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import { useTheme } from "../../../../../../../context/theme-context";

const Top = ({ name, nickName, image, label }) => {
  const { theme } = useTheme();

  return (
    <Box className={styles.top}>
      <Box className={styles.profile}>
        <Box
          className={styles.image}
          sx={{
            backgroundImage: `url(${image})`,
            width: "48px",
            height: "48px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        ></Box>
        <Box className={styles.name}>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.darkgrey.darkgrey600,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 1,
              textOverflow: "ellipsis",
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.darkgrey.darkgrey300,
            }}
          >
            {nickName}
          </Typography>
        </Box>
        <Box className={styles.options}>
          <Chip
            label={label}
            sx={{
              bgcolor: label === "Brand" && "#EC7508" ||
                label === "Cause" && "#05B240" ||
                label === "Creator" && "#09B999" ||
                label === "Media" && "#B90988",
              color: "#FFF",
              fontSize: '12px',
              p: "4px 12px",
              "& .css-193rxqu-MuiChip-label": {
                lineHeight: "inherit",
              },
              height: 'auto',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Top;
