import { useContext, useEffect } from "react"
import { UserContext } from "../context/user-context"
import supabase from "../config/supabaseClient";
import axios from "axios";
import { defaultProfileImageSquare } from "../constants";
import generateUserFeed from "./generateUserFeed";

const checkProfileComplete = async (user, setUser) => {
    // check if user have skill completed
    let updatedUser = user; 
    const { data: skillsData, error: skillsError } = await supabase
        .from("creator_skills")
        .select("*")
        .eq('user_id', user.id);
    const isSkillComplete = skillsData.map((skill) => {
        if (skill.name &&
            skill.description &&
            skill.level &&
            skill.started_training_age
        ) return true;       
    })

    // check if user have creators
    const { data: creatorData, error: creatorError } = await supabase
        .from('creator_desired_collaborators')
        .select("*")
        .eq('user_id', user.id);

    // check if profile complete or not
    if (
        isSkillComplete.includes(true)
        && user.profile_image !== null
        && user.profile_image !== defaultProfileImageSquare
        && creatorData.length > 0
    ) {
        if (user.is_profile_complete === false) { 
            //update profile status
            await supabase.from('users').update({
                is_profile_complete: true,
            }).eq('id', user.id);  
            console.log('check every thing from true');
            // update is_active status
            await supabase.from('user_community_status').update({
                is_active: false,
            })
            .eq('status', 'Incomplete')
            .eq('user_id', user.id)

            // update user context
            const { data } = await supabase
                .from('users')
                .select("*")
                .eq('id', user.id);
           
            setUser(data[0]);
            updatedUser = data[0];
            // const response = await generateUserFeed(user);
            // console.log("test feed response: ", response);
            // FeedGenerated = response?.data?.message;
            // return FeedGenerated;
        }
    
    } else {
        if (user.is_profile_complete === true) { 
            //update profile status
            await supabase.from('users').update({
                is_profile_complete: false,
            }).eq('id', user.id);
            console.log('check every thing from false');

            // update is_active status
            await supabase.from('user_community_status').update({
                is_active: true,
            })
            .eq('status', 'Incomplete')
            .eq('user_id', user.id)

            // update user context
            const { data } = await supabase
                .from('users')
                .select("*")
                .eq('id', user.id);
            
            setUser(data[0]);
            updatedUser = data[0]
        } 

    }

    return updatedUser.is_profile_complete;
    // return "User feed ungenerated";

}

export default checkProfileComplete;