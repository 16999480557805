import moment from "moment";
import { createContext, useState } from "react";

export const ProjectImageContext = createContext(null);

const ProjectImageContextProvider = (props) => {
  const [selectedProjectImage, setSelectedProjectImage] = useState(null);
  const [originalProjectImage, setOriginalProjectImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState('https://lynx-react-images.s3.us-east-2.amazonaws.com/project-cover-image-0.48025884403475594-v-1710946773530');
  const [isModalProjectOpen, setIsModalProjectOpen] = useState(false);
  const [isDeleteProjectImageOpen, setIsDeleteProjectImageOpen] = useState(false);

  const contextValue = {
    selectedProjectImage,
    setSelectedProjectImage,
    croppedImage,
    setCroppedImage,
    isModalProjectOpen,
    setIsModalProjectOpen,
    isDeleteProjectImageOpen,
    setIsDeleteProjectImageOpen,
    originalProjectImage,
    setOriginalProjectImage
  };

  return (
    <ProjectImageContext.Provider value={contextValue}>
      {props.children}
    </ProjectImageContext.Provider>
  );
};
export default ProjectImageContextProvider;
