import axios from "axios";

export const getAddressInfo = async (postalCode, country) => {
  try {
    // Construct the API URL
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode},${country}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`;

    // Make a request to the Google Maps Geocoding API
    const response = await axios.get(apiUrl);

    // Check if the API request was successful
    if (response.data.status === "OK") {
      // Extract the relevant information from the response
      const result = response.data.results[0];
      console.log(result, "from result");
      const address_components = result.address_components;
      let state, county, city;
      for (let i = 0; i < address_components.length; i++) {
        const currentRecord = address_components[i];
        if (currentRecord.types.includes("administrative_area_level_2"))
          county = currentRecord.long_name;
        if (currentRecord.types.includes("administrative_area_level_1"))
          state = currentRecord.long_name;
        if (currentRecord.types.includes("locality"))
          city = currentRecord.long_name;
      }
      console.log({ state, county, city });
      if (!city) {
        for (let i = 0; i < address_components.length; i++) {
          const currentRecord = address_components[i];
          if (currentRecord.types.includes("neighborhood"))
            city = currentRecord.long_name;
        }
      }
      return { state, county, city };
    } else {
      // Handle errors
      console.error("Error:", response.data.status);
    }
  } catch (error) {
    // Handle network or other errors
    console.error("Error fetching data:", error.message);
  }
};
