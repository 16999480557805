import React, { useContext } from "react";
import styles from './style.module.css';
import { Box, Button } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { SettingsContext } from "../../../context/settings-context";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../context/theme-context";

const BackButton = () => {
    const { theme } = useTheme();
    const { setOpenLanding } = useContext(SettingsContext)
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/settings');
    }
    return (
            <Box className={styles.arrow}
                sx={{bgcolor: "transparent",}}
            >
                <Button className={styles.backButton}
                    startIcon={<ChevronLeft />}
                    onClick={handleClick}
                sx={{
                    color: theme.palette.darkgrey.darkgrey400,
                    fontSize: '14px',
                    fontWeight: '400',
                }}
                >Back</Button>
            </Box>        
    );
}
export default BackButton;