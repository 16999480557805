import { Box, Chip, Typography } from "@mui/material";
import React, { useContext } from "react";
import styles from "./styles.module.css";
import { UserContext } from "../../../../../context/user-context.js";
import { useTheme } from "../../../../../context/theme-context";

const UserLocation = ({ data }) => {
  const { user } = useContext(UserContext);
  const { theme, darkMode } = useTheme();

  return (
    <Box
      className={styles.communityStatus}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.darkgrey.darkgrey600,
          fontSize: "18px",
          fontWeight: 700,
        }}
      >
        My Location
      </Typography>

      <Box className={styles.content}>
        {data.map((item, index) => (
          <Box key={item.label} className={styles.same}>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.darkgrey.darkgrey300,
              }}
            >
              {item.label}
            </Typography>
            {item.label === "Country" ? (
              <Chip
                label={item.value}
                sx={{
                  color: darkMode
                    ? theme.palette.secondary.blue100
                    : theme.palette.secondary.blue700,
                  bgcolor: !darkMode
                    && theme.palette.secondary.light,
                  fontWeight: 500,
                  fontSize: "14px",
                  height: "32px",
                  "& .css-6od3lo-MuiChip-label": {
                    padding: "0px 16px",
                  },
                }}
              />
            ) : item.label === "Postal Code" ? (
              <Box className={styles.detail}>
                <Typography
                  variant="body1"
                  sx={{
                    color: darkMode
                      ? theme.palette.darkgrey.darkgreybody50
                      :theme.palette.darkgrey.darkgrey600,
                  }}
                >
                  {item.value}
                </Typography>
                {/* <Chip
                  label="Private"
                  sx={{
                    bgcolor: theme.palette.danger.red500,
                    color: theme.palette.lightgrey.lightgrey00,
                    height: "24px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                /> */}
              </Box>
            ) : (
              <Typography
                variant="body1"
                sx={{
                  color: darkMode
                    ? theme.palette.darkgrey.darkgreybody50
                    :theme.palette.darkgrey.darkgrey600,}}
              >
                {item.value}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default UserLocation;
