import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import styles from "./style.module.css";
import { useTheme } from "../../../../../../../../../context/theme-context";
import { useContext, useState } from "react";
import { Link } from "@mui/icons-material";
import NotLogedIn from "../not-loged"
import { UserContext } from "../../../../../../../../../context/user-context";
import Description from "./components/description";
import { defaultProfilePrefix, defaultProjectPrefix } from "../../../../../../../../../constants";



const Content01 = ({projectUser, project}) => {
    const { theme, darkMode } = useTheme();
    const [isCopied, setIsCopied] = useState(false);
    const { user } = useContext(UserContext);

    const handleCopyLink = async (linkToCopy) => {
        try {
          await navigator.clipboard.writeText(linkToCopy);
          setIsCopied(true);
    
          // Set a timeout to reset the isCopied state after 2 seconds
          setTimeout(() => {
            setIsCopied(false);
          }, 2000);
        } catch (err) {
          console.error("Unable to copy to clipboard", err);
        }
      };

    return (
        <Box className={styles.content01}>
            <Box className={styles.textItem}>
                <Typography
                    variant="body1"
                    sx={{
                        color: darkMode ? theme.palette.darkgrey.darkgrey400
                            : theme.palette.darkgrey.darkgrey600,
                        fontSize: "14px",
                        fontWeight: "500",
                    }}
                >
                    Hosted By
                </Typography>
                <Typography component={'a'}
                    href={`/creators/${project.user_id}` }
                    variant="body1"
                    sx={{
                        color: darkMode ? theme.palette.darkgrey.darkgreybody50
                            :theme.palette.darkgrey.darkgrey400,
                        fontSize: "16px",
                        fontWeight: "500",
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    }}
                >
                    {projectUser?.real_name}
                </Typography>
            </Box>
            <Box className={styles.textItem}>
                <Typography
                    variant="body1"
                    sx={{
                        color: darkMode ? theme.palette.darkgrey.darkgrey400
                            : theme.palette.darkgrey.darkgrey600,
                        fontSize: "14px",
                        fontWeight: "500",
                    }}
                >
                    Company, Group or Organization
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        color: darkMode ? theme.palette.darkgrey.darkgreybody50
                            : theme.palette.darkgrey.darkgrey400,
                        fontSize: "16px",
                        fontWeight: "500",
                    }}
                >
                    {project.primary_entity}
                </Typography>
            </Box>
            <Box className={styles.textItem}>
                <Typography
                    variant="body1"
                    sx={{
                        color: darkMode ? theme.palette.darkgrey.darkgrey400
                            :theme.palette.darkgrey.darkgrey600,
                        fontSize: "14px",
                        fontWeight: "500",
                    }}
                >
                    Project Location
                </Typography>
                <Box className={styles.assitsChips}>
                    <Chip
                        label={project?.location_type}
                        sx={{
                            bgcolor: "#00B0F0",
                            color: "#FFF",
                        }}
                    />
                    {project?.location_type !== "Remote" &&
                        <Chip
                            label={project?.location_name}
                            sx={{
                                bgcolor: !darkMode && theme.palette.darkgrey.darkgrey500,
                                color: "#FFF",
                            }}
                        />
                    }
                </Box>
            </Box>
            <Box className={styles.textItem}>
                <Typography
                    variant="body1"
                    sx={{
                        color: darkMode ? theme.palette.darkgrey.darkgrey400
                            : theme.palette.darkgrey.darkgrey600,
                        fontSize: "14px",
                        fontWeight: "500",
                    }}
                >
                    Project Link
                </Typography>
                <Box className={styles.valueContainer}>
                    <Typography
                        variant="body1"
                        sx={{
                            color: darkMode ? theme.palette.darkgrey.darkgreybody50
                                : theme.palette.darkgrey.darkgrey400,
                            fontSize: "16px",
                            fontWeight: "500",
                            textDecoration: "underline",
                        }}
                    >
                        {`${defaultProjectPrefix}${project?.id}`}
                    </Typography>
                    <Box>
                    <IconButton
                        onClick={() =>
                        handleCopyLink(
                            `${defaultProjectPrefix}${project?.id}`
                        )
                        }
                        sx={{
                            bgcolor: darkMode ? "#525252" : theme.palette.secondary.light,
                            display: isCopied ? "none" : "flex",
                            padding: "6px",
                        }}
                    >
                        <Link sx={{ width: "1.3rem", height: "1.3rem" }} />
                    </IconButton>
                    {isCopied && (
                        <span
                        style={{
                            marginLeft: "5px",
                            color: theme.palette.secondary.blue100,
                        }}
                        >
                        Copied!
                        </span>
                    )}
                </Box>
            </Box>
            </Box>
            <Box className={styles.textItem}>
                <Typography
                    variant="body1"
                    sx={{
                        color: darkMode ? theme.palette.darkgrey.darkgrey400
                            : theme.palette.darkgrey.darkgrey600,
                        fontSize: "14px",
                        fontWeight: "500",
                    }}
                >
                    Long Description
                </Typography>

                <NotLogedIn />
                <Description description={project.description} />
            </Box>
      </Box>        
    )
}

export default Content01;