import iso3166 from "iso-3166-1-alpha-2";
import { useState, useEffect } from "react";

// Function to get a list of all country names compatible with Nominatim
const useCountries = () => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        setCountries(data);
      } catch (error) {
        console.error("Error fetching countries:", error);
        setCountries([]);
      }
    };

    fetchCountries();
  }, []); // The empty dependency array ensures that useEffect runs only once when the component mounts

  // Function to return sorted country names
  const getSortedCountryNames = () => {
    const sortedCountries = [...countries]; // Create a copy of the array to avoid modifying the original
    sortedCountries.sort((a, b) => {
      const nameA = a.name.common.toUpperCase();
      const nameB = b.name.common.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return sortedCountries.map((country) => country.name.common);
  };
  return {
    countriesNames: getSortedCountryNames(),
  };
};

export default useCountries;
