import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import styles from "./styles.module.css";
import CommunityStatus from "./community-status";
import PersonalDetails from "./personal-details";
import UserLocation from "./user-location";
import Collaborate from "./collaborate";
import { useParams } from "react-router-dom";
import supabase from "../../../../config/supabaseClient";
import moment from "moment";
import Loading from "../../../../components/loading/default";
import LoadingButton from "../../../../components/loading/button";
import { defaultProfilePrefix } from "../../../../constants";

const status = ["Creator", "All Access", "Admin", "Verified"];
const collaborats = [
  "Musician",
  "Sign Artist",
  "Architecture",
  "Signer",
  "Dancer",
];

const AboutMe = () => {
  const [user, setUser] = useState(null);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUser = async (i) => {
      setIsLoading(true);
      const { data, error } = await supabase
        .from("users")
        .select()
        .eq("id", id);
      if (error) {
        if (i === 4) return;
        fetchUser(i + 1);
      }

      setUser(data[0]);
      setIsLoading(false);
    };
    if (id) fetchUser(1);
  }, [id]);

  const data = [
    {
      label: "My Real Name",
      value: user?.real_name,
    },
    {
      label: "My Nickname",
      value: user?.nick_name,
    },
    {
      label: "Tagline",
      value: user?.tagline,
    },
    {
      label: "Profile Link",
      value: defaultProfilePrefix + user?.id,
    },
    {
      label: "Pronouns",
      value: user?.pronouns,
    },
    {
      label: "Age",
      value: user?.generation,
    },
    {
      label: "Email Address",
      value: user?.public_email || user?.email,
    },
  ];

  const userLocaton = [
    {
      label: "Country",
      value: user?.country,
    },
    {
      label: "City, State, County",
      value: user?.city + ", " + user?.state + ", " + user?.county,
    },
  ];

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <LoadingButton />
      </div>
    );
  }

  return (
    <Box className={styles.about}>
      <CommunityStatus data={status} />
      <PersonalDetails data={data} />
      <UserLocation data={userLocaton} />
      <Collaborate data={collaborats} />
    </Box>
  );
};

export default AboutMe;
