import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-image-crop/dist/ReactCrop.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { createBrowserRouter } from "react-router-dom";
import { Integrations } from "@sentry/tracing";
import ReactGA from "react-ga4";
import { ThemeContextProvider } from "./context/theme-context";
import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: "GTM-NNR2C7ZZ", // Your GTM Container ID
};

TagManager.initialize(tagManagerArgs);
// Initialize React Ga with your tracking ID
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_NUMBER);

process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: "https://5ea9f8865392b2a723c314617fbfec68@o4506362149076992.ingest.sentry.io/4506377289072640",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const el = document.getElementById("root");
const root = ReactDOM.createRoot(el);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      prompt="select_account"
    >
      <ThemeContextProvider>
        <App />
      </ThemeContextProvider>
    </Auth0Provider>
  </React.StrictMode>
);
