import React, { useContext, useEffect } from "react";
import styles from './style.module.css';
import { Box, Typography} from "@mui/material";
import BackButton from "../back-button";
import Text01 from "./text01";
import Text02 from "./text02";
import Text03 from "./text03";
import Text04 from "./text04";
import { useTheme } from "../../../context/theme-context";

const TermsOfService = () => {
    const { theme } = useTheme();

    return (
        <Box className={styles.privacy_policy}
            sx={{
                bgcolor: theme.palette.lightgrey.lightgrey500,
            }}
        >
            <Box className={styles.container}>
                <BackButton />
                <Box className={styles.privacy}>
                    <Box className={styles.content}>
                        <Text01 />
                        <Text02 />
                        <Text03 />
                        <Text04 />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
export default TermsOfService;