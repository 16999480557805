import { Box, Typography, Link, Button, Chip } from "@mui/material";
import React, { useContext } from "react";
import styles from "./style.module.css";
import { SkillsBarContext } from "../../../../../context/skills-bar-context";
import moment from "moment";
import { useTheme } from "../../../../../context/theme-context";

const AboutSkill = () => {
  const { theme, darkMode } = useTheme();
  const { activeSkill } = useContext(SkillsBarContext);
  const trainingYears = moment().diff(activeSkill.started_training_date, "years");

  return (
    <Box
      className={styles.skillsCommunityStatus}
      sx={{
        background: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Box className={styles.skillsItem}>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.darkgrey.darkgrey600,
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          My Experience
        </Typography>
        <Box className={styles.tags}>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.darkgrey.darkgrey400,
              width: "100%",
            }}
          >
            {activeSkill.description
              ? activeSkill.description
              : "No description yet!"}
          </Typography>
        </Box>
      </Box>
      <Box className={styles.skillsItem}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.darkgrey.darkgrey600,
              fontSize: "18px",
              fontWeight: "700",
            }}
          >
            Portfolio Link
          </Typography>
        </Box>
        <Box className={styles.tags}>
          <Link
            href={activeSkill?.link}
            variant="body2"
            sx={{
              color: theme.palette.darkgrey.darkgrey400,
            }}
          >
            {activeSkill.link}
          </Link>
        </Box>
      </Box>
      <Box className={styles.skillsItem}>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.darkgrey.darkgrey600,
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          Skill Level
        </Typography>
        <Box className={styles.tags}>
          {activeSkill.level ? (
            <Chip
              label={activeSkill.level}
              sx={{
                bgcolor: !darkMode && theme.palette.darkgrey.darkgrey400,
                color: !darkMode && theme.palette.lightgrey.lightgrey00,
              }}
            />
          ) : (
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.darkgrey.darkgrey400,
              }}
            >
              Not Selected yet!
            </Typography>
          )}
        </Box>
      </Box>
      <Box className={styles.skillsItem}>
        { trainingYears?? 0 !==
        0 ? (
          <>
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.darkgrey.darkgrey600,
                fontSize: "18px",
                fontWeight: "700",
              }}
            >
              Experience
            </Typography>
            <Box className={styles.tags}>
              <Chip
                label={`${trainingYears} Years`}
                sx={{
                  bgcolor: theme.palette.secondary.main,
                  color: !darkMode && theme.palette.lightgrey.lightgrey00,
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              />
            </Box>
          </>
        ) : (
            <>
              <Typography
              variant="body1"
              sx={{
                color: theme.palette.darkgrey.darkgrey600,
                fontSize: "18px",
                fontWeight: "700",
              }}
            >
              Experience
            </Typography>
            <Chip
              label={"0 Year"}
            /> 
            </>
        )}
      </Box>
    </Box>
  );
};

export default AboutSkill;
