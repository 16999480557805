import { Box, Typography } from "@mui/material";
import img from '../../../../../assets/images/happy.png';

import styles from './style.module.css';

export default function Happy ({txt, key}) {
    return (
        <Box className={styles.border} key={key}>
            <Box className={styles.bg}>
                {/* <Typography variant="body1" className={styles.txt1}>
                    😞
                </Typography> */}
                <img src={img} />
                <Typography variant="body1" className={styles.txt2}>
                {txt}
                </Typography>
            </Box>
            <Box className={styles.enableIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13.3337 4L6.00033 11.3333L2.66699 8" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </Box>
        </Box>
    )
}