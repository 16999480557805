import { Box, Typography } from '@mui/material';
import styles from './style.module.css';
import { useTheme } from '../../context/theme-context';
import Card from './card';
import image1 from '../../assets/images/project-manage-card1.png';
import image2 from '../../assets/images/project-manage-card2.png';
import { ManageProjectsContext } from '../../context/manage-projects-context';
import { useContext } from 'react';

const Applications = () => {
    const { theme } = useTheme();
    const { applications } = useContext(ManageProjectsContext);

    const renderProjects = applications?.map((project, index) => {
        return (
            <Card
                key={index}
                project={project}
            />
        )
    })

    return (
        <Box className={styles.hosted}>
            <Box className={styles.content}>
                {renderProjects}
            </Box>
        </Box> 
    );
}

export default Applications;