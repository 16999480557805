export function calculateUserGeneration(birthDate) {
  const dateOfBirth = new Date(birthDate);
  const year = dateOfBirth.getFullYear();

  if (year < 1965) {
    return "X+";
  } else if (year >= 1965 && year <= 1979) {
    return "X";
  } else if (year >= 1980 && year <= 1987) {
    return "Y+";
  } else if (year >= 1988 && year <= 1995) {
    return "Y";
  } else if (year >= 1996 && year <= 2004) {
    return "Z+";
  } else {
    return "Z";
  }
}
