import { useContext, useEffect } from "react";
import { ManageProjectsContext } from "../../../../context/manage-projects-context";
import Card from "./card";
import { Box } from "@mui/material";
import styles from './style.module.css';

const Liked = () => {
    const { likedProjects } = useContext(ManageProjectsContext);
    
    const renderProjects = likedProjects?.map((project, index) => {
        return (
            <Card project={project} key={index} />
        )
    })
    
    return (
        <Box className={styles.liked}>
            {renderProjects}
        </Box>
    );
};

export default Liked;

