import { Box, Typography } from "@mui/material";
import Modal from '@mui/material/Modal';
import styles from './style.module.css';
import { useTheme } from "../../../../../../../context/theme-context";
import ApplicantCard from "./card";
import { Close } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import supabase from "../../../../../../../config/supabaseClient";
import { ManageProjectsContext } from "../../../../../../../context/manage-projects-context";

const AppModal = ({isOpen, setIsOpen, project, setTotalApplications}) => {
    const { theme } = useTheme();
    const { projectApplicants } = useContext(ManageProjectsContext);

    // useEffect(() => {
    //     console.log(project, 'test project');
    // },[])
    return (
        <Modal
            open={isOpen}
            onClose={()=>setIsOpen(false)}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}        
        >
            <Box className={styles.applicants}
                sx={{
                    bgcolor: theme.palette.lightgrey.lightgrey500,
                }}
            >
                <Box className={styles.close}>
                    <Close
                        onClick={()=> setIsOpen(false)}
                        sx={{
                            fill: "#A4A2A2",
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer'
                        }}
                    />
                </Box>
                <Box className={styles.title}>
                    <Typography variant="body1"
                        color={theme.palette.darkgrey.darkgrey600}
                        sx={{
                            fontSize: '16px',
                            fontWeight: '700',
                        }}
                    >
                        {project.title}
                    </Typography>
                    <Typography variant="body1"
                        color={theme.palette.darkgrey.darkgrey400}
                        sx={{
                            fontSize: '14px',
                            fontWeight: '500',
                        }}
                    >{projectApplicants.length} Creatives applied </Typography>
                </Box>
                <Box className={styles.appls}>
                    {projectApplicants.map((item, index) => {
                        return (
                            <ApplicantCard item={item} key={index} setTotalApplications={setTotalApplications} />
                        )
                    })}
                </Box>
            </Box>
        </Modal>
        
    );
}

export default AppModal;