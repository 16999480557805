import { Box } from "@mui/material";
import RoleShow from "./components/RoleShow";
import styles from "./style.module.css";
import { useContext, useState } from "react";
import { RoleContext } from "../../../../../../../context/role-context";
import AddNewRoleButton from "./components/AddNewRoleButton";
import AddRoleModal from "./components/AddRoleModal";

const RolesList = () => {
  const { roles, setRoles } = useContext(RoleContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = async (state) => {
    setIsModalOpen?.(state);
  };

  const handleAddRole = () => {
    // const lastNum = roles[roles.length - 1]?.num || 0;
    // const newRole = {
    //   num: lastNum + 1,
    //   selectedCategory: '',
    //   selectedSkill: '',
    //   selectedSpecialty: '',
    // };
    // setRoles((prevRoles) => [...prevRoles, newRole]);
  };
  const renderRoles = roles.map((role, index) => {
    return <RoleShow role={role} key={role.num} num={index + 1} />;
  });

  return (
    <Box className={styles.roles}>
      <AddRoleModal isModalOpen={isModalOpen} handleModal={handleModal} />
      {renderRoles}
      <AddNewRoleButton
        handleModal={handleModal}
        handleAddRole={handleAddRole}
      />
    </Box>
  );
};

export default RolesList;
