import { Box, Chip, Typography } from "@mui/material";
import React, { useContext } from "react";
import styles from "./style.module.css";
import image from "../../../../../../../assets/images/profileImage.png";
import { useTheme } from "../../../../../../../context/theme-context";
import { Close, Favorite, FavoriteBorderOutlined, FavoriteOutlined } from "@mui/icons-material";
import { ManageProjectsContext } from "../../../../../../../context/manage-projects-context";
import { DiscoverContext } from "../../../../../../../context/discover-context";
import ConfettiButton from "../../../../../../../components/common/Confetti/confettiButton";
import { useRef } from "react";
import supabase from "../../../../../../../config/supabaseClient";

const Top = ({
  project,
  isFavorite,
  setIsFavorite,
  showConfetti,
  addToFavorite,
  setShowConfetti }) => {
  
  const { theme } = useTheme();
  const { handleFavorite } = useContext(ManageProjectsContext);
  const { handleCloseProject } = useContext(DiscoverContext);
  const containerRef = useRef(null);
  
  
  const removeFromFavorite = async(id) => {
    setIsFavorite(false);
    handleFavorite(id, 'remove');

    const { data } = await supabase
        .from('project_feed')
        .update({
          is_favourite: false
        })
        .eq('user_id', project.user_id)
        .eq('project_id', project.project_id);
      console.log("test matched user",data)
  }

  return (
    <Box className={styles.top}>
      <Box className={styles.profile}>
        <Box
          className={styles.image}
          sx={{
            backgroundImage: `url(${project.profile_image})`,
            width: "48px",
            height: "48px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        ></Box>
        <Box className={styles.name}>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.darkgrey.darkgrey600,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 1,
              textOverflow: "ellipsis",
            }}
          >
            {project?.real_name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.darkgrey.darkgrey300,
            }}
          >
            {project?.nick_name}
          </Typography>
        </Box>
        <Box
          className={styles.options}
          sx={{
            "& .css-ixzp6w-MuiChip-root": {
              height: "auto",
            },
          }}
        >
          <Chip
            label={project.project_category}
            sx={{
              bgcolor: project.project_category === "Brand" && "#EC7508" ||
                project.project_category === "Cause" && "#05B240" ||
                project.project_category === "Creator" && "#09B999" ||
                project.project_category === "Media" && "#B90988",
              color: "#FFF",
              fontSize: "12px",
              // fontWeight: '500',
              p: "4px 12px",
              "& .css-193rxqu-MuiChip-label": {
                lineHeight: "inherit",
              },
              height: 'auto'
            }}
          />
          <Box
            ref={containerRef}
            sx={{
              position: "relative",
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <ConfettiButton
              showConfetti={showConfetti}
              setShowConfetti={setShowConfetti}
              containerRef={containerRef}
            />
              {isFavorite ? (
                <Favorite
                  sx={{ fill: theme.palette.primary.main }}
                  onClick={()=>{removeFromFavorite(project.project_id)}}
                />
              
              ) : (
                <FavoriteBorderOutlined
                  sx={{ fill: theme.palette.primary.main }}
                  onClick={()=>{addToFavorite(project.project_id)}}
                />   
            )}
            </Box>
          <Close
            onClick={()=> handleCloseProject(project)}
            sx={{
              color: theme.palette.darkgrey.darkgrey400,
              cursor: 'pointer',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Top;
