import { Box, Button, Chip, Divider, Typography } from '@mui/material';
import styles from './style.module.css';
import { useTheme } from '../../../../../context/theme-context';
import { RoomOutlined } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Card = ({key, project }) => {
    const { theme, darkMode } = useTheme();
    // const { applications, setApplications, handleRemoveApplication } = useContext(ManageProjectsContext);
    const navigate = useNavigate();

    useEffect(() => {
        // console.log(project, 'test project')
    }, [])
    return (
        <Box className={styles.card}
            key={key}
            sx={{
                bgcolor: darkMode? "#333": theme.palette.lightgrey.lightgrey00,
            }}
        >
            <Box className={styles.pic}
                onClick={()=> navigate(`/projects/${project.project_id}`)}
                sx={{
                    cursor: 'pointer',
                    backgroundImage: `url(${project.project_cover_image})`,
                }}
            ></Box>
            <Box className={styles.content}>
                <Box className={styles.frame}>
                    <Typography
                        sx={{
                            display: 'flex',
                            alignSelf: 'stretch',
                            color: darkMode ? theme.palette.darkgrey.darkgreybody50
                                : theme.palette.darkgrey.darkgrey600,
                            fontSize: '16px',
                            fontWeight: '700',
                        }}
                    >{project.title}</Typography>
                    <Box className={styles.frame2}>
                        <Typography
                            sx={{
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                alignSelf: 'stretch',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                color: darkMode ? theme.palette.darkgrey.darkgrey400
                                    : theme.palette.darkgrey.darkgrey500,
                                fontSize: '14px',
                                fontWeight: '400',
                            }}
                        >{project.description}</Typography>
                    </Box>
                </Box> 
                <Box className={styles.action}>
                    <Box className={styles.locations}>
                        <RoomOutlined
                            sx={{
                                width: '16px',
                                height: '16px',
                                color: darkMode ? theme.palette.darkgrey.darkgrey400
                                    : theme.palette.darkgrey.darkgrey500,
                            }}
                        />
                        <Typography
                            sx={{
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: '1',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                color: darkMode ? theme.palette.darkgrey.darkgrey400
                                    : theme.palette.darkgrey.darkgrey500,
                                fontSize: '12px',
                                fontWeight: '500',
                            }}
                        >
                            {project.location_type === "Remote"? "Remote": project.location_name}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Card;