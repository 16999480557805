
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import supabase from "../config/supabaseClient";
import { UserContext } from "./user-context";
import { useLocation } from "react-router-dom";
import generateUserFeed from "../helpers/generateUserFeed";

export const DiscoverContext = createContext(null);

const DiscoverContextProvider = ({ children }) => {
    const [projects, setProjects] = useState([]);
    const [discoverItems, setDiscoverItems] = useState([]);
    const [projectItems, setProjectItems] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const { user } = useContext(UserContext);
    const [page, setPage] = useState(1); // Start from 1 to fetch initial data
    const [hasMore, setHasMore] = useState(true);
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [removing, setRemoving] = useState(null);

    const ITEMS_PER_PAGE = 5; // Number of items to load per page

    // Use a ref to track the previous value of is_profile_complete
    const prevIsProfileCompleteRef = useRef();
    
    // useEffect(() => {
    //     const generateFeed = async () => {
    //         if (user && user?.is_profile_complete ) {
    //             setLoading(true);
    //             const response = await generateUserFeed(user);
    //             if (response?.data?.message == "User feed generated") {
    //                 if (data.length == 0) {
    //                     fetchDiscoverData(page)    
    //                 } else {
    //                     setData([])
    //                     setPage(1);
    //                 }
    //             }
    //             console.log("test feed")
    //         }
    //     }
    //     // generateFeed();
    //     // Compare the previous value with the current value
    //     if (prevIsProfileCompleteRef.current !== undefined && prevIsProfileCompleteRef.current !== user?.is_profile_complete) {
    //         generateFeed();
    //     }

    //     prevIsProfileCompleteRef.current = user?.is_profile_complete;

    //     console.log("test feed: ", prevIsProfileCompleteRef.current)

    // },[user?.is_profile_complete])



    async function deleteShoutout(client) {
        try {
          const { error } = await supabase
            .from("user_shoutouts")
            .delete()
            .match({ sender_id: client.user_id, receiver_id: client.matched_user_id });
      
          if (error) {
                console.error('Error deleting shoutout:', error);
          } else {
                console.log('Shoutout deleted successfully!');
                const filterdFav = favorites.filter(item=>(item.receiver_id !== client.matched_user_id))
                setFavorites(filterdFav);
                setData((prev) => (
                    prev.map(item => (
                        item === client ? { ...item, shout_outed: false } : item
                    )))
                )
            const { data } = await supabase
                .from('user_feed')
                .update({
                    shout_outed: false
                })
                .eq('user_id', user?.id)
                .eq('matched_user_id', client.matched_user_id);
            console.log("test matched user",data)
          }
        } catch (error) {
            console.error('Unexpected error:', error);
        }
      }

    async function insertShoutout(client) {
        try {
            if (client.shout_outed === false) {
                console.log("hello Im here")
                const { error } = await supabase
                .from("user_shoutouts")
                .insert({ 
                  sender_id: client.user_id, 
                  receiver_id: client.matched_user_id 
                });
          
                if (error) {
                    console.error('Error inserting shoutout:', error);
                } else {
                    console.log('Shoutout inserted successfully!');
                    setData((prev) => (
                        prev.map(item => (
                            item === client ? { ...item, shout_outed: true } : item
                        )))
                    )
                    const { data } = await supabase
                        .from('user_feed')
                        .update({
                            shout_outed: true
                        })
                        .eq('user_id', user?.id)
                        .eq('matched_user_id', client.matched_user_id);
                    console.log("test matched user",data)
                    
                }
            }
        } catch (error) {
          console.error('Unexpected error:', error);
        }
    }
    
    const handleSwipeLeft = async(client) => {
        console.log('Swiped left on:', client);
        // Handle left swipe action (e.g., delete client)
        // if ( client.key === "user") {
        //     await insertShoutout(client);
        // }
    };

    const handleSwipeRight = (card) => {
        setRemoving(card);
        console.log('Swiped right on:', card);
        // Handle right swipe action (e.g., update client)
        setTimeout(() => { 
            handleCloseProject(card);
            setRemoving(null);
        }, 300)

    };



    useEffect(() => {
        const fetchShoutOuts = async () => {
            const { data, error } = await supabase
                .from("user_shoutouts")
                .select()
                .eq('sender_id', user?.id);
            if (data?.length > 0) {
                setFavorites(data);
            }
            console.log(data, 'test favourites');
        };

        const fetchData = async () => {
            const { data, error } = await supabase
                .from("project_user_info")
                .select()
                .neq('status', "Under Review")
                .order('project_order', { ascending: true });
            if (error) {
                console.error('Error fetching project data:', error);
            } else {
                setProjects(data);
            }
        };

        if (user) {
            fetchData();
            fetchShoutOuts();
        }
    }, [user]);

    const fetchDiscoverData = useCallback(async (page) => {
        setLoading(true);
        console.log(`Fetching data for page ${page}`);
    
        try {
            // Fetch discover data
            const { data: discoverData, error: discoverError } = await supabase
                .from('user_feed')
                .select('*')
                .eq('user_id', user?.id)
                .range((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE - 1)
                .order('match_score', { ascending: false })
                .order('matched_user_id', { ascending: true });
    
            // Fetch project data
            const { data: projectData, error: projectError } = await supabase
                .from('project_feed')
                .select('*')
                .eq('user_id', user?.id)
                .range((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE - 1)
                .order('match_score', { ascending: false })
                .order('project_id', { ascending: true });
    
            if (discoverError || projectError) {
                console.error('Error fetching data:', discoverError || projectError);
                return;
            }
    
            console.log("Fetched Project Data:", projectData);
    
            // Update state with new data
            setDiscoverItems(prevItems => [...prevItems, ...discoverData]);
            setProjectItems(prevItems => [...prevItems, ...projectData]);
    
            // Interleave discoverData and projectData
            const combinedData = [];
            const maxLength = Math.max(discoverData.length, projectData.length);
    
            for (let i = 0; i < maxLength; i++) {
                if (i < discoverData.length) {
                    combinedData.push({ ...discoverData[i], key: 'user' });
                }
                if (i < projectData.length) {
                    combinedData.push({ ...projectData[i], key: 'project' });
                }
            }
    
            setData(prevItems => [...prevItems, ...combinedData]);
    
            // Check if there are more items to load
            setHasMore(discoverData.length === ITEMS_PER_PAGE || projectData.length === ITEMS_PER_PAGE);
            
        } catch (error) {
            console.error('Unexpected error:', error);
        } finally {
            setLoading(false);
        }
    }, [user]);
    

    useEffect(() => {
        if (user) {
            setData([]);
            setHasMore(true);
            setPage(1);
            fetchDiscoverData(1);
        }
    }, [user]);
    

    useEffect(() => {
        const defaultFetch = async () => {
            if (page === 1) {
                setData([]);
                setHasMore(true);
                console.log('test page change', page);
            }
                await fetchDiscoverData(page);
                console.log("test page change 2", page)
        }
        defaultFetch();
    }, [page]);
    
    
    const handleScroll = useCallback(() => {
        if (pathname === "/discover/results" && !loading && hasMore) {
            if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1) {
                console.log('Reached bottom, loading more...');
                setPage(prevPage => prevPage + 1);
            }
        }
    }, [pathname, loading, hasMore]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const handleCloseProject = async (project) => {
        console.log(project, 'test that');
        if (project.key === "project") {
            const filteredProjects = data.filter(item => (
                item.project_id !== project.project_id
            ));
            setData(filteredProjects);

            const {error } = await supabase
                .from("project_feed")
                .delete()
                .eq("user_id", project.user_id)
                .eq("project_id", project.project_id);
            
            
            if (!error) {
                console.log("project deleted successfuly");
                try {
                    const { error: exError } = await supabase
                        .from("excluded_projects")
                        .insert({
                            user_id: project.user_id,
                            removed_project_id: project.project_id
                        })
                    if(!exError)
                        console.log("excluded!")
                    else console.log("not excluded!")
                } catch(e) {
                    console.error(e);
                }
            } else {
                console.error(error);
            }
        } else if (project.key === "user") {
            const filteredProjects = data.filter(item => (
                item.matched_user_id !== project.matched_user_id
            ));
            setData(filteredProjects);

            const {error } = await supabase
                .from("user_feed")
                .delete()
                .eq("user_id", project.user_id)
                .eq("matched_user_id", project.matched_user_id);
            if (!error) {
                console.log("creator deleted successfuly");
                try {
                    const { error: exError } = await supabase
                        .from("excluded_users")
                        .insert({
                            user_id: project.user_id,
                            removed_user_id: project.matched_user_id
                        })
                    if(!exError){
                        console.log("excluded!")
                        const { data: testData, error } = await supabase.from('excluded_users').select('*');
                        console.log(testData, 'test Data');
                    }
                    else console.log("not excluded!")
                } catch(e) {
                    console.error(e);
                }
            }
        }
    }

    const contextValue = {
        projects,
        handleCloseProject,
        favorites,
        setFavorites,
        discoverItems,
        loading,
        data,
        setData,
        handleSwipeLeft,
        handleSwipeRight,
        removing,
        deleteShoutout,
        insertShoutout,
        fetchDiscoverData,
        setPage,
    }

    return (
        <DiscoverContext.Provider value={contextValue}>
            {children}
        </DiscoverContext.Provider>
    );
}

export default DiscoverContextProvider;
