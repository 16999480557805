import { Box, Chip, IconButton, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import styles from "./styles.module.css";
import copyLink from "../../../../../assets/images/link-02.svg";
import { useTheme } from "../../../../../context/theme-context";
import { Link } from "@mui/icons-material";

const UserInfo = ({ data, generation }) => {
  const [isCopied, setIsCopied] = useState(false);
  const { theme, darkMode } = useTheme();

  const handleCopyLink = async (linkToCopy) => {
    try {
      await navigator.clipboard.writeText(linkToCopy);
      setIsCopied(true);

      // Set a timeout to reset the isCopied state after 2 seconds
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
  };

  return (
    <Box
      className={styles.communityStatus}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.darkgrey.darkgrey600,
          fontSize: "18px",
          fontWeight: 700,
        }}
      >
        Personal Details
      </Typography>
      <Box className={styles.content}>
        {data.map((item, index) => (
          <Box key={item.label} className={styles.same}>
            <Box className={styles.title}>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.darkgrey.darkgrey300, 
                }}
              >
                {item.label}
              </Typography>
              {/*item.label === "Public Email Address" && <EmailInfo />*/}
            </Box>
            {item.label === "Pronouns" ? (
              <Chip
                label={item.value}
                sx={{
                  color: darkMode
                    ? theme.palette.primary.purpel100
                    : theme.palette.primary.main,
                  bgcolor: !darkMode
                    && theme.palette.primary.purpel50,
                }}
              />
            ) : item.label === "Birth Date" ||
              item.label === "Mobile Number" ||
              item.label === "Registered Email Address" ? (
              <Box className={styles.container}>
                <Box className={styles.detail}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: darkMode
                        ? theme.palette.darkgrey.darkgreybody50
                        :theme.palette.darkgrey.darkgrey600,
                    }}
                  >
                    {item.value}
                  </Typography>
                  {/* <Chip
                    label="Private"
                    sx={{
                      bgcolor: theme.palette.danger.red500,
                      color: theme.palette.lightgrey.lightgrey00,
                      height: "24px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  /> */}
                  {/* {item.label === "Mobile Number" && <MobileInfo />} */}
                </Box>
                {item.label === "Birth Date" && (
                  <Chip
                    label={"Gen " + generation}
                    sx={{
                      color: darkMode
                        ? theme.palette.secondary.blue100
                        : theme.palette.secondary.blue700,
                      bgcolor: !darkMode && theme.palette.secondary.light,
                      fontWeight: 500,
                      fontSize: "14px",
                      height: "32px",
                      "& .css-6od3lo-MuiChip-label": {
                        padding: "0px 16px",
                      },
                    }}
                  />
                )}
              </Box>
            ) : (
              <Box className={styles.container}>
                <Typography
                  variant="body1"
                  sx={{
                    color: darkMode
                      ? theme.palette.darkgrey.darkgreybody50
                      :theme.palette.darkgrey.darkgrey600,
                  }}
                >
                  {item.value}
                </Typography>
                {item.label === "Profile Link" && (
                  <Box>  
                    <IconButton
                      onClick={()=> handleCopyLink(item.value)}
                      sx={{
                        bgcolor: darkMode
                          ? '#525252'
                          : theme.palette.secondary.light,
                        display: isCopied? 'none': 'flex',
                        padding: '6px',
                      }}
                    >
                      <Link sx={{width: '1.3rem', height: '1.3rem'}}/>
                    </IconButton>
                    {isCopied && <span style={{ marginLeft: '5px', color: theme.palette.secondary.blue100 }}>Copied!</span>}
                        
                  </Box>
                )}
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default UserInfo;
