import { createContext, useState } from "react";

export const RoleContext = createContext(null);

const RoleContextProvider = (props) => {
  const [roles, setRoles] = useState([
    {
      num: 1,
      selectedCategory: "",
      selectedSkill: "",
      selectedSpecialty: "",
    },
  ]);

  const contextValue = {
    roles,
    setRoles,
  };

  return (
    <RoleContext.Provider value={contextValue}>
      {props.children}
    </RoleContext.Provider>
  );
};

export default RoleContextProvider;
