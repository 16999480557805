import React from "react";
import { Box, Button, Chip, Container, Tab, Tabs, Typography } from "@mui/material";
import styles from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "../../../../context/theme-context";

const ProfileName = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  return (
    <Box className={styles.name}>
      <Typography
        variant="h6"
        sx={{
          color: '#fff',
          fontWeight: '700'
        }}
      >
        My Creator Card
      </Typography>
      <Chip
        label={'Make LYNX Better'}
        onClick={()=> navigate("/make-lynx-better")}
        className={styles.name__button}
        sx={{
          textTransform: "initial",
          color: "#5C595C",
          // borderColor: theme.palette.lightgrey.lightgrey00,
          backgroundColor: '#FFF',
        }}
      />
    </Box>
  );
};

export default ProfileName;
