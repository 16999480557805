import React, { useEffect, useState } from "react";

import Skill from "./skill";
import { useParams } from "react-router-dom";
import supabase from "../../../../../../../config/supabaseClient";

const SkillsList = ({ activeSkill, setActiveSkill, skills }) => {
  // Initial state representing three skills

  return (
    <React.Fragment>
      {skills.map((skill, index) => {      
        if(index < 3){
          return <Skill
            skill={skill}
            index={skill.id}
            key={skill.id || index}
            activeSkill={activeSkill}
            setActiveSkill={setActiveSkill}
          />
        }
      })}
    </React.Fragment>
  );
};

export default SkillsList;
