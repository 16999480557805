import React, { useRef, useState, useEffect, useContext } from "react";

import "cropperjs/dist/cropper.min.css";
import { Box, Button, Typography } from "@mui/material";
import styles from "./style.module.css";
import { useTheme } from "../../../../../../../context/theme-context";
import { ProjectImageContext } from "../../../../../../../context/project-cover-image-context";

const DeleteProjectImage = ({ modal }) => {
  const { theme } = useTheme();
  
  const {
    setCroppedImage,
    setSelectedProjectImage,
    setOriginalProjectImage,
    originalProjectImage,
    setIsDeleteProjectImageOpen
  } = useContext(ProjectImageContext);
  

  const original_cover_image = originalProjectImage;
  const handleDelete = () => {
    setCroppedImage(null);
    setOriginalProjectImage(null);
    setIsDeleteProjectImageOpen(false);
    
  };
  const handleCancel = () => {
    setIsDeleteProjectImageOpen(false);
    setSelectedProjectImage(null)
  };
  return (
    <Box className={styles.parent}>
      <Box
        className={styles.container}
        sx={{
          backgroundImage: `url(${original_cover_image})`,
          width: {
            xs: "100%",
            md: "400px",
          },
        }}
      >
        <Box className={styles.frame}>
          <Box className={styles.content}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleDelete}
              sx={{
                textTransform: "capitalize",
                bgcolor: "#fff",
                color: theme.palette.danger.red500,
                fontWeight: 500,
              }}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={handleCancel}
              sx={{
                textTransform: "capitalize",
                bgcolor: "#fff",
                color: theme.palette.secondary.dark,
                fontWeight: 500,
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteProjectImage;
