import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";

import { UserContext } from "../../../../../context/user-context";
import supabase from "../../../../../config/supabaseClient";
import CollaboratorSkill from "./components/collaborator-skill";
import GenerationalTag from "./components/generational-tag";
import { useTheme } from "../../../../../context/theme-context";
import { ProfileCompleteContext } from "../../../../../context/profile-complete-context";

const UserCollaborators = () => {
  const { theme } = useTheme();
  const [collaborators, setCollaborators] = useState([]);
  const { user } = useContext(UserContext);
  const [ageRagnes, setAgeRanges] = useState([]);
  const [infoBoxes, setInfoBoxes] = useState([]);
  const { collaborator, setCollaborator } = useContext(ProfileCompleteContext); 
 
  const buttonstyle = {
    borderRadius: "25px",
    border: "1px solid",
    borderColor: theme.palette.lightgrey.lightgrey500,
    background: theme.palette.lightgrey.lightgrey500,
    textTransform: "capitalize",
    padding: "6px 16px",
    color: theme.palette.darkgrey.darkgrey500,
  };

  useEffect(() => {
    const fetchCollaborators = async () => {
      const { data } = await supabase
        .from("creator_desired_collaborators")
        .select("*")
        .eq("user_id", user.id);

      setCollaborators(data);
    };
    const fetchInfo = async () => {
      const { data } = await supabase.from("info_boxes").select();
      setInfoBoxes(data);
      console.log(data, "from info boxes");
    };
    const fetchAgeRanges = async () => {
      const { data, error } = await supabase
        .from("collaborat_age_ranges")
        .select("*")
        .eq("user_id", user.id);
      setAgeRanges(data || []);
    };
    fetchAgeRanges();
    fetchCollaborators();
    fetchInfo();
  }, [user]);

  // const handleCollaborator = () => {
  //   if (collaborators.length > 0 && !collaborator) {
  //     setCollaborator(true);
  //   } 
  //   if (collaborators.length = 0 && collaborator) {
  //     setCollaborator(false)
  //   }
  // }
  // useEffect(() => {
  //   handleCollaborator();
  // }, [collaborators])

  if (!ageRagnes || !collaborators) return <div>Loading...</div>;
  return (
    <Box
      className={styles.communityStatus}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Box className={styles.content}>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.darkgrey.darkgrey600,
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          I am looking to collaborate with:
        </Typography>
        <Box
          className={styles.tags}
          sx={{
            flexWrap: "wrap",
          }}
        >
          {collaborators.map((item) => (
            <CollaboratorSkill key={item.id} type={item.display_name} />
          ))}
        </Box>
      </Box>
      <Box className={styles.content}>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.darkgrey.darkgrey600,
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          Age Range
        </Typography>
        <Box
          className={styles.tags}
          sx={{
            flexWrap: "wrap",
          }}
        >
          {ageRagnes?.length > 0
            ? ageRagnes.map((item) => {
                return (
                  <GenerationalTag
                    ageCategory={item.age_category}
                    key={item.age_category}
                    infoText={
                      infoBoxes.find(
                        (info) => info.name === "Gen" + item.age_category
                      )?.content
                    }
                  />
                );
              })
            : "Edit Your Profile and start adding Age Ranges"}
        </Box>
      </Box>
    </Box>
  );
};

export default UserCollaborators;
// commit
