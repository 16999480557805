import { Box, Button, Divider, Typography } from "@mui/material";
import styles from "./style.module.css";
import Top from "./top";
import Image from "./image";
import { useTheme } from "../../../../../../context/theme-context";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ManageProjectsContext } from "../../../../../../context/manage-projects-context";

const Card = ({ project }) => {
  const { theme, darkMode } = useTheme();
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(project.is_favourite);
  const { fetchFavorite } = useContext(ManageProjectsContext);

  useEffect(() => {
    // const checkFavorite = fetchFavorite(project);
    // setIsFavorite (checkFavorite);
    // console.log(project, 'project test')
  },[project])

  return (
    <Box
      className={styles.card}
      sx={{
        bgcolor: darkMode? theme.palette.lightgrey.lightgrey400:"#FFF",
      }}
    >
      <Top
        project={project}
        isFavorite={isFavorite}
        setIsFavorite={setIsFavorite}
      />
      <Divider sx={{ width: "100%" }} />
      <Box className={styles.content}>
        <Image image={project.project_cover_image} />
        <Box className={styles.text}>
          <Typography
            component="h4"
            variant="body1"
            sx={{
              fontSize: "20px",
              fontWeight: "500",
              color: theme.palette.darkgrey.darkgrey600,
              // display: "-webkit-box",
              // WebkitBoxOrient: "vertical",
              // overflow: "hidden",
              WebkitLineClamp: 1,
              // textOverflow: "ellipsis",
              minHeight: "1.5em",
              width: '100%',
            }}
          >
            {project?.project_title}
          </Typography>
          <Typography
            className={styles.description}
            variant="body1"
            sx={{
              fontSize: "14px",
              color: theme.palette.darkgrey.darkgrey500,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 4,
              textOverflow: "ellipsis",
              minHeight: "6em",
              width: '100%'
            }}
          >
            {project?.project_description}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box className={styles.button}>
        <Button
          variant="contained"
          onClick={() => navigate(`/projects/${project?.project_id}`)}
          sx={{
            textTransform: "capitalize",
            flex: "1",
            p: "10px 1.5rem",
            borderRadius: "4px",
          }}
        >
          View Project
        </Button>
      </Box>
    </Box>
  );
};

export default Card;
