import { Box, Typography } from "@mui/material";
import styles from "./style.module.css";
import { useTheme } from "../../../../../../context/theme-context";
import Card from "./card";
import image1 from "../../../../../../assets/images/project-manage-card1.png";
import image2 from "../../../../../../assets/images/project-manage-card2.png";
import AppModal from "./app-modal";
import { useContext, useState } from "react";
import { ManageProjectsContext } from "../../../../../../context/manage-projects-context";


const Hosted = () => {
  const { theme } = useTheme();
  const [project, setProject] = useState(null);
  const { projects } = useContext(ManageProjectsContext);

  const renderProjects = projects?.map((projectItem, index) => {
    
    return (
      <Card
        key={index}
        project={projectItem}
        setProject={setProject}
      />
    );
  });

  if (!projects) {
    return <div>loading</div>
  }

  return (
    <Box className={styles.hosted}>
      
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.darkgrey.darkgrey600,
          fontSize: "24px",
          fontWeight: "500",
        }}
      >
        {projects.length} Hosted Projects
      </Typography>
      <Box className={styles.content}>{renderProjects}</Box>
      
    </Box>
  );
};

export default Hosted;
