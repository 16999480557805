import React, { useContext, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import styles from "./style.module.css";
import trash from "../../../../../../../../assets/images/trash-03.svg";
import { useTheme } from "../../../../../../../../context/theme-context";

const DeleteConfirmation = ({isOpen, setIsOpen, onDelete, project}) => {
  const { theme, darkMode } = useTheme();
  
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        className={styles.delConfirm}
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey00,
          padding: '40px 0px !important',
          borderRadius: '8px !important',
          width: {
            xs: "320px",
            // sm: "360px",
          },
        }}
      >
        <Box className={styles.content}>
          <Box className={styles.frame}>
            <Box className={styles.icon}>
              <img src={trash} alt="delete" />
            </Box>
            <Box className={styles.text}>
              <Typography
                variant="body1"
                component="h4"
                sx={{
                  color: theme.palette.darkgrey.darkgrey600,
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                Do you want to remove this Application?
              </Typography>
            </Box>
          </Box>
          <Box className={styles.buttons}>
            <Button
              variant="text"
              onClick={handleClose}
              sx={{
                textTransform: "capitalize",
                color: theme.palette.darkgrey.darkgrey300,
                bgcolor: darkMode
                  ? "#3D3D3D"
                  : theme.palette.lightgrey.lightgrey500,
                padding: "8px",
                boxShadow: "none",
                fontSize: "16px",
                width: "120px",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                color: "#FFF",
                bgcolor: theme.palette.danger.red500,
                padding: "8px",
                width: "120px",
                fontSize: "16px",
              }}
              onClick={() => { onDelete(project); handleClose() }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default DeleteConfirmation;
