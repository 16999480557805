import React, { useRef, useState, useEffect, useContext } from "react";

import "cropperjs/dist/cropper.min.css";
import { Box, Button, Typography } from "@mui/material";
import styles from "./style.module.css";
import supabase from "../../../../../../config/supabaseClient";
import { SkillImageContext } from "../../../../../../context/skill-image-context";
import { SkillsBarContext } from "../../../../../../context/skills-bar-context";
import { useTheme } from "../../../../../../context/theme-context";
import { UserContext } from "../../../../../../context/user-context";
import { creatorDefaultImages } from "../../../../../../constants";

const DeleteSkillImage = ({ modal }) => {
  const { theme } = useTheme();
  const { setSelectedSkillImage, setIsDeleteSkillImageOpen, croppedImage } =
    useContext(SkillImageContext);
  const { activeSkill, setActiveSkill } = useContext(SkillsBarContext);
  const { user } = useContext(UserContext);

  const original_cover_image = activeSkill?.original_cover_image;
  const handleDelete = async () => {
    try {
      setIsDeleteSkillImageOpen(false);
      setSelectedSkillImage(null);
      
      // const { data: creatorTypes, error: creatorError } = await supabase.from("creator_desired_collaborators").select().eq("user_id", user?.id);

      // console.log("hello Im deleted: ", creatorTypes);

      
      const targetCreatorType = activeSkill.category;
      console.log("Hello, I'm still null", activeSkill.category);
      const targetImage = creatorDefaultImages[targetCreatorType];
      const original = targetImage;
      const cropp = targetImage;
      

      const { error, data } = await supabase
        .from("creator_skills")
        .update({
          skill_cover_image: cropp,
          original_cover_image: original,
        })
        .eq("id", activeSkill.id);

      if (error) {
        console.error("Error uploading skill image:", error);
      } else {
        // Handle any additional logic after a successful upload
      }
      const { data: updatedSkill, error: updatedSkillError } = await supabase
        .from("creator_skills")
        .select("*")
        .eq("id", activeSkill.id);
      setActiveSkill(updatedSkill[0]);
    } catch (error) {
      console.error("Error handling upload:", error);
    }
  };
  const handleCancel = () => {
    setIsDeleteSkillImageOpen(false);
    setSelectedSkillImage(null);
  };
  return (
    <Box className={styles.parent}>
      <Box
        className={styles.container}
        sx={{
          backgroundImage: `url(${original_cover_image})`,
          width: {
            xs: "100%",
            md: "400px",
          },
        }}
      >
        <Box className={styles.frame}>
          <Box className={styles.content}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleDelete}
              sx={{
                textTransform: "capitalize",
                bgcolor: "#fff",
                color: theme.palette.danger.red500,
                fontWeight: 500,
              }}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={handleCancel}
              sx={{
                textTransform: "capitalize",
                bgcolor: "#fff",
                color: theme.palette.secondary.dark,
                fontWeight: 500,
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteSkillImage;
