import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import { FilterAltOutlined } from "@mui/icons-material";
import supabase from "../../../../config/supabaseClient";
import { SearchContext } from "../../../../context/search-context";
import { Link } from "react-router-dom";
import GetAllCountries from "../../../../helpers/getAllCountries";
import useCountries from "../../../../helpers/getAllCountries";
import { useTheme } from "../../../../context/theme-context";
const State = ["New York", "Fashion", "UX designer"];
const ages = ["X", "Y", "Z", "X+", "Y+"];

const InputFilter = () => {
  const [categories, setCategories] = useState([]);
  const [skills, setSkills] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const { countriesNames } = useCountries();
  const { theme } = useTheme();
  const {
    selectedSkill,
    selectedAgeRanges,
    setSelectedAgeRanges,
    setSelectedSkill,
    selectedSpecialty,
    setSelectedSpecialty,
    selectedCountry,
    setSelectedCountry,
    selectedState,
    setSelectedState,
    selectedEditorChoice,
    setSelectedEditorChoice,
    selectedProsOnly,
    setSelectedProsOnly,
    setSearchType,
    category,
    setCategory,
  } = useContext(SearchContext);
  useEffect(() => {
    const fetchCategories = async () => {
      const { data, error } = await supabase
        .from("distinct_skills_categories")
        .select();
      setCategories(data.map((item) => item.category));
    };
    fetchCategories();
  }, []);
  useEffect(() => {
    const fetchSkills = async () => {
      const { data, error } = await supabase
        .from("skills")
        .select()
        .eq("category", category);
      const uniqueValues = [...new Set(data.map((item) => item.skill))];
      setSkills(uniqueValues);
    };
    fetchSkills();
  }, [category]);
  useEffect(() => {
    const fetchSpecialities = async () => {
      const { data, error } = await supabase
        .from("skills")
        .select("speciality")
        .eq("skill", selectedSkill);

      setSpecialities(data?.map((item) => item?.speciality));
    };
    fetchSpecialities();
  }, [selectedSkill]);

  const handleChangeSkill = (event, value) => {
    setSelectedSkill(value);
  };
  const handleChangeSpecialty = (event, value) => {
    setSelectedSpecialty(value);
  };
  const handleChangeCountry = (event, value) => {
    setSelectedCountry(value);
  };
  const handleChangeState = (event, value) => {
    setSelectedState(value);
  };
  const handleChangeAge = (age) => {
    const ageIndex = selectedAgeRanges.findIndex((item) => item === age);
    if (ageIndex === -1) {
      setSelectedAgeRanges([...selectedAgeRanges, age]);
    } else {
      setSelectedAgeRanges([
        ...selectedAgeRanges.filter((item) => item !== age),
      ]);
    }
  };
  const handleChangeEditorChoice = () => {
    setSelectedEditorChoice(!selectedEditorChoice);
  };
  const handleChangeProsOnly = () => {
    setSelectedProsOnly(!selectedProsOnly);
  };

  return (
    <Box className={styles.parent}>
      <Box className={styles.content}>
        <Autocomplete
          sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
          options={categories}
          getOptionLabel={(option) => option}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <FilterAltOutlined color="action" />
              </InputAdornment>
            ),
          }}
          value={category || categories[0]}
          fullWidth
          onChange={(event, newValue) => setCategory(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Category (Music, Fine Arts, Film/TV)"
              variant="outlined"
            />
          )}
        />
        <Autocomplete
          sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
          options={skills}
          getOptionLabel={(option) => option}
          value={selectedSkill}
          onChange={handleChangeSkill}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} label="Skill" variant="outlined" />
          )}
        />
        <Autocomplete
          sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
          options={specialities}
          getOptionLabel={(option) => option}
          value={selectedSpecialty}
          onChange={handleChangeSpecialty}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} label="Specialty" variant="outlined" />
          )}
        />
        <Box className={styles.country}>
          <Autocomplete
            sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
            className={styles.selectCountry}
            options={countriesNames}
            getOptionLabel={(option) => option}
            value={selectedCountry}
            onChange={handleChangeCountry}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Country"
                variant="outlined"
              />
            )}
          />
          <Autocomplete
            sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
            className={styles.selectState}
            options={State}
            getOptionLabel={(option) => option}
            value={selectedState}
            onChange={handleChangeState}
            renderInput={(params) => (
              <TextField {...params} label="State" variant="outlined" />
            )}
          />
        </Box>
        <Box className={styles.age}>
          <Typography
            variant="body"
            sx={{
              color: theme.palette.darkgrey.darkgrey600,
              fontSize: '18px',
            }}
          >
            Age Range
          </Typography>
          <Box
            className={styles.tags}
            sx={{
              flexWrap: "wrap",
            }}
          >
            {ages.map((age) => (
              <Chip
                label= {`Gen ` + age}
                key={age}
                onClick={() => handleChangeAge(age)}
                sx={{
                  // background: selectedAgeRanges?.includes(age)
                  //   && theme.palette.primary.purpel100,
                  color: selectedAgeRanges?.includes(age)
                    && theme.palette.secondary.blue700,
                }}
                
              />
            ))}
          </Box>
        </Box>
        <Box
          className={styles.checkbox}
          sx={{
            color: theme.palette.darkgrey.darkgrey500,
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedEditorChoice}
                  onChange={handleChangeEditorChoice}
                  name="Editor Choice"
                  sx={{ color: theme.palette.darkgrey.darkgrey200 }}
                />
              }
              label="Only Editor’s Choice"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedProsOnly}
                  onChange={handleChangeProsOnly}
                  name="Pros only"
                  sx={{ color: theme.palette.darkgrey.darkgrey200 }}
                />
              }
              label="Pros Only"
            />
          </FormGroup>
        </Box>
        <Link
          to="/search/results"
          underline="none"
          textAlign={"center"}
          style={{ width: "100%" }}
        >
          <Button
            variant="contained"
            fullWidth
            sx={{
              p: "10px 24px",
              textTransform: "capitalize",
            }}
            onClick={() => setSearchType("custom")}
          >
            Submit
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default InputFilter;
