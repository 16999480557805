import { Box, Button, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";

import { UserContext } from "../../../../../context/user-context";
import supabase from "../../../../../config/supabaseClient";
import Info from "../../../../../components/info";
import { useTheme } from "../../../../../context/theme-context";

const Status = () => {
  const [status, setStatus] = useState([]);
  const { user } = useContext(UserContext);
  const { theme, darkMode } = useTheme();

  useEffect(() => {
    const fetchStatus = async () => {
      const { data, error } = await supabase
        .from("user_community_status")
        .select()
        .eq("user_id", user.id)
        .eq("is_active", true);

      if (error) console.log(error, "from error");
      setStatus(data);
    };
    fetchStatus();
    console.log(status, 'check status');
  }, [user]);

  if (!status) return <div>Loading..</div>;
  return (
    <Box
      className={styles.community}
      sx={{ bgcolor: theme.palette.lightgrey.lightgrey00, width: "100%" }}
    >
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.darkgrey.darkgrey600,
          fontWeight: 700,
          fontSize: '18px',
        }}
      >
        Community Status
      </Typography>
      <Box className={styles.tags}>
        {status.map((item) => {
          return (
            <Chip
              label={item.status}
              key={item.status}
              className={styles.tag}
              sx={{
                background: item.status == "Incomplete" 
                  && !darkMode
                    && theme.palette.danger.red100,
                color: item.status == "Incomplete"
                  ? darkMode
                    ? theme.palette.danger.red200
                    : theme.palette.danger.red500
                  : darkMode
                    ? '#E2DFDF' : '#5C595C',  
              }}
            />
          );
        })}
        {/* <Info />         */}
      </Box>
    </Box>
  );
};
export default Status;
