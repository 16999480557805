import React, { useContext, useState } from "react";
import styles from "./step1.module.css";
import { Box, Button, TextField, Typography } from "@mui/material";
import { RegistrationContext } from "../../../context/registration-context";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useTheme } from "../../../context/theme-context";

const Step1 = ({ onButtonClick }) => {
  const { theme } = useTheme();
 
  const [formData, setFormData] = useState({
    priorityCode: "",
  });
  const { periorityCode, setPeriorityCode } = useContext(RegistrationContext);

  return (
    <div className={styles.form}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2px",
          padding: '1.5rem',
          bgcolor: theme.palette.lightgrey.lightgrey400,
        }}
      >
        <TextField
          label="Enter Priority Code"
          name="priorityCode"
          value={periorityCode}
          onChange={(e) => setPeriorityCode(e.target.value)}
          variant="outlined"
          className={styles.textField}
          fullWidth
          sx={{
            backgroundColor: theme.palette.lightgrey.lightgrey00,
            width: {
              //md: "50%",
            },
          }}
        />
        <Typography variant="caption">
          No priority code? No worries! Skip to the next step
        </Typography>
      </Box>
      <Box sx={{p: '1.5rem'}}>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          endIcon={
            <ChevronRight/>
          }
          sx={{
            p: "8px 13px 8px 24px",
            justifyContent: 'space-between',
            "& span:nth-of-type(1)": {
              background: theme.palette.primary.purpel400,
              height: '2em',
              width: '2em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
            }
          }}
          onClick={onButtonClick}
        >
          Next Step
        </Button>
      </Box>
    </div>
  );
};

export default Step1;
