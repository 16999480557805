import { Box, Button, Typography } from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import theme from "../../../../themes/theme";
import Top from "./top";
import Image from "./image";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../../context/theme-context";

const Given = ({ data }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  return (
    <Box className={styles.card}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Top data={data} />
      <Image image={data.skill_cover_image} editorChoice={data.editorChoice} />
      <Box
        className={styles.text}
        style={{
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
        }}
      >
        <Typography
          component="h4"
          variant="body1"
          sx={{
            fontSize: "12px",
            fontWeight: "500",
            color: theme.palette.darkgrey.darkgrey600,
          }}
        >
          {data.tagline}
        </Typography>
      </Box>
      <Box className={styles.button}>
        <Button
          variant="contained"
          sx={{
            fontSize: "12px",
            textTransform: "capitalize",
            p: "2px 8px",
            fontWeight: "400",
          }}
          onClick={() => navigate("/creators/" + data.receiver_id)}
        >
          See Profile
        </Button>
      </Box>
    </Box>
  );
};

export default Given;
