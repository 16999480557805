import { createContext, useEffect, useState } from "react";
import UseLocalStorageState from "../hooks/userLocalStorage";

export const SkillsBarContext = createContext(null);
const SkillsBarContextProvider = (props) => {
  const [activeSkill, setActiveSkill] = UseLocalStorageState(
    "active-skill",
    null
  );

  const contextValue = {
    activeSkill,
    setActiveSkill,
  };

  return (
    <SkillsBarContext.Provider value={contextValue}>
      {props.children}
    </SkillsBarContext.Provider>
  );
};
export default SkillsBarContextProvider;
