import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import styles from "./style.module.css";
import { useTheme } from "../../../../../../../context/theme-context";
import { useContext, useEffect, useState } from "react";
import { Link } from "@mui/icons-material";
import { ProjectDetailsContext } from "../../../../../../../context/project-details-context";
import { UserContext } from "../../../../../../../context/user-context";
import Apply from "./components/apply/apply";
import Content01 from "./components/content01";
import Content02 from "./components/content02";
import supabase from "../../../../../../../config/supabaseClient";

const Content = ({
  projectUser,
  project,
  creatorTypes,
  ageRanges,
  projectRoles,
}) => {

  useEffect(() => {
    console.log(project);
  }, [])

  const { user } = useContext(UserContext);

  

  return (
    <Box className={styles.content}>
      <Content01
        projectUser={projectUser}
        project={project}
      />      
      <Content02
        creatorTypes={creatorTypes}
        ageRanges={ageRanges}
        projectRoles={projectRoles}
        project={project}
      />        
      <Apply
        project={project}
      />      
    </Box>
  );
};

export default Content;
