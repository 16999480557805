import React, { useContext, useEffect, useState } from 'react';
import { Box, Chip, Container, Typography } from '@mui/material';
import LynxLogo from '../../../../assets/images/Color=Gredient, Size=Large.svg';
import styles from './style.module.css';
import { useTheme } from '../../../../context/theme-context';
import { ManageProjectsContext } from '../../../../context/manage-projects-context';
import { useParams } from 'react-router-dom';
import supabase from '../../../../config/supabaseClient';
import AppModal from '../../Manage-project/components/tabs/hosted/app-modal';

const BasicLogo = () => {
    const { theme, darkMode } = useTheme(); 
    const [project, setProject] = useState([]);
    const { id } = useParams();
    const { fetchProjectApplicants, isAppModalOpen, setIsAppModalOpen } = useContext(ManageProjectsContext);
    const [totalApplications, setTotalApplications] = useState();

    useEffect(() => {
        const fetchData = async() => {
            const { data, error } = await supabase
                .from("project_info_with_total_applications")
                .select()
                .eq("id", id);
            setProject(data[0]);
            setTotalApplications(data[0].total_applications || 0);
        }  

        fetchData();
    }, [])
    
    // useEffect(() => {
    //     setTotalApplications()
    // },[totalApplications])

    const handleSeeApplicants = () => {
        setIsAppModalOpen(true);
        fetchProjectApplicants(project);
        console.log(project, 'test project');

    }

    return (
        <Box className={styles.basicLogo}>
            <AppModal
                isOpen={isAppModalOpen}
                setIsOpen={setIsAppModalOpen}
                project={project}
                setTotalApplications={setTotalApplications}
            />
            <Box className={styles.content}>
                <Typography variant='body1'
                    sx={{
                        color: theme.palette.darkgrey.darkgrey600,
                        fontSize: '20px',
                        fontWeight: '500',
                        textAlign: 'center',
                    }}
                >
                    Edit Project Details
                </Typography>
            </Box>    
            <Box className={styles.status}>                
                <Box className={styles.container}>                
                    <Typography variant='body1'
                        sx={{
                            color: darkMode ? theme.palette.darkgrey.darkgrey400
                                : theme.palette.darkgrey.darkgrey600,
                            fontSize: '14px',
                            fontWeight: '500',
                        }}
                    >
                        Number of Applicants
                    </Typography>
                    <Box className={styles.applicantNumber}>                
                        <Typography variant='body1'
                            sx={{
                                color: darkMode ? theme.palette.darkgrey.darkgreybody50
                                    : theme.palette.darkgrey.darkgrey400,
                                fontSize: '16px',
                                fontWeight: '500',
                                flex: '1 0 0'
                            }}
                        >
                            {totalApplications}
                        </Typography>
                        <Typography variant='body1'
                            onClick = {handleSeeApplicants}
                            sx={{
                                color: darkMode ? theme.palette.secondary.blue200
                                    : theme.palette.secondary.main,
                                fontSize: '12px',
                                fontWeight: '500',
                                letterSpacing: '1px',
                                textAlign: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            View Applicants
                        </Typography>
                    </Box>    
                </Box>    
            </Box>    
        </Box>
    );
}

export default BasicLogo;