import { Box, Button, Chip, Divider, Typography } from "@mui/material";
import styles from "./style.module.css";
import { useTheme } from "../../../../../../../context/theme-context";
import pencilImage from "../../../../../../../assets/images/pencil-line.svg";
import trash from "../../../../../../../assets/images/trash-02.svg";
import userImage from "../../../../../../../assets/images/users-02.svg";
import pencilImageDark from "../../../../../../../assets/images/pencil-line-dark.svg";
import trashDark from "../../../../../../../assets/images/trash-02-dark.svg";
import userImageDark from "../../../../../../../assets/images/users-02-dark.svg";
import { RoomOutlined } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { ManageProjectsContext } from "../../../../../../../context/manage-projects-context";
import AppModal from "../app-modal";
import DeleteConfirmation from "./delete-confirmation";
import { useNavigate } from "react-router-dom";

const Card = ({ key, project, setProject }) => {
  const isLive = project.status === "Under Review" ? false : true;
  const completed = project.status === "Completed"? true : false;
  const { theme, darkMode } = useTheme();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { isAppModalOpen, setIsAppModalOpen, projects, setProjects, handleProjectDelete, fetchProjectApplicants, setEditNavigate } = useContext(
    ManageProjectsContext
  );
  const [totalApplications, setTotalApplications] = useState();
  const navigate = useNavigate();
  // onClick={() => navigate(`/projects/${project.project_id}`)}
 
  useEffect(() => {
    setTotalApplications(project.total_applications || 0);
  },[])
 
  const handleEdit = () => {
    setEditNavigate(true);
    navigate(`/projects/${project.id}/edit`)
    // console.log(project, 'check project id');
  }

  const handleDelete = (project) => {
    const updatedProjects = projects.filter((item) => item.id !== project.id);
    setProjects(updatedProjects);

    handleProjectDelete(project);
  };

  useEffect(() => {
    if (totalApplications <= 0) {
      setIsAppModalOpen(false);
    }
  },[totalApplications])

  const handleSeeApplicants = () => {
    setIsAppModalOpen(true);
    fetchProjectApplicants(project);
  }

  useEffect(() => {
    console.log(project.location_type, 'project.location_type');
  },[])

  return (
    <>
      <AppModal
        isOpen={isAppModalOpen}
        setIsOpen={setIsAppModalOpen}
        project={project}
        setTotalApplications={setTotalApplications}
      />
      <DeleteConfirmation
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        onDelete={handleDelete}
        project={project}
      />
      <Box
        className={styles.card}
        // onClick={() => navigate(`/projects/${project.id}`)}
        key={key}
        sx={{
          bgcolor: darkMode? "#333": theme.palette.lightgrey.lightgrey00,
        }}
      >
        <Box
          onClick={()=> navigate(`/projects/${project.id}`)}
          className={styles.pic}
          sx={{
            cursor: 'pointer',
            backgroundImage: `url(${project.project_cover_image})`,
          }}
        ></Box>
        <Box className={styles.content}>
          <Box className={styles.frame}>
            <Typography
              sx={{
                display: "flex",
                alignSelf: "stretch",
                color: darkMode ? theme.palette.darkgrey.darkgreybody50
                  : theme.palette.darkgrey.darkgrey600,
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              {project.title}
            </Typography>
            <Box className={styles.frame2}>
              <Chip
                label={project.category}
                sx={{
                  height: "auto",
                  padding: "3px 12px",
                  bgcolor: project.category === "Brand" && "#EC7508" ||
                    project.category === "Cause" && "#05B240" ||
                    project.category === "Creator" && "#09B999" ||
                    project.category === "Media" && "#B90988" ,
                  color: "#FFF",
                  textTransform: "capitalize",
                  fontSize: "12px",
                }}
              />
              <Chip
                label={isLive ? "Live" : "Pending Review"}
                sx={{
                  fontSize: "12px",
                  height: "auto",
                  padding: "3px 12px",
                  bgcolor: isLive
                    ? theme.palette.success.dark
                    : theme.palette.warning.main,
                  color: "#FFF",
                }}
              />
              <Chip
                label={completed && "Completed"}
                sx={{
                  display: !completed && 'none',  
                  fontSize: "12px",
                  height: "auto",
                  padding: '3px 12px',
                  bgcolor: theme.palette.primary.main,
                  color: "#FFF",
                }}
              />
            </Box>
          </Box>
          <Box className={styles.action}>
            <Box className={styles.action1}>
            <Box className={styles.locations}>
                <RoomOutlined
                  sx={{
                    width: "16px",
                    height: "16px",
                    color: theme.palette.darkgrey.darkgrey400,
                  }}
                />
                <Typography
                  sx={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: "1",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: theme.palette.darkgrey.darkgrey400,
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  {project.location_type === "Remote"? 'Remote': project.location_name}
                </Typography>
              </Box>
              <Box className={totalApplications > 0?styles.right: `${styles.right} ${styles.basic}`}>
                <Divider orientation="vertical" flexItem />
                {totalApplications >= 0 && (
                  <>
                    <Box
                      className={styles.action2}
                      onClick={() => {
                        // setProject(project);
                        handleSeeApplicants();
                      }}
                    >
                      <img src={darkMode? userImageDark: userImage} alt="applicants" />
                      <Typography
                        variant="body1"
                        color={theme.palette.darkgrey.darkgrey400}
                        sx={{
                          textWrap: "nowrap",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        ({totalApplications})
                      </Typography>
                    </Box>
                  </>
                )}
                <Box className={styles.edit}
                  onClick = {handleEdit}
                >
                  <img src={darkMode? pencilImageDark: pencilImage} alt="edit" />
                  <Typography
                    variant="body1"
                    color={theme.palette.darkgrey.darkgrey500}
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {/* Edit */}
                  </Typography>
                </Box>
                <Box
                  className={styles.delete}
                  onClick={() => setIsDeleteModalOpen(true)}
                >
                  <img src={darkMode? trashDark: trash} alt="delete" />
                  <Typography
                    variant="body1"
                    color={theme.palette.darkgrey.darkgrey500}
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {/* Delete */}
                  </Typography>
                </Box>

              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Card;
