import { Box, Button, Typography } from "@mui/material";
import removeImage from "../../../../../../../../../../assets/images/trash-02.svg";
import styles from './style.module.css';
import { useTheme } from "../../../../../../../../../../context/theme-context";
import supabase from "../../../../../../../../../../config/supabaseClient";
import DeleteConfirmation from "../delete-confirmation";
import { useContext, useState } from "react";
import { UserContext } from "../../../../../../../../../../context/user-context";
import { sendProjectCompletedEmail, sendRemovedByHostEmail } from "../../../../../../../../../../helpers/emails";

const AcceptedState = ({setStatus, status, item}) => {
    const { theme } = useTheme();
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const { user } = useContext(UserContext);

    const handleCancel = async (item) => {
        setStatus("Pending")
        const { data, error } = await supabase
            .from('projects_applications')
            .update({ status: 'Pending' })
            .eq('project_id', item.project_id)
            .eq('user_id', item.user_id);
        
        if (error) {
            throw error;
        } 

        // active campaign
        await sendRemovedByHostEmail(item.project_id, item.user_id);
    }
    const handleCompleted = async () => {
        setStatus('Completed')
        const { data, error } = await supabase
            .from('projects_applications')
            .update({ status: 'Completed' })
            .eq('project_id', item.project_id)
            .eq('user_id', item.user_id);
        
        if (error) {
            throw error;
        } else {
            await sendProjectCompletedEmail(item.project_id, item.user_id);
        } 

    }

    return (
        <Box className={styles.actions}>
            <DeleteConfirmation
                isOpen={isCancelModalOpen}
                setIsOpen={setIsCancelModalOpen}
                onDelete={handleCancel}
                project={item}
                text={'cancel'}
            />
            <Box className={styles.remove}
                onClick={ ()=> setIsCancelModalOpen(true) }
                sx={{
                }}
            >
                <Typography variant="body1"
                    color={theme.palette.darkgrey.darkgrey400}
                    sx={{
                        fontSize: '12px',
                        fontWeight: '700',
                        letterSpacing: 2,
                        textTransform: 'uppercase',
                        display: status === "Completed" && 'none',
                    }}
                >Cancel</Typography>
            </Box>
            <Button variant="contained"
                sx={{
                    // bgcolor: theme.palette.darkgrey.darkgrey100,
                    fontSize: '12px',
                    fontWeight: '700',
                    padding: '8px 20px',
                    letterSpacing: 1,
                }}
                disabled
            >Accepted</Button>
            <Button variant="contained"
                onClick={ handleCompleted }
                sx={{
                    bgcolor: '#00B0F0',
                    color: '#FFF',
                    fontSize: '12px',
                    fontWeight: '700',
                    padding: '8px 20px',
                    letterSpacing: 1,
                }}
                disabled={status === "Completed"}
            >
                { status === "Completed" ? 'Completed': 'Complete'}
            </Button>
        </Box>
    )
}

export default AcceptedState;