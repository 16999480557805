import supabase from "../config/supabaseClient";

export async function checkPrimarySkill(user) {
    const { data, error } = await supabase
        .from("creator_skills")
        .select()
        .eq("user_id", user.id);
    
    if (error) {
        console.error("Error fetching creator skills:", error);
        return;
    }

    // Check if there's any primary skill
    const hasPrimarySkill = data.some(skill => skill.is_primary === true);

    console.log("Check primary skill exist:", hasPrimarySkill);

    if (!hasPrimarySkill) {
        console.log("User doesn't have a primary skill");

        // Sort skills by ID and update the first skill to be primary
        data.sort((a, b) => a.id - b.id);

        if (data.length > 0) {
            const { updateError } = await supabase
                .from("creator_skills")
                .update({ is_primary: true })
                .eq("id", data[0].id);

            if (updateError) {
                console.error("Error updating primary skill:", updateError);
            } else {
                console.log("Primary skill set successfully");
            }
        } else {
            console.log("No skills found for the user");
        }
    }
}
