import { Box, Button, Typography } from "@mui/material";
import styles from "./style.module.css";
import Top from "./top";
import Image from "./image";
import { useTheme } from "../../../../../../context/theme-context";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { DiscoverContext } from "../../../../../../context/discover-context";
import { ManageProjectsContext } from "../../../../../../context/manage-projects-context";
import supabase from "../../../../../../config/supabaseClient";

const ProjectCard = ({ project, setConfetti }) => {
  const { theme, darkMode } = useTheme();
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(project.is_favourite);
  const {handleSwipeLeft, removing, handleSwipeRight } = useContext(DiscoverContext);
  const { handleFavorite } = useContext(ManageProjectsContext);
  const [showConfetti, setShowConfetti] = useState(false);
  const [liking, setLiking] = useState(null);
  
  const handleClick = () => {
    setShowConfetti(true);
  };

  const addToFavorite = async(id) => {
    setLiking(project);
    setIsFavorite(true);
    setTimeout(() => { 
      if (project.is_favourite === false) {
        handleFavorite(id, 'add');
      }
      handleClick();
      setLiking(null);
    }, 100)
    if (project.is_favourite === false) {
      const { data } = await supabase
        .from('project_feed')
        .update({
          is_favourite: true
        })
        .eq('user_id', project.user_id)
        .eq('project_id', project.project_id);
      console.log("test matched user",data)  
    }
  }

  const handlers = useSwipeable({
    onSwipedRight: () => {
      handleSwipeLeft(project);
      addToFavorite(project.project_id);
    },
    onSwipedLeft: async() => {handleSwipeRight(project);},
  });

  useEffect(() => {
    setIsFavorite(project.is_favourite);
  }, [project]);

  // const handleViewProjectClick = () => {
  //     navigate('/project-details');
  // }

  return (
    <Box {...handlers}
      className={`${styles.card} ${removing === project? styles.removing: ''} ${liking === project? styles.liking: ''}`}
      sx={{
        bgcolor: darkMode? theme.palette.lightgrey.lightgrey400: "FFF",
      }}
    >
      <Top
        name={project.real_name}
        nickName={project.nick_name}
        image={project.profile_image}
        label={project.project_category}
        project={project}
        isFavorite={isFavorite}
        setIsFavorite={setIsFavorite}
        showConfetti={showConfetti}
        setShowConfetti={setShowConfetti}
        addToFavorite={addToFavorite}
      />
      <Image image={project.project_cover_image} />
      <Box className={styles.text}>
        <Typography
          component="h4"
          variant="body1"
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: theme.palette.darkgrey.darkgrey600,
            // display: "-webkit-box",
            // WebkitBoxOrient: "vertical",
            // overflow: "hidden",
            WebkitLineClamp: 1,
            // textOverflow: "ellipsis",
            minHeight: "1.5em",
          }}
        >
          {`${project.project_title}`}
        </Typography>
        {/* <Typography
          className={styles.description}
          variant="body1"
          sx={{
            fontSize: "16px",
            color: theme.palette.darkgrey.darkgrey500,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 3,
            textOverflow: "ellipsis",
            minHeight: "4.5em",
          }}
        >
          {project.project_description}
        </Typography> */}
      </Box>
      <Box
        className={styles.button}
        sx={{
          p: "8px 16px 16px",
        }}
      >
        <Button
          variant="contained"
          onClick={() => navigate(`/projects/${project.project_id}`)}
          sx={{
            textTransform: "capitalize",
            flex: "1",
            p: "10px 1.5rem",
            borderRadius: "4px",
          }}
        >
          View Project
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectCard;
