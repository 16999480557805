import {
  Autocomplete,
  Box,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import { UserContext } from "../../../../../../../context/user-context";
import supabase from "../../../../../../../config/supabaseClient";
import { EditSkillContext } from "../../../../../../../context/edit-skill-context";
import { useTheme } from "../../../../../../../context/theme-context";

const EditSkillMainInfo = ({ activeSkill, setActiveSkill }) => {
  const { theme } = useTheme();
  const { user } = useContext(UserContext);
  const [skills, setSkills] = useState([]);
  const [categories, setCategories] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [uniqueSkills, setUniqueSills] = useState([]);
  const {
    category,
    setCategory,
    skill,
    setSkill,
    speciality,
    setSpeciality,
    isPrimary,
    setIsPrimary,
  } = useContext(EditSkillContext);

  const [selectedCategory, setSelectedCategory] = useState(
    activeSkill?.category
  );
  const [selectedSkill, setSelectedSkill] = useState(activeSkill?.name);
  const [selectedSpecialty, setSelectedSpecialty] = useState(
    activeSkill?.speciality
  );

  const handleChangeCategory = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  const handleChangeSkill = (event, newValue) => {
    setSelectedSkill(newValue);
  };

  const handleChangeSpecialty = (event, newValue) => {
    setSelectedSpecialty(newValue);
  };

  useEffect(() => {
    setCategory(selectedCategory);
    setSkill(selectedSkill);
    setSpeciality(selectedSpecialty);
  }, [selectedCategory, selectedSkill, selectedSpecialty]);

  useEffect(() => {
    const fetchCategories = async () => {
      const { data, error } = await supabase.from("creator_types").select("*");
      setCategories([...new Set(data?.map((item) => item.category))]);
      setCategory(activeSkill?.category);
    };

    fetchCategories();
  }, [user]);

  useEffect(() => {
    const fetchSkills = async (i) => {
      const { data, error } = await supabase
        .from("skills")
        .select()
        .eq("category", selectedCategory);
      if (error) {
        if (i === 3) return;
        alert("Subase Error on Fetching Skills: " + error);
        fetchSkills();
      }

      const set = new Set(data?.map((item) => item.skill));
      setUniqueSills(Array.from(set));
      setSkills(data);
    };

    fetchSkills(1);
  }, [selectedCategory]);

  useEffect(() => {
    const fetchSpecialities = async (i) => {
      const { data, error } = await supabase
        .from("skills")
        .select("*")
        .eq("skill", selectedSkill);
      if (error) {
        alert("Subase Error on Fetching Specialities: " + error);
        if (i === 3) return;
        fetchSpecialities(i + 1);
      }

      const set = new Set(data.map((item) => item.speciality));
      setSpecialities(Array.from(set));
    };

    fetchSpecialities(1);
  }, [selectedSkill]);

  // useEffect(() => {
  //   if (category && !skills.length) fetchSkills(1);
  //   if (category && !specialities.length) fetchSpecialities(1);
  // });

  return (
    <Box className={styles.editSkill}>
      <Box
        className={styles.communityStatus}
        sx={{
          width: {
            // md: "50%",
          },
          alignSelf: {
            xs: "stretch",
            // md: "auto",
          },
        }}
      >
        <Box className={styles.title}>
          <Typography
            variant="body1"
            color={theme.palette.darkgrey.darkgrey600}
            fontSize="18px"
            fontWeight={500}
          >
            Add/Edit Skills
          </Typography>
        </Box>
        <Box className={styles.inputField}>
          <Autocomplete
            sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
            options={categories ? categories : []}
            getOptionLabel={(option) => option}
            value={selectedCategory}
            onChange={handleChangeCategory}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Category" variant="outlined" />
            )}
          />
          <Autocomplete
            sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
            options={uniqueSkills ? uniqueSkills : []}
            getOptionLabel={(option) => option}
            value={selectedSkill}
            onChange={handleChangeSkill}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Skill" variant="outlined" />
            )}
          />
          <Autocomplete
            sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
            options={specialities ? specialities : []}
            getOptionLabel={(option) => option}
            value={selectedSpecialty}
            onChange={handleChangeSpecialty}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Speciality" variant="outlined" />
            )}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              alignSelf: "stretch",
            }}
          >
            <Checkbox
              defaultChecked
              sx={{ m: "0px", p: "0px" }}
              checked={isPrimary}
              onChange={(e) => setIsPrimary(e.target.checked)}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                alignSelf: "stretch",
              }}
            >
              <Typography
                variant="body2"
                color={theme.palette.darkgrey.darkgrey500}
              >
                Add This Skill as a Primary Skill
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EditSkillMainInfo;
